import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AppBar,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Toolbar,
  IconButton,
  LinearProgress, Tooltip,
} from '@material-ui/core';
import {
  Add as AddIcon,
} from '@material-ui/icons';
import AddNotificationList from './AddNotificationList';

import './style.css';


class ListTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      openModal: false,
    };
  }

  render() {
    return (
      <Paper square id="list-module-container">
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
            >
              List
            </Typography>
            <Tooltip
              title="Add New Field"
              onClick={() => this.setState({ openModal: true })}
            >
              <IconButton>
                <AddIcon style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            <AddNotificationList open={this.state.openModal} parentState={state => this.setState(state)} addIntoList={(el) => this.props.addIntoList(el)} />
          </Toolbar>
          {this.state.loading && (
            <div className="loading-container">
              <LinearProgress className="loading" />
            </div>
          )}
        </AppBar>
        <div id="list-table">
          <Table stickyHeader size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>
                  Text
                </TableCell>
                {/*<TableCell>
                  Icon
                </TableCell>*/}
                <TableCell />
                {/*<TableCell />*/}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.list.map((list, i) => (
                <TableRow key={`List_${i}`}>
                  <TableCell>
                    {list.text}
                  </TableCell>
                  {/*<TableCell>
                    {list.icon}
                  </TableCell>*/}
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      onClick={() => this.props.removeFromList(i)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

ListTable.propTypes = {
  list: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ListTable);
