import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import NavBar from '~/components/NavBar';
import UserTransactions from '~/routes/Dashboard/User/Banking/Transactions';
import Users from './Users';
import User from './User';
import UserNew from './User/new';
import Companies from './Companies';
import Company from './Company';
import Subsidiary from './Subsidiary';
import Suggestions from './Suggestions';
import AccessCodes from './AccessCodes';
import Home from './Home';
import CreateAccessCode from './CreateAccessCode';
import DTC from './DTC';
import DTCNumber from './DTC/DTCNumber';
import BrokerageTransactions from './User/Brokerage/Transactions';
import Reauth from './Reauth';
import Agreements from './Agreements';
import AgreementsCreate from './Agreements/AgreementsCreate';
import FeeTransparencyEmail from './FeeTransparencyEmail';
import Notifications from './Notifications';
import NotificationCreate from './Notifications/NotificationCreate';
import './style.css';

// Terms and Agreements implementation
// Router for all /dashboard routes past auth gate
const Dashboard = (props) => {
  const { location, history } = props;
  return (
    <div id="dashboard">
      <NavBar location={location} history={history} />
      <div id="dashboard-router">
        <Switch>
          <Route path="/dashboard/home" component={Home} />
          <Route path="/dashboard/user/:id/brokerage/transactions" component={BrokerageTransactions} />
          <Route path="/dashboard/user/:id/transactions" component={UserTransactions} />
          <Route path="/dashboard/user/:id" component={UserNew} />
          <Route path="/dashboard/users/:id" component={User} />
          <Route path="/dashboard/users" component={Users} />
          <Route path="/dashboard/companies/:ticker/subsidiary/:subsidiaryId" component={Subsidiary} />
          <Route path="/dashboard/companies/:ticker" component={Company} />
          <Route path="/dashboard/companies" component={Companies} />
          <Route path="/dashboard/suggestions" component={Suggestions} />
          <Route path="/dashboard/accesscodes/create" component={CreateAccessCode} />
          <Route path="/dashboard/accesscodes" component={AccessCodes} />
          <Route path="/dashboard/dtc/:id" component={DTCNumber} />
          <Route path="/dashboard/dtc" component={DTC} />
          <Route path="/dashboard/reauth" component={Reauth} />
          <Route path="/dashboard/agreements/:id" component={AgreementsCreate} />
          <Route path="/dashboard/agreements" component={Agreements} />
          <Route path="/dashboard/fee/transparency/email" component={FeeTransparencyEmail} />
          <Route path="/dashboard/notifications/:id" component={NotificationCreate} />
          <Route path="/dashboard/notifications" component={Notifications} />
          <Route path="/dashboard" component={() => <Redirect to="/dashboard/home" />} />
          <Route component={() => <h6>dashboard 404</h6>} />
        </Switch>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Dashboard;
