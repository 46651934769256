import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress,
  LinearProgress,
  Tooltip,
  Grid,
} from '@material-ui/core';
import get from '~/services/user/get';
import verifyFunding from '~/services/user/verifyFundingSource';
import { creators as errorCreators } from '~/ducks/error';
import {
  NavigateBefore,
  Refresh,
  ExpandLess,
  ExpandMore
} from '@material-ui/icons';
import _ from 'lodash';
import config from 'Config';
import update from '~/services/user/update';

import './style.css';
import UserSummary from './UserSummary';
import ForgotPasswordAttempts from './ForgotPasswordAttempts';
import InvestmentProfile from './InvestmentProfile';
import SettingsSummary from './SettingsSummary';
import CarouselSummary from './CarouselSummary';
import FundingSourceSummary from './FundingSourceSummary';
import BanksSummary from './BanksSummary';
import HoldingsSummary from './HoldingsSummary';
import BrokerageAccountSummary from './BrokerageAccountSummary';
import TransfersSummary from './TransfersSummary';
import TransactionsFlow from './TransactionsFlow';
import DepositsList from './DepositsList';
import StrikeResolution from './StrikeResolution';

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      userUpdateLoading: false,
      expanded: true,
    };
    this.addRole = this.addRole.bind(this);
    this.deleteRole = this.deleteRole.bind(this);
    this.addPoliticallyExposedName = this.addPoliticallyExposedName.bind(this);
    this.deletePoliticallyExposedName = this.deletePoliticallyExposedName.bind(this);
    this.refreshUser = this.refreshUser.bind(this);
    this.verifyFundingSource = this.verifyFundingSource.bind(this);
  }

  async componentDidMount() {
    if (!this.state.user) {
      await this.getUser(this.props.match.params.id);
    }
  }

  async getUser(userId) {
    this.setState({
      userUpdateLoading: true,
    });
    const user = await get(userId);
    if (user.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch users',
        action: false,
      }));
      this.setState({
        userUpdateLoading: false,
        user: {},
      });
    } else {
      this.setState({
        user: user.data,
        userUpdateLoading: false,
      });
    }
  }

  async refreshUser() {
    this.setState({
      userUpdateLoading: true,
      user: null,
    });
    await this.getUser(this.props.match.params.id);
  }

  async updateRoundups(checked) {
    this.setState({
      userUpdateLoading: true,
    });
    const updatedUser = await update(
      this.state.user.publicKey,
      {
        settings: {
          roundupsEnabled: checked,
        },
      },
    );
    if (updatedUser.data) {
      this.setState({
        user: {
          ...this.state.user,
          settings: {
            ...this.state.user.settings,
            roundupsEnabled: checked
          },
        },
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'User successfully updated',
        action: false,
      }));
    } else {
      this.setState({ userUpdateLoading: false });
      this.props.dispatch(errorCreators.new({
        message: updatedUser.error.message || 'Failed to update user',
        action: false,
      }));
    }
  }

  async updateUser(data, field) {
    this.setState({
      userUpdateLoading: true,
    });
    const updatedUser = await update(
      this.state.user.publicKey,
      data,
    );
    if (updatedUser.data) {
      if (field === 'email' || field === 'phone') {
        this.setState({
          user: {
            ...this.state.user,
            basic: {
              ...this.state.user.basic,
              ...data.basic
            }
          },
          userUpdateLoading: false,
        });
      } else {
        this.setState({
          user: {
            ...this.state.user,
            ...data,
          },
          userUpdateLoading: false,
        });
      }
      this.props.dispatch(errorCreators.new({
        message: 'User successfully updated',
        action: false,
      }));
    } else {
      this.setState({ userUpdateLoading: false });
      this.props.dispatch(errorCreators.new({
        message: updatedUser.error.message || 'Failed to update user',
        action: false,
      }));
    }
  }

  async verifyFundingSource() {
    this.setState({
      userUpdateLoading: true,
    });

    const response = await verifyFunding(this.state.user.publicKey);
    if (response.error) {
      this.props.dispatch(errorCreators.new({
        message: response.error.message || 'Failed to verify funding source',
        action: false,
      }));
      this.setState({
        // user: user.data,
        userUpdateLoading: false,
      });
    } else {
      this.setState({
        user: {
          ...this.state.user,
          fundingSource: response.data.fundingSource,
        },
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: `Verification Status: ${_.get(response, 'data.fundingSource.verification status', null)}`,
        action: false,
      }));
    }
  }

  async addRole(newRole) {
    this.setState({
      userUpdateLoading: true,
    });
    const updatedUser = await update(
      this.state.user.publicKey,
      {
        roles: [
          ...this.state.user.roles,
          newRole,
        ].filter((role, index, self) => self.indexOf(role) === index),
      },
    );
    if (updatedUser.data) {
      this.setState({
        user: {
          ...this.state.user,
          roles: updatedUser.data.roles,
        },
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'User role added successfully',
        action: false,
      }));
    } else {
      this.setState({
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Failed to add role to user',
        action: false,
      }));
    }
  }

  async deleteRole(roleToDelete) {
    this.setState({
      userUpdateLoading: true,
    });
    const updatedUser = await update(
      this.state.user.publicKey,
      {
        roles: this.state.user.roles.filter(role => role !== roleToDelete),
      },
    );
    if (updatedUser.data) {
      this.setState({
        user: {
          ...this.state.user,
          roles: updatedUser.data.roles,
        },
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'User role removed successfully',
        action: false,
      }));
    } else {
      this.setState({
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Failed to remove role to user',
        action: false,
      }));
    }
  }

  async addPoliticallyExposedName(name) {
    this.setState({
      userUpdateLoading: true,
    });
    const updatedUser = await update(
      this.state.user.publicKey,
      {
        personal: {
          politicallyExposedNames: [
            ..._.get(this.state.user, 'personal.politicallyExposedNames', []),
            name,
          ].filter((polName, index, self) => self.indexOf(polName) === index),
        },
      },
    );
    if (updatedUser.data) {
      this.setState({
        user: {
          ...this.state.user,
          personal: updatedUser.data.personal,
        },
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Politically Exposed Name added successfully',
        action: false,
      }));
    } else {
      this.setState({
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Failed to add Politically Exposed Name',
        action: false,
      }));
    }
  }

  async deletePoliticallyExposedName(name) {
    this.setState({
      userUpdateLoading: true,
    });
    const updatedUser = await update(
      this.state.user.publicKey,
      {
        personal: {
          politicallyExposedNames: _.get(this.state.user, 'personal.politicallyExposedNames', []).filter(polName => polName !== name),
        },
      },
    );
    if (updatedUser.data) {
      this.setState({
        user: {
          ...this.state.user,
          personal: updatedUser.data.personal,
        },
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Politically Exposed Name removed successfully',
        action: false,
      }));
    } else {
      this.setState({
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Failed to remove Politically Exposed Name',
        action: false,
      }));
    }
  }

  handleAccordion() {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    if (!this.state.user) {
      return (
        <div id="user-container">
          <div id="edit-container">
            <Paper square className="fill-container">
              <AppBar position="static">
                <Toolbar>
                  <IconButton
                    className="menu-button"
                    color="inherit"
                    aria-label="back"
                    onClick={() => this.props.history.goBack()}
                  >
                    <NavigateBefore />
                  </IconButton>
                  <Typography
                    variant="h6"
                    color="inherit"
                    className="flex-grow"
                  >
                    {'Loading User...'}
                  </Typography>
                </Toolbar>
                {this.state.userUpdateLoading && (
                  <div className="loading-container">
                    <LinearProgress className="loading" />
                  </div>
                )}
              </AppBar>
              <div id="content">
                <CircularProgress />
              </div>
            </Paper>
          </div>
        </div>
      );
    }
    const title = (_.get(this.state.user, 'basic.firstName', false) || _.get(this.state.user, 'basic.lastName', false))
      ? `${_.get(this.state.user, 'basic.firstName', '')} ${_.get(this.state.user, 'basic.lastName')}`
      : `User: ${_.get(this.state.user, 'publicKey', 'Unknown')}`;

    return (
      <div id="user-container">
        <div id="edit-container">
          <Paper square className="fill-container">
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  className="menu-button"
                  color="inherit"
                  aria-label="back"
                  onClick={() => this.props.history.goBack()}
                >
                  <NavigateBefore />
                </IconButton>
                <Typography
                  variant="h6"
                  color="inherit"
                  className="flex-grow"
                >
                  {title}
                </Typography>
                <Tooltip
                  title="Refresh User Data"
                  onClick={this.refreshUser}
                >
                  <IconButton>
                    <Refresh style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={`${this.state.expanded ? 'Collapse' : 'Expand'} All`}
                  onClick={() => this.handleAccordion()}
                >
                  <IconButton size={'small'}>
                    {this.state.expanded ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
                  </IconButton>
                </Tooltip>
              </Toolbar>
              {this.state.userUpdateLoading && (
                <div className="loading-container">
                  <LinearProgress className="loading" />
                </div>
              )}
            </AppBar>
            <div className="item-container" style={{ paddingTop: '7px' }}>
              <Grid container spacing={1} wrap={'wrap'}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                >
                  <UserSummary
                    user={this.state.user}
                    roles={this.props.user.roles}
                    addRole={this.addRole}
                    deleteRole={this.deleteRole}
                    addPoliticallyExposedName={this.addPoliticallyExposedName}
                    deletePoliticallyExposedName={this.deletePoliticallyExposedName}
                    expanded={this.state.expanded}
                    updateUser={(data, field) => this.updateUser(data, field)}
                  />
                  <StrikeResolution
                    user={this.state.user}
                  />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                >
                  <InvestmentProfile
                    roles={this.props.user.roles}
                    settings={this.state.user.settings}
                    investment={this.state.user.investment}
                    expanded={this.state.expanded}
                    updateRoundups={(checked) => this.updateRoundups(checked)}
                  />
                  <SettingsSummary
                    settings={this.state.user.settings}
                    expanded={this.state.expanded}
                  />
                  <CarouselSummary
                    parentState={(state) => this.setState(state)}
                    userKey={this.state.user.publicKey}
                    user={this.state.user}
                    dispatch={this.props.dispatch}
                    expanded={this.state.expanded}
                  />
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                >
                  <FundingSourceSummary
                    fundingSource={this.state.user.fundingSource}
                    verifyFundingSource={this.verifyFundingSource}
                    expanded={this.state.expanded}
                  />
                  <BrokerageAccountSummary userKey={this.state.user.publicKey} expanded={this.state.expanded} />
                  <BanksSummary userKey={this.state.user.publicKey} expanded={this.state.expanded} />
                </Grid>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                style={{ marginTop: '30px' }}
              >
                <ForgotPasswordAttempts user={this.state.user} expanded={this.state.expanded} />
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                style={{ marginTop: '30px' }}
              >
                <HoldingsSummary userKey={this.state.user.publicKey} expanded={this.state.expanded} />
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                style={{ marginTop: '30px' }}
              >
                <TransfersSummary userKey={this.state.user.publicKey} roles={this.props.user.roles} expanded={this.state.expanded} />
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                style={{ marginTop: '30px' }}
              >
                <DepositsList userKey={this.state.user.publicKey} expanded={this.state.expanded} />
              </Grid>
              {config.environment === 'staging' &&
                <div style={{ marginTop: '30px' }}>
                  <TransactionsFlow userKey={this.state.user.publicKey} expanded={this.state.expanded} />
                </div>
              }
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

User.propTypes = {
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(User);
