import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import ActiveUsersReportsTable from './ActiveUsersReportsTable';

const Home = () => (
  <Grid id={'home-container'} container spacing={2}>
    <Grid
      item
      xs={12}
      md={12}
      lg={12}
    >
      <ActiveUsersReportsTable />
    </Grid>
  </Grid>
);

export default Home;
