import axios from 'axios';
import config from 'Config'; /* eslint-disable-line */
import store from '../../';

export default async (file, name, folder) => {
  const form = new FormData();
  form.append('name', name);
  form.append('fullPath', `fee-transparency-${config.environment}/${folder}`);
  form.append('file', file);
  const authUser = store.getState().user;
  return axios.post(`${config.apiUrl}/aws/s3/putFile`, form, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data; boundary=Grifin',
      authorization: authUser.access_token,
      'grifin-user-key': authUser.publicKey,
    },
  })
    .then(async (response) => {
      if (response.status !== 200 || false) {
        throw new Error(response.statusText);
      }
      return response.data;
    })
    .catch(error => ({ error }));
};
