import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Checkbox,
  Input,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  LockOutlined,
} from '@material-ui/icons';

import { thunks } from '~/ducks/user';
import './style.css';

// Login screen, automatically redirects to dashboard if already authed
class Login extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (props.user.isAuthed) {
      return {
        ...state,
        ...{
          complete: true,
        },
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      complete: false,
      error: false,
      errorMsg: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  async handleSubmit(event) {
    event.preventDefault();

    const { dispatch } = this.props;

    const loginRequest = true;

    if (loginRequest) {
      dispatch(thunks.login({
        email: this.state.email,
        password: this.state.password,
      }));
      // this.setState({
      //   complete: true,
      // });
    }
  }

  render() {
    if (this.state.complete === true) return <Redirect to="/dashboard/home" />;

    return (
      <div id="main">
        <CssBaseline />
        <Paper className="paper">
          <Avatar className="avatar">
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className="form" onSubmit={this.handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input id="email" onChange={this.handleChange} name="email" autoComplete="email" autoFocus />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input name="password" onChange={this.handleChange} type="password" id="password" autoComplete="current-password" />
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
            >
              Sign in
            </Button>
          </form>
        </Paper>
      </div>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(Login);
