import React from 'react';
import {
  Paper,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@material-ui/core';
import {
  ExpandMore,
} from '@material-ui/icons';
import { get } from 'lodash';
import PropTypes from 'prop-types';

class SettingsSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
    return true;
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { settings } = this.props;
    return (
      <Paper square style={{ marginBottom: 8 }}>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
            >
              Settings
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Security Mode
                  </TableCell>
                  <TableCell>
                    {get(settings, 'security.mode', 'Not Set')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Auth Timeout
                  </TableCell>
                  <TableCell>
                    {get(settings, 'security.authTimeout', 'Not Set')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
}

SettingsSummary.propTypes = {
  settings: PropTypes.object,
};

SettingsSummary.defaultProps = {
  settings: {},
};

export default SettingsSummary;
