import config from 'Config'; /* eslint-disable-line */
import store from '../../';

export default async () => {
  const authUser = store.getState().user;
  return fetch(`${config.apiUrl}/agreements`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: authUser.access_token,
      'grifin-user-key': authUser.publicKey,
    },
  })
    .then(async (response) => {
      if (response.status !== 200 || false) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .then(async (response) => {
      const json = await response.json();

      return { success: true, data: json };
    })
    .catch(error => ({ error }));
};
