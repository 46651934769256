import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  IconButton,
  Tooltip,
  LinearProgress,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';
import {
  Refresh, PlusOne, ExpandMore,
} from '@material-ui/icons';
import { creators as errorCreators } from '~/ducks/error';
import getRedemptionsByUser from '~/services/redemptions/getByUser';
import { connect } from 'react-redux';
import Create from './create';
import config from 'Config';

class TransfersSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redemptions: [],
      redemptionsUpdateLoading: false,
      create: false,
      expanded: true,
    };
  }

  async componentDidMount() {
    if (this.state.redemptions.length === 0) {
      await this.getRedemptions();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
    return true;
  }

  async getRedemptions(success) {
    this.setState({
      redemptionsUpdateLoading: true,
    });

    const response = await getRedemptionsByUser(this.props.userKey);
    if (response.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch user\'s redemptions',
        action: false,
      }));
      this.setState({
        redemptionsUpdateLoading: false,
        redemptions: [],
      });
    } else {
      this.setState({
        redemptionsUpdateLoading: false,
        redemptions: response.data,
      });
      if (success) {
        success();
      }
    }
  }

  async refreshRedemptions(event, err) {
    event.stopPropagation();
    if (err) {
      this.setState({
        redemptionsUpdateLoading: false,
        create: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Failed to create user\'s redemption',
        action: false,
      }));
    } else {
      this.setState({
        redemptionsUpdateLoading: true,
        redemptions: [],
        create: false,
      });
      await this.getRedemptions(() => {
        this.props.dispatch(errorCreators.new({
          message: 'Refreshed Redemptions Data Successfully',
          action: false,
        }));
      });
    }
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    return (
      <Paper square>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Transfers
            </Typography>
            {(config.environment === 'staging' || this.props.roles.includes('customer-service-manager')) &&
              <div>
                <Tooltip
                  title="Create Redemption"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.setState({ create: true });
                  }}
                >
                  <IconButton size={'small'}>
                    <PlusOne style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
                <Create userKey={this.props.userKey} open={this.state.create} parentState={(event, state) => {
                  event.stopPropagation();
                  this.setState(state);
                }} refreshRedemptions={(event, err) => this.refreshRedemptions(event, err)} />
              </div>
            }
            <Tooltip
              title="Refresh Redemptions Data"
              onClick={(event) => this.refreshRedemptions(event)}
            >
              <IconButton size={'small'}>
                <Refresh style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            {this.state.redemptionsUpdateLoading && (
              <div className="loading-container" style={{ width: '100%', position: 'absolute', left: 0, top: '48px' }}>
                <LinearProgress className="loading" />
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, flexDirection: 'column' }}>
            {this.state.redemptions.length > 0 &&
              <div>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Total Redemptions
                      </TableCell>
                      <TableCell>
                        {this.state.redemptions.length}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Successful Redemptions
                      </TableCell>
                      <TableCell>
                        {this.state.redemptions.filter(redemption => redemption.status === 'SUCCESSFUL').length}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Successful/Total Amount
                      </TableCell>
                      <TableCell>
                        {`$${this.state.redemptions.filter(redemption => redemption.status === 'SUCCESSFUL').reduce((a, b) => a + (b.amount || 0), 0)} / $${this.state.redemptions.reduce((a, b) => a + (b.amount || 0), 0)}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <TableContainer style={{ maxHeight: 480 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          DW Id
                        </TableCell>
                        <TableCell>
                          Status
                        </TableCell>
                        <TableCell>
                          DW Status
                        </TableCell>
                        <TableCell>
                          Last Updated
                        </TableCell>
                        <TableCell>
                          DW Last Updated
                        </TableCell>
                        <TableCell>
                          Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { this.state.redemptions.length > 0 &&
                        this.state.redemptions.map((redemption, i) => (
                          <TableRow key={`Redemption_${i}`}>
                            <TableCell>
                              {redemption.dwId}
                            </TableCell>
                            <TableCell>
                              {redemption.status}
                            </TableCell>
                            <TableCell>
                              {get(redemption, 'dwStatus', 'None')}
                            </TableCell>
                            <TableCell>
                              {moment(redemption.last_updated).format('MM-DD-YY hh:mm A')}
                            </TableCell>
                            <TableCell>
                              {redemption.dwUpdated ? moment(redemption.dwUpdated).format('MM-DD-YY hh:mm A') : 'None'}
                            </TableCell>
                            <TableCell>
                              {redemption.amount}
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
}

TransfersSummary.propTypes = {
  userKey: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(TransfersSummary);
