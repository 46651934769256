import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  TableBody,
  TableRow,
  TableCell,
  Table,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { get } from 'lodash';

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

class EditEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basic: props.basic,
      emailError: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (JSON.stringify(nextProps.basic) !== JSON.stringify(this.props.basic)) {
      this.setState({ basic: nextProps.basic });
    }
    return true;
  }

  handleEmailChange (event, field) {
    const basic = JSON.parse(JSON.stringify(this.state.basic));
    basic[field] = event.target.value.trim();
    this.setState({ basic, [`${field}Error`]: false });
  }

  validateEmail() {
    const { email } = this.state.basic;
    if (!validateEmail(email)) {
      this.setState({ emailError: true });
      return false;
    }
    return true;
  }

  onSubmit() {
    if (this.validateEmail()) {
      this.props.updateEmail({ email: this.state.basic.email });
    }
  }

  render() {
    if (!this.props.open) return null;
    return (
      <Dialog
        onClick={(event) => event.stopPropagation()}
        fullWidth
        maxWidth={'sm'}
        open={this.props.open}
        onClose={(event) => this.props.parentState(event, { editEmail: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" className="flex-grow">
                Edit Email
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <TextField
                    autoFocus
                    error={this.state.emailError}
                    style={{ width: '100%', margin: 0 }}
                    id="email"
                    label="Email Address"
                    className={'input-field ml-0'}
                    value={get(this.state.basic, 'email', '')}
                    onChange={e => this.handleEmailChange(e, 'email')}
                    margin="normal"
                    type="email"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => this.props.parentState(event, { editEmail: false })}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.onSubmit()}
            variant="contained"
            color="primary"
            className="space"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(EditEmail);
