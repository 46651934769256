import React, { useEffect, useState } from "react";
import {
  ChevronLeft,
  ChevronRight
} from '@material-ui/icons';
import { gql, useLazyQuery, useQuery, useApolloClient } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { get } from "lodash";
import moment from "moment";

function BrokerageTransactions({ history }) {
  const { id } = useParams();

  const start = moment().utc().subtract(60, "days").toISOString();
  const end = moment().utc().toISOString();
  const [filter, setFilter] = useState({
    from: start,
    to: end,
    limit: 10,
    direction: "next",
    offset: start,
  });

  const { data, loading, error } = useQuery(gql`
    query GetUser($id: String) {
      user(id: $id) @rest(type: "User", path: "/users/{args.id}") {
        basic @type(name: "Profile") {
          email
          firstName
          lastName
        }
        brokerage @type(name: "Brokerage") {
          account @type(name: "BrokerageAccount") {
            id
          }
        }
      }
    }
  `, { variables: { id: id }});

  const [getTransactions, {
    data: transactionsData,
    loading: transactionsLoading,
    error: transactionsError }] = useLazyQuery(gql`
      query GetDrivewealthTransactions($id: String, $from: Date, $to: Date, $limit: Int, $direction: String, $offset: Date) {
        drivewealthTransactions(id: $id, from: $from, to: $to, limit: $limit, direction: $direction, offset: $offset)
          @rest(type: "DrivewealthTransaction", path: "/brokerage/transactions/{args.id}?from={args.from}&to={args.to}&limit={args.limit}&direction={args.direction}&offset={args.offset}") {
          accountAmount
          accountBalance
          comment
          finTranID
          finTranTypeID
          instrument @type(name: "DrivewealthInstrument") {
            id
            name
            symbol
          }
          orderID
          orderNo
          tranWhen
        }
      }`, {
        variables: {
          id: get(data, "user.brokerage.account.id", null),
          from: filter.from,
          to: filter.to,
          limit: filter.limit,
          direction: filter.direction,
          offset: filter.offset
        }
      }
    );

  useEffect(() => {
    if (!loading && !error) {
      getTransactions();
    }
  }, [loading]);

  function renderTransactions() {
    if (transactionsError) return <div>Error...</div>
    if (transactionsLoading || !transactionsData) return <div>Loading...</div>

    const transactions = transactionsData.drivewealthTransactions;

    return (
      <>
        {transactions.map(transaction => {
          return (
            <div key={`${transaction.finTranID}`} className="grid border-b pb-3 mb-3 text-sm" style={{ gridTemplateColumns: "1fr 5fr 1fr" }}>
              <div>{transaction.finTranTypeID}</div>
              <div>{moment(transaction.tranWhen).format("MMM DD YYYY h:mm:ss A")}</div>
              <div>
                {
                  transaction.instrument && (
                    <>{transaction.instrument.symbol}</>
                  )
                }
              </div>
            </div>
          )
        })}
        <div className="flex">
          <div className="mr-3">
            <a href="#prev" onClick={(e) => {
              e.preventDefault();
              setFilter({
                ...filter,
                direction: "prev",
                offset: transactions[0].tranWhen
              })
            }}>
              <ChevronLeft fontSize="small" /> Prev
            </a>
          </div>
          <div>
            <a href="#next" onClick={(e) => {
              e.preventDefault();
              setFilter({
                ...filter,
                direction: "next",
                offset: transactions[transactions.length - 1].tranWhen
              })
            }}>
              Next <ChevronRight fontSize="small" />
            </a>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="m-5">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <a href="#" onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}>
            <ChevronLeft fontSize="large" />
          </a>
          <h1 className="text-xl font-bold">Transactions</h1>
        </div>
      </div>

      <div className="rounded-sm shadow p-4">
        {renderTransactions()}
      </div>

    </div>
  )
}

export default BrokerageTransactions;
