import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  CircularProgress,
  Button,
  Toolbar,
  InputBase,
  IconButton,
  MenuItem,
  Tooltip,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import {
  Search as SearchIcon,
} from '@material-ui/icons';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import getAll from '~/services/matchSuggestion/getAll';
import deleteSuggestion from '~/services/matchSuggestion/delete';
import { creators as errorCreators } from '~/ducks/error';

class SuggestionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      suggestions: [],
      searchActive: false,
      filterMenu: null,
      textSearch: '',
      sortBy: '',
      order: 'desc',
      page: 0,
      entries: 15,
    };

    this.searchInput = React.createRef();

    this.toggleSearch = this.toggleSearch.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.filterSuggestions = this.filterSuggestions.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.sortSuggestions = this.sortSuggestions.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleEntriesChange = this.handleEntriesChange.bind(this);
  }

  async componentDidMount() {
    if (this.state.fetching) {
      const suggestions = await getAll();
      if (suggestions.error) {
        this.props.dispatch(errorCreators.new({
          message: 'Failed to fetch suggestions',
          action: false,
        }));
        this.setState({
          fetching: false,
          suggestions: [],
        });
      } else {
        this.setState({
          suggestions: suggestions.data,
          fetching: false,
        });
      }
    }
  }

  async handleDelete(suggestionId) {
    if (confirm('Delete this suggestion?')) {
      const response = await deleteSuggestion(suggestionId);
      if (response.success) {
        const suggestions = await getAll();
        if (suggestions.error) {
          this.props.dispatch(errorCreators.new({
            message: 'Failed to refresh suggestions',
            action: false,
          }));
        } else {
          this.setState({
            suggestions: suggestions.data,
          });
        }
      } else {
        this.props.dispatch(errorCreators.new({
          message: 'Failed to delete suggestion',
        }));
      }
    }
  }

  handleEntriesChange(event) {
    this.setState({
      entries: event.target.value,
    });
  }

  sortBy(by) {
    if (by === this.state.sortBy) {
      this.setState({
        order: this.state.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortBy: by,
        order: 'desc',
      });
    }
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleSearchTextChange(event) {
    this.setState({
      textSearch: event.target.value,
    });
  }

  toggleSearch() {
    if (this.state.searchActive) {
      this.setState({
        searchActive: false,
        textSearch: '',
      });
    } else {
      this.setState({
        searchActive: true,
      }, () => this.searchInput.current.focus());
    }
  }


  sortSuggestions(users) {
    if (!this.state.sortBy) {
      return users;
    }
    const sortedUsers = users.sort((a, b) => {
      if (typeof get(a, this.state.sortBy, '') === 'string') {
        const x = get(a, this.state.sortBy, '').toLowerCase();
        const y = get(b, this.state.sortBy, '').toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
      }
      return a[this.state.sortBy] - b[this.state.sortBy];
    });
    if (this.state.order === 'desc') {
      return sortedUsers;
    }
    return sortedUsers.reverse();
  }

  filterSuggestions() {
    if (!this.state.searchActive) {
      return this.state.suggestions;
    }

    return this.state.suggestions.filter((suggestion) => {
      const transactionName = get(suggestion, 'transaction[0].name', '');
      const suggestedTicker = get(suggestion, 'suggestion', '');
      const matchedCompany = get(suggestion, 'matchedCompany[0].tickerSymbol', 'cash');
      const firstName = get(suggestion, 'user[0].basic.firstName', '');
      const lastName = get(suggestion, 'user[0].basic.lastName', '');

      if (firstName.toLowerCase().indexOf(this.state.textSearch.toLowerCase()) !== -1) {
        return true;
      } else if (lastName.toLowerCase().indexOf(this.state.textSearch.toLowerCase()) !== -1) {
        return true;
      } else if (`${firstName} ${lastName}`.toLowerCase().indexOf(this.state.textSearch.toLowerCase()) !== -1) {
        return true;
      } else if (
        transactionName.toLowerCase().indexOf(this.state.textSearch.toLowerCase()) !== -1
      ) {
        return true;
      } else if (
        suggestedTicker.toLowerCase().indexOf(this.state.textSearch.toLowerCase()) !== -1
      ) {
        return true;
      } else if (
        matchedCompany.toLowerCase().indexOf(this.state.textSearch.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
  }

  render() {
    return (
      <div id="module-container" className={this.state.fetching ? 'fetching' : 'full'}>
        <Paper className="table-paper">
          {this.state.fetching &&
            <div className="circular-loading-container">
              <CircularProgress />
            </div>
          }
          {!this.state.fetching &&
            <div id="module-content" className="full-width">
              <Toolbar>
                <div className="title-container">
                  <Typography variant="h6" id="tableTitle">
                    Suggestions
                  </Typography>
                </div>
                <div id="page-entries">
                  <FormControl style={{ minWidth: '70px' }}>
                    <InputLabel htmlFor="per-page">Per Page</InputLabel>
                    <Select
                      value={this.state.entries}
                      onChange={this.handleEntriesChange}
                      inputProps={{ id: 'per-page' }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  id="toolbar-search"
                  className={this.state.searchActive ? 'open' : 'closed'}
                >
                  <Tooltip enterDelay={300} title="Search">
                    <IconButton
                      onClick={this.toggleSearch}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  {
                    this.state.searchActive &&
                    <InputBase
                      id="text-search"
                      placeholder="Search"
                      onChange={this.handleSearchTextChange}
                      inputRef={this.searchInput}
                      value={this.state.searchText}
                    />
                  }
                </div>
              </Toolbar>
              <div id="table-container">
                <Table stickyHeader size={'small'} id="table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'transaction[0].name'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('transaction[0].name')}
                          >
                            Transaction Name
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'suggestion'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('suggestion')}
                          >
                            Suggestion
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'matchedCompany[0].name'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('matchedCompany[0].name')}
                          >
                            Matched Company
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'user[0].basic.lastName'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('user[0].basic.lastName')}
                          >
                            {"User's Name"}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <TableSortLabel>
                          Delete
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody id="table-body">
                    {this.sortSuggestions(this.filterSuggestions())
                      .slice(
                        this.state.page * this.state.entries,
                        (this.state.page * this.state.entries) + this.state.entries,
                      )
                      .map(row => (
                        <TableRow key={`suggestion-${row._id}`}>
                          <TableCell component="th" scope="row">{get(row, 'transaction[0].name', row.transactionName)}</TableCell>
                          <TableCell>{row.suggestion}</TableCell>
                          <TableCell>{get(row, 'matchedCompany[0].tickerSymbol', 'cash')}</TableCell>
                          <TableCell>{`${get(row, 'user[0].basic.firstName', '')} ${get(row, 'user[0].basic.lastName', '')}`}</TableCell>
                          <TableCell align="right">
                            <Button
                              variant="outlined"
                              onClick={() => this.handleDelete(row._id)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                component="div"
                id="table-pagination"
                count={this.state.suggestions.length}
                rowsPerPage={this.state.entries}
                rowsPerPageOptions={[]}
                page={this.state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
              />
            </div>
          }
        </Paper>
      </div>
    );
  }
}

SuggestionTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(SuggestionTable);
