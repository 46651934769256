import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  LinearProgress,
  IconButton,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import {
  Save as SaveIcon,
} from '@material-ui/icons';
import update from '~/services/company/update';
import { creators as errorCreators } from '~/ducks/error';

import './style.css';


class ManageRiskLevel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      companyData: props.companyData,
      riskLevel: props.riskLevel || '',
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.riskLevel !== this.props.riskLevel) {
      this.setState({
        riskLevel: nextProps.riskLevel,
        companyData: nextProps.companyData,
      });
    }
    return true;
  }

  async handleSubmit() {
    this.setState({
      saving: true,
    });
    const updatedCompany = await update(
      this.state.companyData.tickerSymbol,
      { riskLevel: this.state.riskLevel },
    );
    if (updatedCompany.data) {
      this.setState({
        saving: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Company risk level updated successfully',
        action: false,
      }));
    } else {
      this.setState({
        saving: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Failed to update company risk level',
        action: false,
      }));
    }
  }

  handleChange(event) {
    this.setState({
      riskLevel: event.target.value,
    });
  }

  render() {
    return (
      <Paper square id="risk-level-module-container">
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Risk Level
            </Typography>
            <IconButton
              onClick={() => this.handleSubmit()}
              aria-label="Save company tags"
              color="inherit"
              disabled={this.state.saving || this.state.riskLevel === this.props.riskLevel}
            >
              <SaveIcon />
            </IconButton>
          </Toolbar>
          {this.state.saving && (
            <div className="loading-container">
              <LinearProgress className="loading" />
            </div>
          )}
        </AppBar>
        <div className="risk-level-body-container">
          <RadioGroup aria-label="riskLevel" name="riskLevel" value={this.state.riskLevel || ''} onChange={e => this.handleChange(e)}>
            <FormControlLabel value="LOW" control={<Radio />} label="Conservative" />
            <FormControlLabel value="MODERATE" control={<Radio />} label="Moderate" />
            <FormControlLabel value="HIGH" control={<Radio />} label="Aggressive" />
          </RadioGroup>
        </div>
      </Paper>
    );
  }
}

ManageRiskLevel.propTypes = {
  riskLevel: PropTypes.string.isRequired,
  companyData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ManageRiskLevel);
