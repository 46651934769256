import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  CircularProgress,
  Button,
  Toolbar,
  InputBase,
  IconButton,
  MenuItem,
  Tooltip,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';

import getAll from '~/services/accessCode/getAll';
import expire from '~/services/accessCode/expire';
import { creators as errorCreators } from '~/ducks/error';

class AccessCodeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      accessCodes: [],
      searchActive: false,
      filterMenu: null,
      textSearch: '',
      sortBy: '',
      order: 'desc',
      page: 0,
      entries: 15,
    };

    this.searchInput = React.createRef();

    this.toggleSearch = this.toggleSearch.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.filterAccessCodes = this.filterAccessCodes.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.sortAccessCodes = this.sortAccessCodes.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleEntriesChange = this.handleEntriesChange.bind(this);
  }

  async componentDidMount() {
    if (this.state.fetching) {
      const response = await getAll();
      if (response.error) {
        this.props.dispatch(errorCreators.new({
          message: 'Failed to fetch access codes',
          action: false,
        }));
        this.setState({
          fetching: false,
          accessCodes: [],
        });
      } else {
        this.setState({
          accessCodes: response.data.accessCodes,
          fetching: false,
        });
      }
    }
  }

  async handleDelete(accessCodeId) {
    if (confirm('Expire this access code?')) {
      const response = await expire(accessCodeId);
      if (response.success) {
        const refreshResponse = await getAll();
        if (refreshResponse.error) {
          this.props.dispatch(errorCreators.new({
            message: 'Failed to refresh access codes',
            action: false,
          }));
        } else {
          this.setState({
            accessCodes: refreshResponse.data.accessCodes,
          });
        }
      } else {
        this.props.dispatch(errorCreators.new({
          message: 'Failed to expire access code',
        }));
      }
    }
  }

  handleEntriesChange(event) {
    this.setState({
      entries: event.target.value,
    });
  }

  sortBy(by) {
    if (by === this.state.sortBy) {
      this.setState({
        order: this.state.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortBy: by,
        order: 'desc',
      });
    }
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleSearchTextChange(event) {
    this.setState({
      textSearch: event.target.value,
    });
  }

  toggleSearch() {
    if (this.state.searchActive) {
      this.setState({
        searchActive: false,
        textSearch: '',
      });
    } else {
      this.setState({
        searchActive: true,
      }, () => this.searchInput.current.focus());
    }
  }


  sortAccessCodes(accessCodes) {
    if (!this.state.sortBy) {
      return accessCodes;
    }
    const sortedAccessCodes = accessCodes.sort((a, b) => {
      if (typeof get(a, this.state.sortBy, '') === 'string') {
        const x = get(a, this.state.sortBy, '').toLowerCase();
        const y = get(b, this.state.sortBy, '').toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
      }
      return a[this.state.sortBy] - b[this.state.sortBy];
    });
    if (this.state.order === 'desc') {
      return sortedAccessCodes;
    }
    return sortedAccessCodes.reverse();
  }

  filterAccessCodes() {
    if (!this.state.searchActive) {
      return this.state.accessCodes;
    }

    return this.state.accessCodes.filter((accessCode) => {
      const code = get(accessCode, 'accessCode', '');
      const description = get(accessCode, 'description', '');
      const creatorFirstName = get(accessCode, 'creator[0].basic.firstName', '');
      const creatorLastName = get(accessCode, 'creator[0].basic.lastName', '');

      if (creatorFirstName.toLowerCase().indexOf(this.state.textSearch.toLowerCase()) !== -1) {
        return true;
      } else if (
        creatorLastName.toLowerCase().indexOf(this.state.textSearch.toLowerCase()) !== -1
      ) {
        return true;
      } else if (`${creatorFirstName} ${creatorLastName}`.toLowerCase().indexOf(this.state.textSearch.toLowerCase()) !== -1) {
        return true;
      } else if (
        code.toLowerCase().indexOf(this.state.textSearch.toLowerCase()) !== -1
      ) {
        return true;
      } else if (
        description.toLowerCase().indexOf(this.state.textSearch.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    });
  }

  render() {
    return (
      <div id="module-container" className={this.state.fetching ? 'fetching' : 'full'}>
        <Paper className="table-paper">
          {this.state.fetching &&
            <div className="circular-loading-container">
              <CircularProgress />
            </div>
          }
          {!this.state.fetching &&
            <div id="module-content" className="full-width">
              <Toolbar>
                <div className="title-container">
                  <Typography variant="h6" id="tableTitle">
                    Access Codes
                  </Typography>
                </div>
                <div id="page-entries">
                  <FormControl style={{ minWidth: '70px' }}>
                    <InputLabel htmlFor="per-page">Per Page</InputLabel>
                    <Select
                      value={this.state.entries}
                      onChange={this.handleEntriesChange}
                      inputProps={{ id: 'per-page' }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  id="toolbar-search"
                  className={this.state.searchActive ? 'open' : 'closed'}
                >
                  <Tooltip enterDelay={300} title="Search">
                    <IconButton
                      onClick={this.toggleSearch}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  {
                    this.state.searchActive &&
                    <InputBase
                      id="text-search"
                      placeholder="Search"
                      onChange={this.handleSearchTextChange}
                      inputRef={this.searchInput}
                      value={this.state.searchText}
                    />
                  }
                </div>
                <div
                  id="toolbar-add"
                >
                  <Tooltip enterDelay={300} title="Create Access Code">
                    <Link to={'/dashboard/accesscodes/create'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </div>
              </Toolbar>
              <div id="table-container">
                <Table id="table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'created_at'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('created_at')}
                          >
                            Date Created
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'accessCode'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('accessCode')}
                          >
                            Code
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'creator[0].basic.lastName'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('creator[0].basic.lastName')}
                          >
                            Creator
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'uses'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('uses')}
                          >
                            Total Uses
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'usesLeft'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('usesLeft')}
                          >
                            Uses Left
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'investments'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('investments')}
                          >
                            Expire
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody id="table-body">
                    {this.sortAccessCodes(this.filterAccessCodes())
                      .slice(
                        this.state.page * this.state.entries,
                        (this.state.page * this.state.entries) + this.state.entries,
                      )
                      .map(row => (
                        <TableRow key={`accessCode-${row._id}`}>
                          <TableCell component="th" scope="row">{moment(get(row, 'created_at', null)).format('MM/DD/YYYY HH:MM')}</TableCell>
                          <Tooltip enterDelay={300} title={get(row, 'description', false) || 'No Description'}>
                            <TableCell>
                              {row.accessCode}
                            </TableCell>
                          </Tooltip>
                          <TableCell>{`${get(row, 'creator[0].basic.firstName')} ${get(row, 'creator[0].basic.lastName')}`}</TableCell>
                          <TableCell>{row.uses}</TableCell>
                          <TableCell>{row.usesLeft}</TableCell>
                          <TableCell align="right">
                            {row.expireDate ?
                              moment(row.expireDate).format('MM/DD/YYYY HH:MM')
                              : <Button
                                variant="outlined"
                                onClick={() => this.handleDelete(row._id)}
                              >
                                Expire
                              </Button>}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                component="div"
                id="table-pagination"
                count={this.state.accessCodes.length}
                rowsPerPage={this.state.entries}
                rowsPerPageOptions={[]}
                page={this.state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
              />
            </div>
          }
        </Paper>
      </div>
    );
  }
}

AccessCodeTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(AccessCodeTable);
