import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  LinearProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import {
  ExpandMore,
  Refresh,
} from '@material-ui/icons';

import getReauthFundingSource from '~/services/users/getReauthFundingSource';
import sendEmailFundingSource from '~/services/users/sendEmailFundingSource';
import { creators as errorCreators } from '~/ducks/error';
import '../style.css';

class ReauthFundingSource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      fundingSource: [],
      capital: false,
      roundupsEnabled: true,
      expanded: true,
    };

  }

  async componentDidMount() {
    if (this.state.fetching) {
      await this.refreshUsersFundingSource(false);
    }
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  async refreshUsersFundingSource(event) {
    if (event) {
      event.stopPropagation();
    }
    if (!this.state.fetching) {
      this.setState({
        fetching: true,
      });
    }
    const response = await getReauthFundingSource(this.state.capital, this.state.roundupsEnabled);
    if (response.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch users who needs their funding source re-authed',
        action: false,
      }));
      this.setState({
        fetching: false,
        fundingSource: [],
      });
    } else {
      this.setState({
        fundingSource: response.data,
        fetching: false,
      });
    }
  }
  async sendEmailToAllUsersForFundingSource(event) {
    event.stopPropagation();
    const response = await sendEmailFundingSource(this.state.capital, this.state.roundupsEnabled);
    if (response.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to send email to all users',
        action: false,
      }));
    } else {
      await this.refreshUsersFundingSource(false);
    }
  }

  render() {
    return (
      <Paper square style={{ height: '50%', marginTop: 30 }}>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              {`Funding Source - Total: ${this.state.fundingSource.length}`}
            </Typography>
            <Tooltip
              title={'Send email'}
              onClick={(event) => this.sendEmailToAllUsersForFundingSource(event)}
            >
              <Button
                size="small"
                variant="outlined"
                style={{ color: 'white', borderColor: 'white', marginRight: '10px' }}
              >
                Send Email To All
              </Button>
            </Tooltip>
            <Tooltip
              title="Refresh Users who needs their funding source to be re-authed"
              onClick={(event) => this.refreshUsersFundingSource(event)}
            >
              <IconButton size={'small'}>
                <Refresh style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            {this.state.fetching &&
              <div className="loading-container" style={{ width: '100%', position: 'absolute', left: 0, top: '44px' }}>
                <LinearProgress className="loading" />
              </div>
            }
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, flexDirection: 'column' }}>
            <div className={'container'}>
              <div>Filters:</div>
              <div className={'item'}>
                <FormControlLabel
                  classes={{ label: 'my-label' }}
                  control={<Switch color={'secondary'} checked={this.state.capital} onChange={() => this.setState({ capital: !this.state.capital }, () => this.refreshUsersFundingSource(false))} />}
                  label={'Capital One'}
                />
              </div>
              <div className={'item'}>
                <FormControlLabel
                  classes={{ label: 'my-label' }}
                  control={<Switch color={'secondary'} checked={this.state.roundupsEnabled} onChange={() => this.setState({ roundupsEnabled: !this.state.roundupsEnabled }, () => this.refreshUsersFundingSource(false))} />}
                  label={'Roundups Enabled'}
                />
              </div>
            </div>
            <TableContainer style={{ maxHeight: 350 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      First Name
                    </TableCell>
                    <TableCell>
                      Last Name
                    </TableCell>
                    <TableCell>
                      Email
                    </TableCell>
                    <TableCell>
                      Bank Name
                    </TableCell>
                    <TableCell>
                      Roundups Enabled
                    </TableCell>
                    <TableCell>
                      Needs Reauth since
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {
                  this.state.fundingSource.map((user, i) => (
                    <TableRow key={`FundingSource_${i}`}>
                      <TableCell>
                        {user.basic.firstName}
                      </TableCell>
                      <TableCell>
                        {user.basic.lastName}
                      </TableCell>
                      <TableCell>
                        {user.basic.email}
                      </TableCell>
                      <TableCell>
                        {user.fundingSource.bankName}
                      </TableCell>
                      <TableCell>
                        {user.settings.roundupsEnabled.toString().toUpperCase()}
                      </TableCell>
                      <TableCell>
                        {moment(user.fundingSource.reauth).format('MM-DD-YY')}
                      </TableCell>
                    </TableRow>
                  ))
                }
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
}

ReauthFundingSource.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(ReauthFundingSource);
