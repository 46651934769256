import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  IconButton,
  Tooltip,
  LinearProgress,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';
import {
  Refresh, ExpandMore,
} from '@material-ui/icons';
import { creators as errorCreators } from '~/ducks/error';
import getDepositsByUser from '~/services/deposits/getByUser';
import { connect } from 'react-redux';
import '../style.css';

class DepositsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deposits: [],
      depositsUpdateLoading: false,
      expanded: true,
    };
  }

  async componentDidMount() {
    if (this.state.deposits.length === 0) {
      await this.getDeposits();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
    return true;
  }

  async getDeposits(success) {
    this.setState({
      depositsUpdateLoading: true,
    });

    const response = await getDepositsByUser(this.props.userKey);
    if (response.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch user\'s deposits',
        action: false,
      }));
      this.setState({
        depositsUpdateLoading: false,
        deposits: [],
      });
    } else {
      this.setState({
        depositsUpdateLoading: false,
        deposits: response.data,
      });
      if (success) {
        success();
      }
    }
  }

  async refreshDeposits(event) {
    event.stopPropagation();
    this.setState({
      depositsUpdateLoading: true,
      deposits: [],
    });
    await this.getDeposits(() => {
      this.props.dispatch(errorCreators.new({
        message: 'Refreshed Deposits Data Successfully',
        action: false,
      }));
    });
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    return (
      <Paper square>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Deposits
            </Typography>
            <Tooltip
              title="Refresh Deposits Data"
              onClick={(event) => this.refreshDeposits(event)}
            >
              <IconButton size={'small'}>
                <Refresh style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            {this.state.depositsUpdateLoading && (
              <div className="loading-container" style={{ width: '100%', position: 'absolute', left: 0, top: '48px' }}>
                <LinearProgress className="loading" />
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, flexDirection: 'column' }}>
            {this.state.deposits.length > 0 &&
              <div>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        Total Deposits
                      </TableCell>
                      <TableCell>
                        {this.state.deposits.length}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Successful Deposits
                      </TableCell>
                      <TableCell>
                        {this.state.deposits.filter(deposit => deposit.status === 'DONE' && get(deposit, 'brokerageStatus', 'None') === 'SUCCESSFUL').length}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Successful/Total Amount
                      </TableCell>
                      <TableCell>
                        {`$${this.state.deposits.filter(deposit => deposit.status === 'DONE' && get(deposit, 'brokerageStatus', 'None') === 'SUCCESSFUL').reduce((a, b) => a + (b.amount || 0), 0)} / $${this.state.deposits.reduce((a, b) => a + (b.amount || 0), 0)}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <TableContainer style={{ maxHeight: 480 }}>
                  <Table stickyHeader>
                    <TableHead style={{ zIndex: 4 }}>
                      <TableRow>
                        <TableCell>
                          DW Id
                        </TableCell>
                        <TableCell>
                          Status / DW Status
                        </TableCell>
                        <TableCell>
                          DW Status
                        </TableCell>
                        <TableCell>
                          Last Updated
                        </TableCell>
                        <TableCell>
                          DW Last Updated
                        </TableCell>
                        <TableCell>
                          Amount
                        </TableCell>
                        <TableCell>
                          Balance Check
                        </TableCell>
                        <TableCell>
                          TimeMachine
                        </TableCell>
                        <TableCell>
                          Companies
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { this.state.deposits.length > 0 &&
                        this.state.deposits.map((deposit, i) => (
                          <TableRow key={`Deposit_${i}`}>
                            <TableCell>
                              {get(deposit, 'drivewealth.id', 'None')}  
                            </TableCell>
                            <TableCell>
                              {deposit.status} / {get(deposit, 'drivewealth.status', 'None')}
                            </TableCell>
                            <TableCell>
                              {get(deposit,'brokerageStatus','None')}
                            </TableCell>
                            <TableCell>
                              {moment(deposit.last_updated).format('MM-DD-YY hh:mm A')}
                            </TableCell>
                            <TableCell>
                              {deposit.brokerageUpdated ? moment(deposit.brokerageUpdated).format('MM-DD-YY hh:mm A') : 'None'}
                            </TableCell>
                            <TableCell>
                              {deposit.amount}
                            </TableCell>
                            <TableCell>
                              {deposit.balanceCheck ?
                                (
                                  <div>
                                    <Typography>{deposit.balanceCheck?.result ? 'PASSED' : 'FAILED'}</Typography>
                                    <Typography variant={'subtitle1'}>Balance: ${deposit.balanceCheck?.availableBalance}</Typography>
                                    <Typography variant={'subtitle2'}>{deposit.balanceCheck?.info}</Typography>
                                  </div>
                                ) : <Typography>No Data</Typography>
                              }
                            </TableCell>
                            <TableCell>
                              {deposit.timeMachine ? 'Yes' : 'No'}
                            </TableCell>
                            <TableCell size={'small'} style={{ padding: 0 }}>
                            {deposit.companies.length > 0 &&
                              <TableContainer style={{ maxHeight: 133 }} size={'small'}>
                                <Table stickyHeader size={'small'}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        Name
                                      </TableCell>
                                      <TableCell>
                                        Symbol
                                      </TableCell>
                                      <TableCell>
                                        Amount
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {deposit.companies.map((company, j) =>
                                      <TableRow key={`Deposit_company_${j}`}>
                                        <TableCell style={{ border: 0 }}>{company.name}</TableCell>
                                        <TableCell style={{ border: 0 }}>{company.symbol}</TableCell>
                                        <TableCell style={{ border: 0 }}>{company.amount}</TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            }
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
}

DepositsList.propTypes = {
  userKey: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(DepositsList);
