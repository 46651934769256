import { creators as errorCreators } from '~/ducks/error';
import { creators as appCreators } from '~/ducks/app';

import apiLogin from '~/services/user/login';
import { intersection } from 'lodash';

// => ACTIONS
const actions = {
  LOGGED_IN: 'users/LOGGED_IN',
  LOGIN: 'users/LOGIN',
  LOGOUT: 'user/LOGOUT',
};

// => STATE
let INITIAL_STATE = {
  isAuthed: false,
};

if (!!sessionStorage.getItem('grifinAuthStore')) {
  INITIAL_STATE = {
    isAuthed: true,
    ...JSON.parse(sessionStorage.getItem('grifinAuthStore')),
  }
}

const reducer = (state = INITIAL_STATE, action) => {
  const nextState = state;

  switch (action.type) {
    case actions.LOGGED_IN:
      return {
        ...state,
        ...{
          isAuthed: true,
        },
        ...action.payload.data,
      };
    case actions.LOGOUT:
      return {
        isAuthed: false,
      };

    default:
      break;
  }

  return nextState;
};

// => CREATORS
const creators = {
  loggedIn: payload => ({ type: actions.LOGGED_IN, payload }),
  logout: payload => ({ type: actions.LOGOUT, payload }),
};

// => THUNKS
const login = payload => async (dispatch) => {
  dispatch(appCreators.loading());
  const { email, password } = payload;
  if (!email || !password) {
    dispatch(appCreators.doneLoading());
    dispatch(errorCreators.new({
      message: 'Log in credentials not found',
      action: false,
    }));
  }
  const loginResponse = await apiLogin(email, password);
  if (loginResponse.error) {
    dispatch(appCreators.doneLoading());
    dispatch(errorCreators.new({
      message: loginResponse.error.message || 'Failed to log in.',
      action: false,
    }));
  } else if (
    intersection(
      loginResponse.data.roles,
      [
        'admin',
        'data-entry',
        'customer-service',
      ],
    ).length === 0
  ) {
    dispatch(appCreators.doneLoading());
    dispatch(errorCreators.new({
      message: 'This dashboard is for Grifin staff only',
      action: false,
    }));
  } else {
    dispatch(appCreators.doneLoading());
    dispatch(creators.loggedIn({
      data: loginResponse.data,
    }));
    sessionStorage.setItem('grifinAuthStore', JSON.stringify(loginResponse.data));
  }
};

const logout = () => async (dispatch) => {
  sessionStorage.removeItem('grifinAuthStore');
  dispatch(creators.logout());
}

const thunks = {
  login,
  logout,
};

export default reducer;
export { actions, creators, thunks };
