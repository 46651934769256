import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';

class AddNotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      icon: '',
    };
  }

  handleTextChange(name, event) {
    this.setState({ [name]: event.target.value.length > 48 ? event.target.value.slice(0, 48) : event.target.value });
  }

  render() {
    const { text, icon } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={this.props.open}
        onClose={() => this.props.parentState({ openModal: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" className="flex-grow">
                Add New Field
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            style={{ width: '95%' }}
            id="text"
            label="Text"
            className={'input-field'}
            value={this.state.text}
            onChange={e => this.handleTextChange('text', e)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.parentState({ openModal: false })}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.parentState({ openModal: false });
              this.props.addIntoList({ text, icon });
            }}
            variant="contained"
            color="primary"
            className="space"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AddNotificationList;
