import React from 'react';
import UserTable from './UserTable';


function Users() {
  return (
    <div>
      <UserTable />
    </div>
  );
}

export default Users;
