import React from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  Button,
  TextField,
  IconButton,
} from '@material-ui/core';
import {
  NavigateBefore,
} from '@material-ui/icons';
import { get } from 'lodash';
import create from '~/services/notifications/create';
import update from '~/services/notifications/update';
import ListTable from './ListTable';
import { creators as errorCreators } from '~/ducks/error';
import moment from 'moment';

class NotificationCreate extends React.Component {
  constructor(props) {
    super(props);

    const title = get(props, 'location.query.title', '');
    const releasedOn = get(props, 'location.query.releasedOn', '');
    const list = get(props, 'location.query.list', []);
    this.state = {
      fetching: false,
      title,
      releasedOn,
      list,
    };
  }

  componentDidMount() {
    const id = get(this.props, 'match.params.id', '');
    const title = get(this.props, 'location.query.title', '');
    const releasedOn = get(this.props, 'location.query.releasedOn', '');
    const list = get(this.props, 'location.query.list', []);
    if ((title === '' || releasedOn === '' || list.length === 0) && id !== 'create') {
      location.replace('/dashboard/notifications');
    }
  }

  handleTextChange(stateField, event) {
    this.setState({ [stateField]: event.target.value });
  }

  async handleSubmit() {
    this.setState({
      fetching: true,
    });
    const { title, releasedOn, list } = this.state;

    if (!title || title === '' || !releasedOn || releasedOn === '' || !list || list.length === 0) {
      this.setState({
        fetching: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Please check the fields',
        action: false,
      }));
      return;
    }

    const body = {
      title,
      releasedOn,
      list,
    };
    if (this.props.match.params.id === 'create') {
      const response = await create(body);
      if (response.success) {
        this.setState({
          fetching: false,
        });
        this.props.dispatch(errorCreators.new({
          message: 'Notification create successfully',
          action: false,
        }));
        location.replace('/dashboard/notifications');
      } else {
        this.props.dispatch(errorCreators.new({
          message: `Failed to create notification: ${get(response, 'error.message', '500')}`,
          action: false,
        }));
        this.setState({
          fetching: false,
        });
      }
    } else {
      const updatedNotification = await update(this.props.match.params.id, body);
      if (updatedNotification.data) {
        this.setState({
          fetching: false,
        });
        this.props.dispatch(errorCreators.new({
          message: 'Notification updated successfully',
          action: false,
        }));
        location.replace('/dashboard/notifications');
      } else {
        this.props.dispatch(errorCreators.new({
          message: updatedNotification.error.message || `Failed to update Notification version: ${version}`,
          action: false,
        }));
        this.setState({
          fetching: false,
        });
      }
    }
  }

  addIntoList(element) {
    const list = JSON.parse(JSON.stringify(this.state.list));
    list.push(element);
    this.setState({ list });
  }

  removeFromList(idx) {
    const list = JSON.parse(JSON.stringify(this.state.list));
    list.splice(idx, 1);
    this.setState({ list });
  }

  render() {
    return (
      <div style={{ padding: '10px' }}>
        <Paper square>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                className="menu-button"
                color="inherit"
                aria-label="back"
                onClick={() => this.props.history.goBack()}
              >
                <NavigateBefore />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className="flex-grow"
              >
                {`${this.props.match.params.id === 'create' ? 'Create' : 'Edit'} Notification`}
              </Typography>
            </Toolbar>
          </AppBar>
          <div
            style={{
              padding: '10px',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <TextField
              id="title"
              label="Title"
              value={this.state.title}
              onChange={e => this.handleTextChange('title', e)}
              margin="normal"
            />
            <TextField
              id="releasedOn"
              style={{ width: '150px' }}
              value={moment(this.state.releasedOn).format('YYYY-MM-DD')}
              onChange={e => this.handleTextChange('releasedOn', e)}
              margin="normal"
              type="date"
            />
            <ListTable list={this.state.list} addIntoList={(el) => this.addIntoList(el)} removeFromList={(idx) => this.removeFromList(idx)} />
            <Button
              style={{ marginTop: '30px' }}
              disabled={this.state.fetching}
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => this.handleSubmit()}
            >
              {`${this.props.match.params.id === 'create' ? 'Create' : 'Update'}`}
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}

export default connect()(NotificationCreate);
