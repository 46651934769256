import moment from 'moment';

export default function addBusinessDays(momentDate, addDays) {
  let date = moment(momentDate);
  let days = Math.abs(addDays);
  const add = addDays > 0;
  while (days > 0) {
    if (add) {
      date = date.add(1, 'days');
    } else {
      date = date.subtract(1, 'days');
    }
    // decrease "days" only if it's a weekday.
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1;
    }
  }
  return date;
}
