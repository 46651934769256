import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistCombineReducers, purgeStoredState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import reducers from '~/ducks';


export default function configureStore(initialState = {}, history) {
  const persistConfig = {
    key: 'root',
    storage,
    debug: true,
    blacklist: ['router', 'user'],
  };

  const persistedReducer = persistCombineReducers(persistConfig, { router: connectRouter(history), ...reducers });

  const rootReducer = (state, action) => {
    let newState = state;
    if (action.type === 'users/LOGOUT') {
      purgeStoredState(persistConfig);
      newState = undefined;
    }

    return persistedReducer(newState, action);
  };

  const middleware = [
    routerMiddleware(history),
    thunk,
  ];

  const enhancers = [
    applyMiddleware(...middleware),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
  /* eslint-enable */

  const store = createStore(rootReducer, undefined, composeEnhancers(...enhancers));
  const persistor = persistStore(store);

  // store.asyncReducers = {};

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('../ducks', () => {
      const nextReducer = require("../ducks").default;
      store.replaceReducer(nextReducer);
    });
  }

  return { store, persistor };
}
