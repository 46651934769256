import config from 'Config'; /* eslint-disable-line */
import store from '../../';

const update = async (id, data) => {
  const authUser = store.getState().user;
  return fetch(`${config.apiUrl}/companies/${encodeURIComponent(id)}/logo`, {
    method: 'PUT',
    headers: {
      // 'Content-Type': 'multipart/form-data',
      authorization: authUser.access_token,
      'grifin-user-key': authUser.publicKey,
    },
    body: data,
  })
    .then(async (response) => {
      if (response.status !== 200 || false) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .then(async (response) => {
      const json = await response.json();

      return { success: true, data: json };
    })
    .catch(error => ({ error }));
};

export default update;
