import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  LinearProgress,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';
import {
  Save as SaveIcon,
} from '@material-ui/icons';
import update from '~/services/company/update';
import get from '~/services/tags/get';
import { creators as errorCreators } from '~/ducks/error';

import './style.css';

function tagsMatch(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i += 1) {
    if (!arr1.includes(arr2[i])) return false;
    // if (!arr2.includes(arr1[i])) return false;
  }

  return true;
}

class ManageTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      posting: false,
      tags: [],
      companyTags: props.companyData.tags || [],
      companyData: props.companyData,
    };
  }

  async componentDidMount() {
    if (this.state.fetching) {
      const tags = await get();
      if (tags.error) {
        this.props.dispatch(errorCreators.new({
          message: 'Availiable tags not found in api config',
          action: false,
        }));
      } else {
        this.setState({
          tags: tags.data,
          fetching: false,
        });
      }
    }
  }

  handleTagToggle(tag) {
    const tagIndex = this.state.companyTags.indexOf(tag);
    const companyTags = [...this.state.companyTags];

    if (tagIndex !== -1) {
      companyTags.splice(tagIndex, 1);
    } else {
      companyTags.push(tag);
    }

    this.setState({
      companyTags,
    });
  }

  async handleSubmit() {
    this.setState({
      posting: true,
    });
    const updatedCompany = await update(
      this.state.companyData.tickerSymbol,
      { tags: this.state.companyTags },
    );
    if (updatedCompany.data) {
      this.setState({
        posting: false,
        companyData: updatedCompany.data,
        companyTags: updatedCompany.data.tags,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Company tags updated successfully',
        action: false,
      }));
    } else {
      this.setState({
        loading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Failed to update company tags',
        action: false,
      }));
    }
  }

  _renderTagRow(tag) {
    const active = this.state.companyTags.includes(tag);

    return (
      <TableRow key={tag}>
        <TableCell>{tag}</TableCell>
        <TableCell align="right">
          <Checkbox
            checked={active}
            onChange={() => this.handleTagToggle(tag)}
            value={tag}
          />
        </TableCell>
      </TableRow>
    );
  }

  render() {
    if (this.state.fetching) {
      return (
        <Paper square id="tags-module-container">
          <AppBar position="static">
            <Toolbar>
              <Typography
                variant="h6"
                color="inherit"
                className="flex-grow"
              >
                Company Tags
              </Typography>
            </Toolbar>
            {this.state.loading && (
              <div className="loading-container">
                <LinearProgress className="loading" />
              </div>
            )}
          </AppBar>
          <div className="tag-content-container">
            <CircularProgress className="loading-circle" />
          </div>
        </Paper>
      );
    }
    return (
      <Paper square id="tags-module-container">
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Company Tags
            </Typography>
            <IconButton
              onClick={() => this.handleSubmit()}
              aria-label="Save company tags"
              color="inherit"
              disabled={
                this.state.loading
                || tagsMatch(this.state.companyTags, this.state.companyData.tags || [])}
            >
              <SaveIcon />
            </IconButton>
          </Toolbar>
          {this.state.loading && (
            <div className="loading-container">
              <LinearProgress className="loading" />
            </div>
          )}
        </AppBar>
        <div className="tag-content-container">
          <div className="tag-table-body-container">
            <Table stickyHeader size={'small'}>
              <TableBody className="tag-table-body">
                {this.state.tags.map(tag => this._renderTagRow(tag))}
              </TableBody>
            </Table>
          </div>
        </div>
      </Paper>
    );
  }
}

ManageTags.propTypes = {
  companyData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ManageTags);
