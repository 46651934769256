import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  LinearProgress,
  ListItemAvatar,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';
import {
  Lock,
  LockOpen,
  Refresh,
  ToggleOn,
  ToggleOff,
  DeleteForever,
  ExpandMore,
} from '@material-ui/icons';
import { creators as errorCreators } from '~/ducks/error';
import getByUser from '~/services/banks/getByUser';
import { connect } from 'react-redux';
import { green, berry } from '~/theme/colors';

class BanksSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banks: null,
      banksUpdateLoading: false,
      expanded: true,
    };
  }

  async componentDidMount() {
    if (!this.state.bank) {
      await this.getBanks();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
    return true;
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  async getBanks() {
    this.setState({
      banksUpdateLoading: true,
    });
    const banks = await getByUser(this.props.userKey);
    if (banks.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch banks for user',
        action: false,
      }));
      this.setState({
        banksUpdateLoading: false,
        banks: [],
      });
    } else {
      this.setState({
        banksUpdateLoading: false,
        banks: banks.data,
      });
    }
  }

  async refreshBanks(event) {
    event.stopPropagation();
    this.setState({
      banksUpdateLoading: true,
      banks: null,
    });
    await this.getBanks();
    this.props.dispatch(errorCreators.new({
      message: 'Refreshed Banks Successfully',
      action: false,
    }));
  }

  render() {
    return (
      <Paper square>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Transaction Banks
            </Typography>
            <Tooltip
              title="Refresh Bank Data"
              onClick={(event) => this.refreshBanks(event)}
            >
              <IconButton size={'small'}>
                <Refresh style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            {(this.state.banksUpdateLoading || !this.state.banks) && (
              <div className="loading-container" style={{ width: '100%', position: 'absolute', left: 0, top: '48px' }}>
                <LinearProgress className="loading" />
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, flexDirection: 'column' }}>
            <List>
              {
                this.state.banks && this.state.banks.map((bank, i) => ([
                  <ListItem key={`BankListItem_${i}`}>
                    {
                      bank.deleted
                        ? (
                          <Tooltip
                            title={get(bank, 'institution.name', 'Unknown')}
                          >
                            <ListItemIcon>
                              <DeleteForever />
                            </ListItemIcon>
                          </Tooltip>
                        )
                        : (
                          <Tooltip
                            title={get(bank, 'institution.name', 'Unknown')}
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt={get(bank, 'institution.name', 'Unknown')}
                                src={`data:image/jpg;base64,${bank.branding.logo}`}
                              />
                            </ListItemAvatar>
                          </Tooltip>
                        )
                    }
                    <ListItemText
                      primary={get(bank, 'institution.name', 'Unknown')}
                      secondary={`Last Sync: ${bank.transactionsSynced ? moment(bank.transactionsSynced).format('MM-DD-YY hh:mmA') : 'Never'}`}
                    />
                    <Tooltip
                      title={
                        get(bank, 'reauth', false)
                          ? `Needs Reauth since ${moment(get(bank, 'reauth', false)).format('MM-DD-YY')}`
                          : 'Auth Connected'
                      }
                    >
                      <ListItemIcon>
                        {
                          get(bank, 'reauth', false)
                            ? <Lock />
                            : <LockOpen />
                        }
                      </ListItemIcon>
                    </Tooltip>
                  </ListItem>,
                  <List key={`BankList_${i}`}>
                    {
                      bank.accounts.map((account, i) => (
                        <ListItem key={`BankAccounts_${i}`}>
                          <ListItemText
                            primary={account.official_name ? account.official_name : get(account, 'name', 'Unknown Name')}
                            secondary={`${get(account, 'subtype', get(account, 'type', 'Unknown Type'))} - ${get(account, 'mask', '****')}`}
                          />
                          <Tooltip
                            title={`Active: ${get(account, 'active', 'Unknown').toString()}`}
                          >
                            <ListItemIcon>
                              {
                                get(account, 'active', false)
                                  ? <ToggleOn style={{ color: green }} />
                                  : <ToggleOff style={{ color: berry }} />
                              }
                            </ListItemIcon>
                          </Tooltip>
                        </ListItem>
                      ))
                    }
                  </List>,
                ]))
              }
            </List>
          </AccordionDetails>
        </Accordion>        
      </Paper>
    );
  }
}

BanksSummary.propTypes = {
  userKey: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(BanksSummary);
