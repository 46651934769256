import React, { useState } from "react";
import Modal from "react-modal";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useToasts } from "react-toast-notifications";

import { gql, useApolloClient } from "@apollo/client";

import "./styles.css";

Modal.setAppElement("#root");

function renderBrokerageStatus (status) {
  let color = "#ccc";
  switch (status.name) {
    case "OPEN":
      color = "green";
      break;
    case "OPEN_NO_NEW_TRADES":
      color = "yellow";
      break;
    case "CLOSED":
      color = "red";
      break;
    default:
      break;
  }

  return (
    <span className="flex items-center flex-grow">
      <span className="block w-3 h-3 rounded-full tooltip right mr-2" data-content={`${status.description}`} style={{ backgroundColor: color }}></span>
      {status.description}
    </span>
  )
}

function UpdateAccountStatus ({ isOpen, close, user, account, refresh }) {
  const apolloClient = useApolloClient();
  const { addToast } = useToasts();

  const [statusChangeChecked, setStatusChangeChecked] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);

  const handleStatusChange = name => event => {
    setStatusChangeChecked(event.target.checked);
  }

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={500}
      style={{
        content: {
          inset: undefined,
          right: "0px",
          height: "100vh",
          width: "40vw"
        }
      }}
    >
      <div className="flex justify-between mb-4">
        <h2 className="text-lg font-bold">Brokerage Account Info</h2>
        <a href="#" onClick={(e) => {
          e.preventDefault();
          close();
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </a>
      </div>

      <div>
        <div className="font-bold mb-2">{user.name}</div>
        <div className="text-sm"><b>ID:</b> {account.info.account.id}</div>
        <div className="text-sm"><b>Account Number:</b> {account.info.account.accountNo}</div>
        <div className="text-sm flex items-center">
          <b className="pr-2">Status:</b>
          {renderBrokerageStatus(account.info.account.status)}
          {
            ["OPEN", "OPEN_NO_NEW_TRADES"].includes(account.info.account.status.name) && !statusOpen && (
              <a href="#openStatus"
                className="flex items-center"
                onClick={(e) => {
                  e.preventDefault();
                  setStatusOpen(true);
                }}
              >
                {
                  account.info.account.status.name === "OPEN_NO_NEW_TRADES" ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  )
                }

                <span className="pl-1">
                  {account.info.account.status.name === "OPEN" ? "Pause" : "Activate"}{" "}
                  Account
                </span>
              </a>
            )
          }
          {
            statusOpen && (
              <a href="closeStatus"
                className="flex items-center"
                onClick={(e) => {
                  e.preventDefault();
                  setStatusOpen(false);
                }}
              >
                <span>Cancel</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </a>
            )
          }
        </div>
      </div>

      <div className={`${statusOpen ? "block" : "hidden"} p-5 border shadow rounded`}>
        {
          account.info.account.status.name === "OPEN" && (
            <>
            <h3 className="font-bold text-lg mb-2">Are you sure you want to pause the users account?</h3>
            <div>
              By pausing a users brokerage account the following will changes will be made.
                <ul className="my-2 list-disc list-inside">
                <li>Users brokerage account will be set to "Open, no new trades".</li>
                <li>All round-ups will be disabled for the user.</li>
                <li>Deposits will be disabled for any transactions that have aleady been logged.</li>
              </ul>
              <span className="text-sm italic">* Users can continue to sell their positions and withdraw their cash.</span>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={statusChangeChecked}
                    onChange={handleStatusChange("statusChange")}
                    value="statusChange"
                  />
                }
                label="Confirm Change" />
            </div>

            <a href="#"
              className="flex justify-center p-2 rounded text-white font-bold" style={{ backgroundColor: !statusChangeChecked ? "#ccc" : "#4350AE" }}
              onClick={async (e) => {
                e.preventDefault();

                if (!statusChangeChecked) return;

                try {
                  const updatedUser = await apolloClient.mutate({
                    mutation: gql`
                      mutation PauseUserBrokerageAccount($id: String) {
                        pauseUserBrokerageAccount(id: $id, input: $input) @rest(type: "User", path: "/users/{args.id}/toggleBrokerageAccountStatus", method: "POST") {
                          data @type(name: "User")
                        }
                      }
                    `,
                    variables: {
                      id: user.publicKey,
                      input: { active: false }
                    },
                  });

                  setStatusOpen(false);
                  setStatusChangeChecked(false);
                  refresh();

                  addToast(`User brokerage account has been paused.`, { appearance: "success", autoDismiss: true });
                } catch(error) {
                  console.log(error);
                  addToast(`Unable to pause user brokerage account.`, { appearance: "error", autoDismiss: true });
                }
              }}
            >
              Save
            </a>
            </>
          )
        }

        {
          account.info.account.status.name === "OPEN_NO_NEW_TRADES" && (
            <>
            <h3 className="font-bold text-lg mb-2">Are you sure you want to activate the users account?</h3>
            <div>
              By activating a users brokerage account the following will changes will be made.
                <ul className="my-2 list-disc list-inside">
                <li>Users brokerage account will be set to "Open".</li>
                {/* <li>All round-ups will be disabled for the user.</li>
                <li>Deposits will be disabled for any transactions that have aleady been logged.</li> */}
              </ul>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={statusChangeChecked}
                    onChange={handleStatusChange("statusChange")}
                    value="statusChange"
                  />
                }
                label="Confirm Change" />
            </div>

            <a href="#"
              className="flex justify-center p-2 rounded text-white font-bold" style={{ backgroundColor: !statusChangeChecked ? "#ccc" : "#4350AE" }}
              onClick={async (e) => {
                e.preventDefault();

                if (!statusChangeChecked) return;

                try {
                  const updatedUser = await apolloClient.mutate({
                    mutation: gql`
                      mutation PauseUserBrokerageAccount($id: String) {
                        pauseUserBrokerageAccount(id: $id, input: $input) @rest(type: "User", path: "/users/{args.id}/toggleBrokerageAccountStatus", method: "POST") {
                          data @type(name: "User")
                        }
                      }
                    `,
                    variables: {
                      id: user.publicKey,
                      input: { active: true }
                    },
                  });

                  setStatusOpen(false);
                  setStatusChangeChecked(false);
                  refresh();

                  addToast(`User brokerage account has been activated.`, { appearance: "success", autoDismiss: true });
                } catch(error) {
                  console.log(error);
                  addToast(`Unable to activate user brokerage account.`, { appearance: "error", autoDismiss: true });
                }
              }}
            >
              Save
            </a>
            </>
          )
        }

      </div>

      <h3 className="font-bold text-lg my-2">Cash Positions</h3>
      <div className="grid grid-cols-2 gap-4">
        <div className="text-sm">
          <h4 className="mb-2 font-bold">BoD</h4>
          <div className="flex justify-between">
            <div className="font-bold">Cash Balance</div>
            <div>${account.info.account.bod.cashBalance}</div>
          </div>
          <div className="flex justify-between">
            <div className="font-bold">Cash for Withdrawal</div>
            <div>${account.info.account.bod.cashAvailableForWithdrawal}</div>
          </div>
          <div className="flex justify-between">
            <div className="font-bold">Cash for Trading</div>
            <div>${account.info.account.bod.cashAvailableForTrading}</div>
          </div>
          <div className="flex justify-between">
            <div className="font-bold">Money Market</div>
            <div>${account.info.account.bod.moneyMarket}</div>
          </div>
        </div>
        <div className="text-sm">
          <h4 className="mb-2 font-bold">Current</h4>
          <div className="flex justify-between">
            <div className="font-bold">Cash Balance</div>
            <div>${account.summary.accountSummary.cash.cashBalance}</div>
          </div>
          <div className="flex justify-between">
            <div className="font-bold">Cash for Withdrawal</div>
            <div>${account.summary.accountSummary.cash.cashAvailableForWithdrawal}</div>
          </div>
          <div className="flex justify-between">
            <div className="font-bold">Cash for Trading</div>
            <div>${account.summary.accountSummary.cash.cashAvailableForTrade}</div>
          </div>
        </div>
      </div>

      <h3 className="font-bold text-lg my-2">Equity Positions</h3>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="text-sm">
          <div className="flex justify-between">
            <div className="font-bold">BoD</div>
            <div>${account.info.account.bod.equityValue}</div>
          </div>
          <div className="flex justify-between">
            <div className="font-bold">Current</div>
            <div>${account.summary.accountSummary.equity.equityValue}</div>
          </div>
        </div>
        <div className="text-sm"></div>
      </div>

      {
        account.summary.accountSummary.equity.equityPositions.length > 0 && (
          <div className="text-sm rounded border p-2 shadow">
            <div className="py-1 grid grid-cols-4">
              <div>Symbol</div>
              <div>Open</div>
              <div>Available</div>
              <div>Basis</div>
            </div>
            {
              account.summary.accountSummary.equity.equityPositions.map((position, index) => (
                <div key={`position-${position.symbol}`} className={`py-1 grid grid-cols-4 ${index + 1 === account.summary.accountSummary.equity.equityPositions.length ? "" : "border-b"}`}>
                  <div className="font-bold">{position.symbol}</div>
                  <div>{position.openQty}</div>
                  <div>{position.availableForTradingQty}</div>
                  <div>{position.costBasis}</div>
                </div>
              ))
            }
          </div>
        )
      }
    </Modal>
  )
}

export default UpdateAccountStatus;
