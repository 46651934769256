import React from 'react';
import {
  Paper,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  withStyles,
} from '@material-ui/core';
import {
  PlayCircleFilled,
  Stop,
  ExpandMore,
} from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';

import { get } from 'lodash';
import PropTypes from 'prop-types';

const CustomSwitch = withStyles({
  switchBase: {
    color: red[600],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
    "&.Mui-checked:hover": {
      backgroundColor: 'rgba(76, 175, 80, 0.12)',
    },
  },
  checked: {},
  track: {
    backgroundColor: red[500],
  },
})(Switch);

class InvestmentProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
    return true;
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  async toggleChecked(event) {
    const checked = event.target.checked;
    this.props.updateRoundups(checked);
  }

  render() {
    const { settings, investment, roles } = this.props;

    return (
      <Paper square style={{ marginBottom: 8 }}>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
            >
              Investment Profile
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {'Oneup Amount'}
                  </TableCell>
                  <TableCell>
                    {
                      investment.defaultOneup
                        ? `$${investment.defaultOneup.toFixed(2)}`
                        : 'Not Set'
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {'Blacklist'}
                  </TableCell>
                  <TableCell>
                    {get(investment, 'blacklist', null)
                      ? get(investment, 'blacklist', []).map((ticker, i) => (
                        <Chip
                          key={`BlackList${i}`}
                          style={{ margin: 3 }}
                          label={ticker}
                        />
                      ))
                      : 'None'
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {'Monthly Deposit Limit'}
                  </TableCell>
                  <TableCell>
                    {
                      investment.monthlyDepositLimit
                        ? `$${investment.monthlyDepositLimit.toFixed(2)}`
                        : 'Not Set'
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {'Risk Tolerance'}
                  </TableCell>
                  <TableCell>
                    {get(investment, 'riskTolerance', 'Not Set')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {'Automated Investments'}
                  </TableCell>
                  <TableCell>
                    {(roles.includes('customer-service-manager') || roles.includes('admin'))
                      && <Tooltip title={!!get(settings, 'roundupsEnabled', false) ? 'Enabled' : 'Disabled'}>
                        <CustomSwitch value={get(settings, 'roundupsEnabled', false)} checked={!!get(settings, 'roundupsEnabled', false)} onChange={e => this.toggleChecked(e)} />
                      </Tooltip>
                    }
                    {!(roles.includes('customer-service-manager') || roles.includes('admin'))
                      && <>
                        {
                          get(settings, 'roundupsEnabled', false)
                            ? <Tooltip title={'Enabled'}><PlayCircleFilled style={{ color: green[500] }} /></Tooltip>
                            : <Tooltip title={'Disabled'}><Stop style={{ color: red[500] }} /></Tooltip>
                        }
                      </>
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {'Deposit Day'}
                  </TableCell>
                  <TableCell>
                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'][get(investment, 'weekdayDeposit', 2) - 1]}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
}

InvestmentProfile.propTypes = {
  settings: PropTypes.object,
  investment: PropTypes.object,
};

InvestmentProfile.defaultProps = {
  settings: {},
  investment: {},
};

export default InvestmentProfile;
