import { thunks } from '~/ducks/user';

// => ACTIONS
import store from '../index';

const actions = {
  CLEAR: 'error/CLEAR',
  NEW: 'error/NEW',
};

// => STATE
const INITIAL_STATE = {
  error: false,
  message: '',
  action: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  const nextState = state;

  switch (action.type) {
    case actions.CLEAR:
      return {
        // ...state,
        ...INITIAL_STATE,
        message: state.message,
      };
    case actions.NEW:
      if ([401, 403].includes(action.payload.responseCode)) {
        store.dispatch(thunks.logout());
      }
      return {
        ...state,
        ...{
          error: true,
          message: action.payload.message,
          action: action.payload.action,
        },
      };
    default:
      break;
  }

  return nextState;
};

// => CREATORS
const creators = {
  clear: () => ({ type: actions.CLEAR }),
  new: payload => ({ type: actions.NEW, payload }),
};

export default reducer;
export { actions, creators };
