export const yellow = '#FFDE2F';
export const green = '#60BC56';
export const orange = '#F3703A';
export const skillet = '#253738';
export const berry = '#DB406C';
export const turquoise = '#00656F';
export const darkGreen = '#00896A';
export const white = '#FFFFFF';
export const halfwayGrey = '#808080';
export const lightGrey = '#C4C4C4';
export const lightLightGrey = '#F2F2F2';
export const threeQuarterGrey = '#BFBFBF';
export const banana = '#FFEDB4';
export const crimson = '#9D0400';
export const nobleBlack = '#202223'
