import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  Switch,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import {
  Refresh,
} from '@material-ui/icons';
import fetch from '~/services/transactions/fetch';
import insert from '~/services/transactions/insert';
import update from '~/services/company/update';
import { connect } from 'react-redux';

class AddMultipleTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      randomCompanies: [],
    };
  }

  componentDidMount() {
    if (this.props.open) {
      this.fetchCompanies();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.open !== this.props.open && nextProps.open) {
      this.fetchCompanies();
    }
    return true;
  }

  async fetchCompanies() {
    this.setState({
      randomCompanies: [],
    });
    const response = await fetch();
    this.setState({
      randomCompanies: response.data,
    });
  }

  async insertTransactions() {
    await insert(this.props.userKey, this.state.randomCompanies.filter(company => company.active));
    this.props.refreshTransactions();
  }

  handleCompanyToggle(event, tickerSymbol) {
    const checked = event.target.checked;
    const companies = JSON.parse(JSON.stringify(this.state.randomCompanies));
    const findCompany = companies.find(company => company.tickerSymbol === tickerSymbol);
    if (findCompany) {
      findCompany.active = checked;
      this.setState({
        randomCompanies: companies,
      });
    }
  }

  async handleChange(event, tickerSymbol) {
    const riskLevel = event.target.value;
    const updatedCompany = await update(
      tickerSymbol,
      { riskLevel },
    );
    if (updatedCompany.data) {
      const companies = JSON.parse(JSON.stringify(this.state.randomCompanies));
      const findCompany = companies.find(company => company.tickerSymbol === tickerSymbol);
      if (findCompany) {
        findCompany.riskLevel = riskLevel;
        this.setState({
          randomCompanies: companies,
        });
      }
    }
  }

  render() {
    return (
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={this.props.open}
        onClose={() => this.props.parentState({ openMultiple: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" className="flex-grow">
                Generate Random Transactions
              </Typography>
              <Tooltip
                title="Refresh Companies"
                onClick={() => this.fetchCompanies()}
              >
                <IconButton>
                  <Refresh style={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        {this.state.randomCompanies.length === 0 &&
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        }
        {this.state.randomCompanies.length > 0 &&
          <DialogContent>
            <Table stickyHeader size={'small'} id="table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel
                        active={false}
                      >
                        Ticker Symbol
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel
                        active={false}
                      >
                        Name
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel
                        active={false}
                      >
                        Risk Level
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel
                        active={false}
                      >
                        Include
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="table-body">
                {this.state.randomCompanies.map(row => (
                  <TableRow key={`transaction-${row.tickerSymbol}`}>
                    <TableCell component="th" scope="row">{row.tickerSymbol}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      <RadioGroup style={{ flexDirection: 'row' }} aria-label="riskLevel" name="riskLevel" value={row.riskLevel || ''} onChange={e => this.handleChange(e, row.tickerSymbol)}>
                        <FormControlLabel value="Conservative" control={<Radio />} label="Conservative" />
                        <FormControlLabel value="Moderate" control={<Radio />} label="Moderate" />
                        <FormControlLabel value="Aggressive" control={<Radio />} label="Aggressive" />
                      </RadioGroup>
                    </TableCell>
                    <TableCell>
                      <Switch id={row.tickerSymbol} value={row.tickerSymbol} color={'secondary'} checked={!!row.active} onChange={e => this.handleCompanyToggle(e, row.tickerSymbol)} />
                    </TableCell>
                  </TableRow>
                ))
                }
              </TableBody>
            </Table>
          </DialogContent>
        }
        <DialogActions>
          <Button autoFocus onClick={() => this.props.parentState({ openMultiple: false })} color="primary">
            Cancel
          </Button>
          <Button onClick={() => this.insertTransactions()} color="primary" autoFocus>
            Insert
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddMultipleTransactions.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(AddMultipleTransactions);
