import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import {
  ChevronLeft,
} from '@material-ui/icons';
import { gql, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import moment from "moment";

function UserTransactions({ history }) {
  const { id } = useParams();

  const [filter, setFilter] = useState({
    page: 0,
    limit: 10,
    sort: {
      date: 'desc',
    },
  });

  const [getTransactions, {
    data: transactionsData,
    loading,
    error }] = useLazyQuery(gql`
      query GetTransactions($id: String, $page: Int, $limit: Int, $sort: String) {
        transactions(id: $id, page: $page, limit: $limit, sort: $sort)
          @rest(type: "Transaction", path: "/users/{args.id}/transactions?page={args.page}&limit={args.limit}&sort={args.sort}") {
          transactions
          meta
        }
      }`, {
        variables: {
          id,
          page: filter.page + 1,
          limit: filter.limit,
          sort: JSON.stringify(filter.sort),
        }
      }
    );

  useEffect(() => {
    if (!loading && !error) {
      getTransactions();
    }
  }, [loading]);

  function renderTransactions() {
    if (error) return <div>Error...</div>
    if (loading || !transactionsData) return <div>Loading...</div>

    const { transactions, meta } = transactionsData.transactions;

    return (
      <>
        <TableContainer style={{ maxHeight: 600 }}>
          <Table stickyHeader id="table">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>
                  <Tooltip enterDelay={300} title="Sort">
                    <TableSortLabel
                      active={true}
                      direction={filter.sort.date}
                      onClick={(event) => {
                        event.preventDefault();
                        setFilter({
                          ...filter,
                          page: 0,
                          sort: {
                            date: filter.sort.date === 'desc' ? 'asc' : 'desc',
                          },
                        });
                      }}
                    >
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Ticker Symbol</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Halted Reason</TableCell>
                <TableCell>Roundup Enabled</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="table-body">
              {transactions.map(row => {
                return (
                  <TableRow key={`transaction-${row._id}`}>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{moment(row.date).format("MMM DD YYYY h:mm:ss A")}</TableCell>
                    <TableCell>{moment(row.last_updated).format("MMM DD YYYY h:mm:ss A")}</TableCell>
                    <TableCell>{row.tickerSymbol}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.companyName === 'cash' ? 'CASHMONEY' : row.companyName}</TableCell>
                    <TableCell>{row.haltedReason ? <b style={{ color: 'red' }}>{row.haltedReason}</b> : 'None'}</TableCell>
                    <TableCell>{row.roundupEnabled ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={(e, page) => {
            e.preventDefault();
            setFilter({
              ...filter,
              page,
            })
          }}
          onChangeRowsPerPage={(event, data) => {
            event.preventDefault();
            setFilter({
              ...filter,
              page: 0,
              limit: parseInt(event.target.value, 10),
            })
          }}
        />
      </>
    )
  }

  return (
    <div className="m-5">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <a href="#" onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}>
            <ChevronLeft fontSize="large" />
          </a>
          <h1 className="text-xl font-bold">Transactions</h1>
        </div>
      </div>
      {renderTransactions()}
    </div>
  )
}

export default UserTransactions;
