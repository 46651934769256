import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  CircularProgress,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import {
  Add as AddIcon,
} from '@material-ui/icons';
import moment from 'moment-timezone';

import getAllSentFiles from '~/services/feeTransparencyEmails/getAllSentFiles';
import addFileToS3 from '~/services/feeTransparencyEmails/addFileToS3';
import { creators as errorCreators } from '~/ducks/error';

class FeeTransparencyEmailTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      emailsSent: [],
    };

    this.searchInput = React.createRef();
  }

  async componentDidMount() {
    if (this.state.fetching) {
      await this.getAllSentFilesFromS3();
    }
  }

  async getAllSentFilesFromS3 () {
    const response = await getAllSentFiles();
    if (response.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch agreements',
        action: false,
      }));
      this.setState({
        fetching: false,
        emailsSent: [],
      });
    } else {
      this.setState({
        emailsSent: response.data,
        fetching: false,
      });
    }
  }

  async handleFileUpload (files) {
    this.setState({ fetching: true });
    for (let file of files) {
      try {
        await addFileToS3(file, file.name, 'files');
      } catch (err) {
        console.log(err)
      }
    }
    await addFileToS3(JSON.stringify({ files: files.length }), 'sendEmail.xlsx', 'send');
    setTimeout(async () => {
      await this.getAllSentFilesFromS3();
    }, 5000);
  }

  formatBytes(bytes) {
    const marker = 1024; // Change to 1000 if required
    const decimal = 0; // Change as required
    const kiloBytes = marker; // One Kilobyte is 1024 bytes
    const megaBytes = marker * marker; // One MB is 1024 KB
    const gigaBytes = marker * marker * marker; // One GB is 1024 MB
    const teraBytes = marker * marker * marker * marker; // One TB is 1024 GB

    // return bytes if less than a KB
    if (bytes < kiloBytes) return bytes + " Bytes";
    // return KB if less than a MB
    else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
    // return MB if less than a GB
    else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
    // return GB if less than a TB
    else return(bytes / gigaBytes).toFixed(decimal) + " GB";
  }

  render() {
    return (
      <div id="module-container" className={this.state.fetching ? 'fetching' : 'full'}>
        <Paper className="table-paper">
          {this.state.fetching &&
            <div className="circular-loading-container">
              <CircularProgress />
            </div>
          }
          {!this.state.fetching &&
            <div id="module-content" className="full-width">
              <Toolbar>
                <div className="title-container">
                  <Typography variant="h6" id="tableTitle">
                    Fee Transparency Emails
                  </Typography>
                </div>
                <div
                  id="toolbar-add"
                >
                  <label style={{ padding: 5, cursor: 'pointer' }} htmlFor={'addNeFiles'}><AddIcon /></label>
                  <input id={'addNeFiles'} type='file' multiple onChange={(event) => this.handleFileUpload(event.target.files)} style={{ display: 'none' }} />
                </div>
              </Toolbar>
              <div id="table-container">
                <Table id="table" stickyHeader size={'small'}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel>
                            Name
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel>
                            Uploaded
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel>
                            Size
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody id="table-body">
                    {this.state.emailsSent.map((row, idx) => (
                      <TableRow key={`email-sent-${row.ETag}-${idx}`}>
                        <TableCell style={{ width: '270px' }}>{row.Key.replace('sent/', '')}</TableCell>
                        <TableCell>{moment.tz(moment(row.LastModified), 'America/New_York').format("MMM DD YYYY h:mm:ss A")}</TableCell>
                        <TableCell>{this.formatBytes(row.Size)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          }
        </Paper>
      </div>
    );
  }
}

FeeTransparencyEmailTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(FeeTransparencyEmailTable);
