import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  LinearProgress,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  AccountCircle,
} from '@material-ui/icons';
import { thunks } from '~/ducks/user';
import { creators as appCreators } from '~/ducks/app';
import './style.css';

// Header Bar above all routes, displays authed profile info
class HeaderBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountMenu: null,
    };

    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.signOut = this.signOut.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  handleMenu(event) {
    this.setState({ accountMenu: event.currentTarget });
  }

  handleClose() {
    this.setState({ accountMenu: null });
  }

  signOut() {
    this.setState({
      accountMenu: false,
    });
    const { dispatch } = this.props;
    dispatch(thunks.logout());
  }

  toggleNavbar() {
    const { dispatch } = this.props;
    dispatch(appCreators.navbarToggle());
  }

  render() {
    const { accountMenu } = this.state;
    const { user, app } = this.props;

    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              id="navbar-toggle"
              color="inherit"
              onClick={this.toggleNavbar}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className="flex-grow">
              Grifin Admin Dashboard
            </Typography>
            <div>
              {user.isAuthed && (
                <div>
                  <IconButton
                    aria-owns={accountMenu ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={event => this.handleMenu(event)}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(accountMenu)}
                    anchorEl={accountMenu}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                    <MenuItem onClick={this.signOut}>Sign Out</MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </Toolbar>
          {app.loading && (
            <div className="loading-container">
              <LinearProgress className="loading" />
            </div>
          )}
        </AppBar>
      </div>
    );
  }
}

HeaderBar.propTypes = {
  user: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  app: state.app,
});

export default connect(mapStateToProps)(HeaderBar);
