import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  IconButton,
  Tooltip,
  LinearProgress,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { get } from 'lodash';
import {
  Refresh, InfoOutlined, ExpandMore,
} from '@material-ui/icons';
import { creators as errorCreators } from '~/ducks/error';
import getBrokerageAccount from '~/services/brokerage/getAccountSummary';
import { connect } from 'react-redux';

class HoldingsSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: null,
      accountUpdateLoading: false,
      expanded: true,
    };
  }

  async componentDidMount() {
    if (!this.state.account) {
      await this.getAccount();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
    return true;
  }

  async getAccount(success) {
    this.setState({
      accountUpdateLoading: true,
    });

    const account = await getBrokerageAccount(this.props.userKey);
    if (account.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch user\'s brokerage account',
        action: false,
      }));
      this.setState({
        accountUpdateLoading: false,
        account: {},
      });
    } else {
      this.setState({
        accountUpdateLoading: false,
        account: account.data,
      });
      if (success) {
        success();
      }
    }
  }

  async refreshAccount(event) {
    event.stopPropagation();
    this.setState({
      accountUpdateLoading: true,
      account: null,
    });
    await this.getAccount(() => {
      this.props.dispatch(errorCreators.new({
        message: 'Refreshed Holdings Data Successfully',
        action: false,
      }));
    });
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const costBasis = get(this.state.account, 'equity.equityPositions', []).reduce((a, b) => a + (b.costBasis || 0), 0);
    const equityValue = get(this.state.account, 'equity.equityValue', 0);
    const tooltipAvailableCash = <div><div>{'Cash Available For Trade: '}<b>{`$${get(this.state.account, 'cash.cashAvailableForTrade', '0')}`}</b></div><div>{'Pending Deposits: '}<b>{`$${get(this.state.account, 'pending.deposits', '0')}`}</b></div><div>{'Pending Orders: '}<b>{`$${get(this.state.account, 'pending.orders', '0')}`}</b></div><div>{'Pending ManualOrders: '}<b>{`$${get(this.state.account, 'pending.manualOrders', '0')}`}</b></div></div>;
    const tooltipAvailableWithdrawal = <div><div>{'Cash Available For Withdrawal: '}<b>{`$${get(this.state.account, 'cash.cashAvailableForWithdrawal', '0')}`}</b></div><div>{'Pending Withdrawals: '}<b>{`$${get(this.state.account, 'withdrawals.pendingRedemptions.amount', '0')}`}</b></div></div>;

    return (
      <Paper square>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Holdings Summary
            </Typography>
            <Tooltip
              title="Refresh Account Data"
              onClick={(event) => this.refreshAccount(event)}
            >
              <IconButton size={'small'}>
                <Refresh style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            {(this.state.accountUpdateLoading || !this.state.account) && (
              <div className="loading-container" style={{ width: '100%', position: 'absolute', left: 0, top: '48px' }}>
                <LinearProgress className="loading" />
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, flexDirection: 'column' }}>
            {this.state.account &&
              <div>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {'Account Number'}
                      </TableCell>
                      <TableCell>
                        {get(this.state.account, 'accountNo', 'None')}
                      </TableCell>
                      <TableCell>
                        {'Cash Balance'}
                      </TableCell>
                      <TableCell>
                        ${get(this.state.account, 'cash.cashBalance', 'None')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {'Available for Trade'}
                      </TableCell>
                      <TableCell>
                        ${get(this.state.account, 'cash.cashAvailableForTrade', 'None')}
                      </TableCell>
                      <TableCell>
                        {'Available for Withdrawal'}
                      </TableCell>
                      <TableCell>
                        ${get(this.state.account, 'cash.cashAvailableForWithdrawal', 'None')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {'Cost Basis'}
                      </TableCell>
                      <TableCell>
                        ${costBasis}
                      </TableCell>
                      <TableCell>
                        {'Equity Value'}
                      </TableCell>
                      <TableCell>
                        {`$${equityValue} (${(equityValue - costBasis) > 0 ? '+' : '-'}$${(equityValue - costBasis).toFixed(2)})`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {'Calculated Cash'}
                        <Tooltip
                          title={tooltipAvailableCash}
                        >
                          <IconButton size={'small'}>
                            <InfoOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        ${get(this.state.account, 'availableCash', 'None')}
                      </TableCell>
                      <TableCell>
                        {'Calculated Withdrawal'}
                        <Tooltip
                          title={tooltipAvailableWithdrawal}
                        >
                          <IconButton size={'small'}>
                            <InfoOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        ${get(this.state.account, 'withdrawals.availableWithdrawAmount', 'None')}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Typography variant={'h6'} style={{ paddingLeft: 16, paddingTop: 16 }}>Holdings</Typography>
                <TableContainer style={{ maxHeight: 480 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Ticker Symbol
                        </TableCell>
                        <TableCell>
                          Open Quantity
                        </TableCell>
                        <TableCell>
                          Cost Basis
                        </TableCell>
                        <TableCell>
                          Market Value
                        </TableCell>
                        <TableCell>
                          Average Buy Price
                        </TableCell>
                        <TableCell>
                          Market Price
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        get(this.state.account, 'equity.equityPositions', []).map((position, i) => (
                          <TableRow key={`EquityPositions_${i}`}>
                            <TableCell>
                              {position.symbol}
                            </TableCell>
                            <TableCell>
                              {position.openQty}
                            </TableCell>
                            <TableCell>
                              ${position.costBasis}
                            </TableCell>
                            <TableCell>
                              {`$${position.marketValue} (${position.marketValue - position.costBasis > 0 ? '+' : '-'}$${Math.abs(position.marketValue - position.costBasis).toFixed(2)})`}
                            </TableCell>
                            <TableCell>
                              ${position.avgPrice}
                            </TableCell>
                            <TableCell>
                              ${position.marketValue}
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
}

HoldingsSummary.propTypes = {
  userKey: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(HoldingsSummary);
