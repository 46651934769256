import React from 'react';
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import {
  Person,
  Email,
  VerifiedUser,
  Phone,
  Warning,
  Language,
  Cake,
  Accessibility,
  Add,
  Edit,
  Work,
  Business,
  Gavel,
  ExpandMore,
} from '@material-ui/icons';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import EditAddress from './address';
import EditName from './name';
import EditEmail from './email';
import EditPhone from './phone';
import EditSMSVerification from './smsVerification';

class UserSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      editAddress: false,
      editName: false,
      editEmail: false,
      editPhone: false,
      editSMSVerification: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
    return true;
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  updateUser(type, data, stateField, userField) {
    this.props.updateUser({ [type]: data }, userField);
    this.setState({ [stateField]: false });
  }

  render() {
    const {
      user,
      roles,
      addRole,
      deleteRole,
      addPoliticallyExposedName,
      deletePoliticallyExposedName,
    } = this.props;
    const emailVerification = get(user, 'emailVerification.status', get(user, 'verification.type', '') === 'email' ? get(user, 'verification.status', 'NOT_STARTED') : 'NOT_STARTED');
    const smsVerification = get(user, 'smsVerification.status', get(user, 'verification.type', '') === 'sms' ? get(user, 'verification.status', 'NOT_STARTED') : 'NOT_STARTED');
    const dob = get(user, 'personal.dob', false) ? moment(user.personal.dob).format('MM-DD-YY') : 'Unknown';

    return (
      <Paper square>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
            >
              User Summary
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, flexDirection: 'column' }}>
            <div id="content">
              <List>
                <ListItem>
                  <Tooltip
                    title={'Full Name'}
                  >
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={<><div><span style={{ fontSize: '15px' }}>{'First Name: '}</span> <b>{get(user, 'basic.firstName', 'Unknown')}</b></div><div><span style={{ fontSize: '15px' }}>{'Last Name: '}</span> <b>{get(user, 'basic.lastName', 'Unknown')}</b></div></>}
                  />
                  {(roles.includes('customer-service-manager') || roles.includes('admin'))
                    && <ListItemIcon>
                      <IconButton
                        onClick={() => {
                          this.setState({ editName: true })
                        }}
                      >
                        <Tooltip
                          title={'Edit full name'}
                        >
                          <Edit />
                        </Tooltip>
                      </IconButton>
                      <EditName
                        basic={user.basic}
                        open={this.state.editName}
                        updateName={(basic) => this.updateUser('basic', basic, 'editName', '')}
                        parentState={(event, state) => {
                          event.stopPropagation();
                          this.setState(state);
                        }}
                      />
                    </ListItemIcon>
                  }
                </ListItem>
                <ListItem>
                  <Tooltip
                    title={'Email'}
                  >
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={get(user, 'basic.email', 'Unknown')}
                  />
                  <ListItemIcon>
                    <IconButton>
                      <Tooltip
                        title={`Email Verification Status: ${emailVerification}`}
                      >
                        {
                          emailVerification === 'COMPLETE'
                            ? <VerifiedUser />
                            : <Warning />
                        }
                      </Tooltip>
                    </IconButton>
                  </ListItemIcon>
                  {(roles.includes('customer-service-manager') || roles.includes('admin'))
                    && <ListItemIcon>
                      <IconButton
                        onClick={() => {
                          this.setState({ editEmail: true })
                        }}
                      >
                        <Tooltip
                          title={'Edit email'}
                        >
                          <Edit/>
                        </Tooltip>
                      </IconButton>
                      <EditEmail
                        basic={user.basic}
                        open={this.state.editEmail}
                        updateEmail={(basic) => this.updateUser('basic', basic, 'editEmail', 'email')}
                        parentState={(event, state) => {
                          event.stopPropagation();
                          this.setState(state);
                        }}
                      />
                    </ListItemIcon>
                  }
                </ListItem>
                <ListItem>
                  <Tooltip
                    title={'Phone Number'}
                  >
                    <ListItemIcon>
                      <Phone />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={get(user, 'basic.phone', 'Unknown')}
                  />
                  <ListItemIcon>
                    <IconButton
                      onClick={() => {
                        if ((roles.includes('customer-service-manager') || roles.includes('admin')) && emailVerification === 'COMPLETE' && smsVerification === 'COMPLETE') {
                          this.setState({ editSMSVerification: true })
                        }
                      }}
                    >
                      <Tooltip
                        title={`SMS Verification Status: ${smsVerification}`}
                      >
                        {
                          smsVerification === 'COMPLETE'
                            ? <VerifiedUser />
                            : <Warning />
                        }
                      </Tooltip>
                    </IconButton>
                    <EditSMSVerification
                      smsVerification={user.smsVerification}
                      open={this.state.editSMSVerification}
                      updateSMSVerification={(basic) => this.updateUser('smsVerification', basic, 'editSMSVerification', '')}
                      parentState={(event, state) => {
                        event.stopPropagation();
                        this.setState(state);
                      }}
                    />
                  </ListItemIcon>
                  {(roles.includes('customer-service-manager') || roles.includes('admin'))
                    && <ListItemIcon>
                      <IconButton
                        onClick={() => {
                          this.setState({ editPhone: true })
                        }}
                      >
                        <Tooltip
                          title={'Edit phone'}
                        >
                          <Edit/>
                        </Tooltip>
                      </IconButton>
                      <EditPhone
                        basic={user.basic}
                        open={this.state.editPhone}
                        updatePhone={(basic) => this.updateUser('basic', basic, 'editPhone', 'phone')}
                        parentState={(event, state) => {
                          event.stopPropagation();
                          this.setState(state);
                        }}
                      />
                    </ListItemIcon>
                  }
                </ListItem>
                <ListItem>
                  <Tooltip
                    title={'Location'}
                  >
                    <ListItemIcon>
                      <Language />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={`${get(user, 'address.postalCode', 'Unknown')}, ${get(user, 'address.province', 'Unknown')}, ${get(user, 'basic.citizenship', 'Unknown')}`}
                  />
                  {(roles.includes('customer-service-manager') || roles.includes('admin'))
                    && <ListItemIcon>
                      <IconButton
                        onClick={() => {
                          this.setState({ editAddress: true })
                        }}
                      >
                        <Tooltip
                          title={'Edit address'}
                        >
                          <Edit/>
                        </Tooltip>
                      </IconButton>
                      <EditAddress
                        address={user.address}
                        open={this.state.editAddress}
                        updateAddress={(address) => this.updateUser('address', address, 'editAddress', '')}
                        parentState={(event, state) => {
                          event.stopPropagation();
                          this.setState(state);
                        }}
                      />
                    </ListItemIcon>
                  }
                </ListItem>
                <ListItem>
                  <Tooltip
                    title={'Birthdate'}
                  >
                    <ListItemIcon>
                      <Cake />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={dob}
                  />
                </ListItem>
                <ListItem>
                  <Tooltip
                    title={'Roles'}
                  >
                    <ListItemIcon>
                      <Accessibility />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText>
                    {
                      get(user, 'roles', []).map((role, i) => (
                        <Chip
                          key={`UserRoles_${i}`}
                          style={{ margin: 3 }}
                          label={role}
                          onDelete={role === 'user' ? undefined : () => deleteRole(role)}
                        />
                      ))
                    }
                  </ListItemText>
                  <ListItemIcon>
                    <IconButton
                      onClick={() => {
                        // eslint-disable-next-line no-alert
                        const role = prompt('Enter New Role');
                        if (role) {
                          addRole(role);
                        }
                      }}
                    >
                      <Add />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>
                <ListItem>
                  <Tooltip
                    title={'Employment Status'}
                  >
                    <ListItemIcon>
                      <Work />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={get(user, 'employment.status', 'Unknown')}
                    secondary={get(user, 'employment.broker', false) ? 'Broker' : 'Not a Broker'}
                  />
                </ListItem>
                <ListItem>
                  <Tooltip
                    title={'Employer Details'}
                  >
                    <ListItemIcon>
                      <Business />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={get(user, 'employment.company', 'Unknown')}
                    secondary={`${get(user, 'employment.type', 'Unknown Type')} - ${get(user, 'employment.position', 'Unknown Position')}`}
                  />
                </ListItem>
                <ListItem>
                  <Tooltip
                    title={'Politically Exposed Names'}
                  >
                    <ListItemIcon>
                      <Gavel />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText>
                    {
                      get(user, 'personal.politicallyExposedNames', []) === null
                      || get(user, 'personal.politicallyExposedNames', []).length === 0
                        ? 'Not Politically Exposed'
                        : (
                          get(user, 'personal.politicallyExposedNames', []).map((name, i) => (
                            <Chip
                              key={`PoliticallyExposedNames_${i}`}
                              style={{ margin: 3 }}
                              label={name}
                              onDelete={() => deletePoliticallyExposedName(name)}
                            />
                          ))
                        )
                    }
                  </ListItemText>
                  <ListItemIcon>
                    <IconButton
                      onClick={() => {
                        // eslint-disable-next-line no-alert
                        const name = prompt('Enter New Politically Exposed Name');
                        if (name) {
                          addPoliticallyExposedName(name);
                        }
                      }}
                    >
                      <Add />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>
              </List>
            </div>
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
}

UserSummary.propTypes = {
  user: PropTypes.object.isRequired,
  addRole: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  addPoliticallyExposedName: PropTypes.func.isRequired,
  deletePoliticallyExposedName: PropTypes.func.isRequired,
};

export default UserSummary;
