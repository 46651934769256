import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  Button,
  TextField,
  IconButton,
} from '@material-ui/core';
import {
  NavigateBefore,
} from '@material-ui/icons';
import { get } from 'lodash';
import create from '~/services/agreements/create';
import update from '~/services/agreements/update';
import { creators as errorCreators } from '~/ducks/error';

class AgreementsCreate extends React.Component {
  constructor(props) {
    super(props);

    const name = get(props, 'location.query.name', '');
    const version = get(props, 'location.query.version', '');
    const url = get(props, 'location.query.url', '');
    const active = get(props, 'location.query.active', '');

    this.state = {
      fetching: false,
      name,
      version,
      url,
      active,
    };
  }
  componentDidMount() {
    const id = get(this.props, 'match.params.id', '');
    const name = get(this.props, 'location.query.name', '');
    const version = get(this.props, 'location.query.version', '');
    const url = get(this.props, 'location.query.url', '');
    if (name === '' && version === '' && url === '' && id !== 'create') {
      location.replace('/dashboard/agreements');
    }
  }

  handleTextChange(stateField, event) {
    this.setState({ [stateField]: event.target.value });
  }

  async handleSubmit() {
    this.setState({
      fetching: true,
    });
    const { name, version, url, active } = this.state;

    if (!name || name === '' || !version || !url || url === '') {
      this.setState({
        fetching: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Please check the fields',
        action: false,
      }));
      return;
    }

    if (this.props.match.params.id === 'create') {
      const body = {
        name,
        version,
        url: url.trim(),
        active,
      };
      const response = await create(body);
      if (response.success) {
        this.setState({
          fetching: false,
        });
        this.props.dispatch(errorCreators.new({
          message: 'Agreement create successfully',
          action: false,
        }));
        location.replace('/dashboard/agreements');
      } else {
        this.props.dispatch(errorCreators.new({
          message: `Failed to create agreement: ${get(response, 'error.message', '500')}`,
          action: false,
        }));
        this.setState({
          fetching: false,
        });
      }
    } else {
      const updatedAgreement = await update(this.props.match.params.id, name, version, url.trim(), active);
      if (updatedAgreement.data) {
        this.setState({
          fetching: false,
        });
        this.props.dispatch(errorCreators.new({
          message: 'Agreement updated successfully',
          action: false,
        }));
        location.replace('/dashboard/agreements');
      } else {
        this.props.dispatch(errorCreators.new({
          message: updatedAgreement.error.message || `Failed to update Agreement version: ${version}`,
          action: false,
        }));
        this.setState({
          fetching: false,
        });
      }
    }
  }

  render() {
    return (
      <div style={{ padding: '10px' }}>
        <Paper square>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                className="menu-button"
                color="inherit"
                aria-label="back"
                onClick={() => this.props.history.goBack()}
              >
                <NavigateBefore />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className="flex-grow"
              >
                {`${this.props.match.params.id === 'create' ? 'Create' : 'Edit'} Agreement`}
              </Typography>
            </Toolbar>
          </AppBar>
          <div
            style={{
              padding: '10px',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <TextField
              id="name"
              label="Name"
              value={this.state.name}
              onChange={e => this.handleTextChange('name', e)}
              margin="normal"
            />
            <TextField
              id="version"
              label="Version"
              value={this.state.version}
              onChange={e => this.handleTextChange('version', e)}
              margin="normal"
              type="number"
            />
            <TextField
              id="url"
              label="URL"
              value={this.state.url}
              onChange={e => this.handleTextChange('url', e)}
              margin="normal"
            />
            <Button
              disabled={this.state.fetching}
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => this.handleSubmit()}
            >
              {`${this.props.match.params.id === 'create' ? 'Create' : 'Update'}`}
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}

export default connect()(AgreementsCreate);
