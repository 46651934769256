import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { get } from 'lodash';

function validatePostal(text) {
  const regex = new RegExp(/(?!([089])\1{4})\d{5}/);

  return regex.test(text);
}

const states = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

class EditAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.address,
      street1Error: false,
      cityError: false,
      provinceError: false,
      postalCodeError: false
    };
  }

  handleAddressChange (event, field) {
    const address = JSON.parse(JSON.stringify(this.state.address));
    let cleaned = event.target.value.replace(/[,#\-/!@$%^*(){}|[\]\\]+/, '');
    if (field === 'postalCode') {
      if(cleaned.length > 5) {
        return;
      }
      cleaned = event.target.value.replace(/^[A-Za-z _\W,]+/, '');
    }
    address[field] = cleaned;
    this.setState({ address, [`${field}Error`]: false });
  }

  validateAddress() {
    const { street1, city, province, postalCode } = this.state.address;
    const errors = {};
    if (street1.length < 5) {
      errors.street1Error = true;
    }
    if (city.length < 2) {
      errors.cityError = true;
    }
    if (!province) {
      errors.provinceError = true;
    }
    if(!validatePostal(postalCode)) {
      errors.postalCodeError = true;
    }
    if (Object.keys(errors).length > 0) {
      this.setState(errors);
      return false;
    }
    return true;
  }

  onSubmit() {
    if (this.validateAddress()) {
      this.props.updateAddress(this.state.address);
    }
  }

  render() {
    if (!this.props.open) return null;
    return (
      <Dialog
        onClick={(event) => event.stopPropagation()}
        fullWidth
        maxWidth={'sm'}
        open={this.props.open}
        onClose={(event) => this.props.parentState(event, { editAddress: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" className="flex-grow">
                Edit Address
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <TextField
                    autoFocus
                    error={this.state.street1Error}
                    style={{ width: '100%', margin: 0 }}
                    id="street1"
                    label="Street 1"
                    className={'input-field ml-0'}
                    value={get(this.state.address, 'street1', '')}
                    onChange={e => this.handleAddressChange(e, 'street1')}
                    margin="normal"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <TextField
                    style={{ width: '100%', margin: 0 }}
                    id="street2"
                    label="Apt/Suite"
                    className={'input-field ml-0'}
                    value={get(this.state.address, 'street2', '')}
                    onChange={e => this.handleAddressChange(e, 'street2')}
                    margin="normal"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <TextField
                    error={this.state.cityError}
                    style={{ width: '100%', margin: 0 }}
                    id="city"
                    label="City"
                    className={'input-field ml-0'}
                    value={get(this.state.address, 'city', '')}
                    onChange={e => this.handleAddressChange(e, 'city')}
                    margin="normal"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={'50%'} align="center">
                  <InputLabel style={{
                    transform: 'translate(0, 1.5px) scale(0.75)',
                    transformOrigin: 'top left'
                  }} id="province-select-label">State</InputLabel>
                  <Select
                    error={this.state.provinceError}
                    style={{ width: '100%', margin: 0 }}
                    labelId="province-select-label"
                    label="State"
                    value={get(this.state.address, 'province', '')}
                    onChange={e => this.handleAddressChange(e, 'province')}
                  >
                    {states.map(state => <MenuItem value={state}>{state}</MenuItem>)}
                  </Select>
                </TableCell>
                <TableCell width={'50%'} align="center">
                  <TextField
                    error={this.state.postalCodeError}
                    style={{ width: '100%', margin: 0 }}
                    id="postalCode"
                    label="Postal Code"
                    className={'input-field ml-0'}
                    value={get(this.state.address, 'postalCode', '')}
                    onChange={e => this.handleAddressChange(e, 'postalCode')}
                    margin="normal"
                    type="number"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => this.props.parentState(event, { editAddress: false })}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.onSubmit()}
            variant="contained"
            color="primary"
            className="space"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(EditAddress);
