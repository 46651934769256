import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';

// HOC that redirects app routes to login if user is not authed
const ProtectedRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={
      props => (user.isAuthed ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: rest.location },
          }}
        />
      ))
    }
  />
);

const mapStateToProps = state => ({
  user: state.user,
});

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ProtectedRoute);
