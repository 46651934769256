// => ACTIONS
const actions = {
  NAVBAR_TOGGLE: 'app/NAVBAR_TOGGLE',
  LOADING: 'app/LOADING',
  DONE: 'app/DONE_LOADING',
};

// => STATE
const INITIAL_STATE = {
  navbarOpen: true,
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  const nextState = state;

  switch (action.type) {
    case actions.NAVBAR_TOGGLE:
      return {
        ...state,
        ...{
          navbarOpen: !state.navbarOpen,
        },
      };
    case actions.LOADING:
      return {
        ...state,
        ...{
          loading: true,
        },
      };
    case actions.DONE:
      return {
        ...state,
        ...{
          loading: false,
        },
      };
    default:
      break;
  }

  return nextState;
};

// => CREATORS
const creators = {
  navbarToggle: payload => ({ type: actions.NAVBAR_TOGGLE, payload }),
  loading: payload => ({ type: actions.LOADING, payload }),
  doneLoading: payload => ({ type: actions.DONE, payload }),
};

export default reducer;
export { actions, creators };
