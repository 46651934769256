import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import insert from '~/services/transactions/insert';
import { connect } from 'react-redux';

class AddSingleTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  handleTextChange(name, event) {
    this.setState({ [name]: event.target.value });
  }

  async insertTransaction() {
    await insert(this.props.userKey, [{ name: this.state.name }]);
    this.props.refreshTransactions();
  }

  render() {
    return (
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={this.props.open}
        onClose={() => this.props.parentState({ openOne: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" className="flex-grow">
                Insert Single Transaction
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            style={{ width: '95%' }}
            id="name"
            label="Name"
            className={'input-field'}
            value={this.state.name}
            onChange={e => this.handleTextChange('name', e)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.props.parentState({ openOne: false })}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.insertTransaction()}
            variant="contained"
            color="primary"
            className="space"
          >
            Insert
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddSingleTransaction.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(AddSingleTransaction);
