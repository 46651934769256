import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  TextField,
  LinearProgress,
  Tooltip,
} from '@material-ui/core';
import {
  NavigateBefore,
  Close,
} from '@material-ui/icons';
import { MaterialPicker } from 'react-color';
import _ from 'lodash';

import update from '~/services/company/update';
import create from '~/services/company/create';
import updateLogo from '~/services/company/updateLogo';
import { creators as errorCreators } from '~/ducks/error';

import './style.css';

class UpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyData: props.companyData,
      ticker: props.companyData.tickerSymbol,
      name: props.companyData.name,
      currentLogo: props.companyData.logo,
      background: props.companyData.background || '#fff',
      loading: false,
      newLogo: '',
      file: '',
      imagePreviewUrl: '',
    };
  }

  async onImageChange(e) {
    e.preventDefault();

    const file = e.target.files[0];
    const img = new Image();

    img.onload = (self) => {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        this.props.dispatch(errorCreators.new({
          message: `Logo image must be JPEG or PNG not ${file.type.split('/').pop().toUpperCase()}`,
          action: false,
        }));
      } else if (self.srcElement.width !== self.srcElement.height) {
        this.props.dispatch(errorCreators.new({
          message: `Logo image must be square. Resolution: ${self.srcElement.width}x${self.srcElement.height}`,
          action: false,
        }));
      } else if (self.srcElement.width > 750 || self.srcElement.width < 250) {
        this.props.dispatch(errorCreators.new({
          message: `Logo resolution must be between 250 and 750. Resolution: ${self.srcElement.width}x${self.srcElement.height}`,
          action: false,
        }));
      } else {
        const r = new FileReader();
        r.onloadend = () => {
          this.setState({
            file,
            imagePreviewUrl: r.result,
          });
        };

        r.readAsDataURL(file);
      }
    };
    img.src = URL.createObjectURL(e.target.files[0]);
  }

  handleImage(e) {
    const content = e.target.result;
    this.setState({
      newLogo: content,
    });
  }

  removeNewLogo() {
    this.setState({
      file: '',
      imagePreviewUrl: '',
    });
  }

  handleColorChange(color) {
    this.setState({
      background: color.hex,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    if (this.props.new) {
      this.setState({
        loading: true,
      });
      const createdCompany = await create({
        name: this.state.name,
        tickerSymbol: this.state.ticker,
      });
      if (createdCompany.data) {
        this.setState({
          loading: false,
          name: createdCompany.data.name,
          ticker: createdCompany.data.tickerSymbol,
          companyData: createdCompany.data,
        });
        this.props.dispatch(errorCreators.new({
          message: 'Company created successfully',
          action: false,
        }));
        location.replace(`/dashboard/companies/${createdCompany.data.tickerSymbol}`);
      } else {
        this.setState({
          loading: false,
        });
        this.props.dispatch(errorCreators.new({
          message: 'Failed to create company',
          action: false,
        }));
      }
    } else {
      this.setState({
        loading: true,
      });
      const updatedCompanyData = {
        name: this.state.name,
        tickerSymbol: this.state.ticker,
        background: this.state.background,
      };

      if (this.state.file) {
        const formData = new FormData();
        formData.append('logoFile', this.state.file);
        const logoUploadRes = await updateLogo(
          this.state.companyData.tickerSymbol,
          formData,
        );

        if (logoUploadRes.data) {
          updatedCompanyData.logo = logoUploadRes.data;
          this.setState({
            file: '',
          });
        } else {
          this.props.dispatch(errorCreators.new({
            message: 'Failed to upload logo',
            action: false,
          }));
          this.setState({
            loading: false,
          });
        }
      }

      if (!(this.state.file.name && !updatedCompanyData.logo)) {
        const updatedCompany = await update(
          this.state.companyData.tickerSymbol,
          updatedCompanyData,
        );
        if (updatedCompany.data) {
          if (this.state.companyData.tickerSymbol !== updatedCompany.data.tickerSymbol) {
            location.replace(`/dashboard/companies/${updatedCompany.data.tickerSymbol}`);
          }
          this.setState({
            loading: false,
            name: updatedCompany.data.name,
            ticker: updatedCompany.data.tickerSymbol,
            companyData: updatedCompany.data,
          });
          this.props.dispatch(errorCreators.new({
            message: 'Company updated successfully',
            action: false,
          }));
        } else {
          this.setState({
            loading: false,
          });
          this.props.dispatch(errorCreators.new({
            message: 'Failed to update company',
            action: false,
          }));
        }
      }
    }
  }

  handleTextChange(name, event) {
    this.setState({ [name]: event.target.value });
  }

  render() {
    const { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (
        <img className="image-preview" alt="logo preview" src={imagePreviewUrl} />
      );
    } else {
      $imagePreview = (<img className="image-preview" alt="Add Logo" src={this.state.companyData.logo} />);
    }
    return (
      <Paper square id="update-form-container">
        <AppBar position="static">
          <Toolbar>
            <IconButton
              className="menu-button"
              color="inherit"
              aria-label="back"
              onClick={() => this.props.history.goBack()}
            >
              <NavigateBefore />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              {this.state.name}
            </Typography>
          </Toolbar>
          {this.state.loading && (
            <div className="loading-container">
              <LinearProgress className="loading" />
            </div>
          )}
        </AppBar>
        <form
          className="company-edit-form"
          autoComplete="off"
          onSubmit={e => this.handleSubmit(e)}
        >
          <div id="form-fields-container">
            {!this.props.new && [
              <Tooltip title="Change company logo">
                <Paper
                  id="logo-preview-container"
                  style={{
                    backgroundColor: this.state.background,
                  }}
                >
                  <form encType="multipart/form-data">
                    <label htmlFor="logo-upload">
                      <input id="logo-upload" type="file" onChange={e => this.onImageChange(e)} />
                      {$imagePreview}
                    </label>
                  </form>
                  {this.state.file.name &&
                    <IconButton
                      id="remove-image-button"
                      onClick={() => this.removeNewLogo()}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  }
                </Paper>
              </Tooltip>,
              <Paper id="color-picker-container">
                <MaterialPicker
                  color={this.state.background}
                  onChangeComplete={color => this.handleColorChange(color)}
                />
              </Paper>,
            ]}
            <div id="text-inputs-container">
              <TextField
                id="ticker"
                label="Ticker"
                className={`input-field ${this.state.ticker !== this.state.companyData.tickerSymbol ? 'changed' : ''}`}
                value={this.state.ticker}
                onChange={e => this.handleTextChange('ticker', e)}
                margin="normal"
              />
              <TextField
                id="name"
                label="Name"
                className={`input-field ${this.state.name !== this.state.companyData.name ? 'changed' : ''}`}
                value={this.state.name}
                onChange={e => this.handleTextChange('name', e)}
                margin="normal"
              />
            </div>
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="space"
            disabled={
              (_.isEqual(
                {
                  name: this.state.name,
                  ticker: this.state.ticker,
                  logo: this.state.imagePreviewUrl.length,
                  background: this.state.background,
                },
                {
                  name: this.state.companyData.name,
                  ticker: this.state.companyData.tickerSymbol,
                  logo: 0,
                  background: this.state.companyData.background,
                },
              ) || (
                this.props.new && !(this.state.name.length > 3 && this.state.ticker.length > 0)
              ))
              || this.state.loading
            }
          >
            Submit
          </Button>
        </form>
      </Paper>
    );
  }
}

UpdateForm.propTypes = {
  companyData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  new: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

UpdateForm.defaultProps = {
  new: false,
};

export default connect()(UpdateForm);
