import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
} from '@material-ui/core';

import CreditCard from './CreditCard';
import FundingSource from './FundingSource';

class ReauthTable extends React.Component {
  render() {
    return (
      <div id="module-container">
        <Paper square>
            <CreditCard />
            <FundingSource/>
        </Paper>
      </div>
    );
  }
}

ReauthTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(ReauthTable);
