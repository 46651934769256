import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  CircularProgress,
  Button,
  Toolbar,
  InputBase,
  IconButton,
  MenuItem,
  Tooltip,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  TableContainer,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import getAll from '~/services/notifications/getAll';
import { creators as errorCreators } from '~/ducks/error';
import moment from 'moment';

class NotificationsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      notifications: [],
      searchActive: false,
      filterMenu: null,
      textSearch: '',
      sortBy: 'name',
      order: 'desc',
      page: 0,
      entries: 15,
    };

    this.searchInput = React.createRef();
  }

  async componentDidMount() {
    if (this.state.fetching) {
      const response = await getAll();
      if (response.error) {
        this.props.dispatch(errorCreators.new({
          message: 'Failed to fetch notifications',
          action: false,
        }));
        this.setState({
          fetching: false,
          notifications: [],
        });
      } else {
        this.setState({
          notifications: response.data.notifications,
          fetching: false,
        });
      }
    }
  }

  handleEntriesChange(event) {
    this.setState({
      entries: event.target.value,
      page: 0,
    });
  }

  sortBy(by) {
    if (by === this.state.sortBy) {
      this.setState({
        order: this.state.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortBy: by,
        order: 'desc',
      });
    }
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleSearchTextChange(event) {
    this.setState({
      textSearch: event.target.value,
    });
  }

  toggleSearch() {
    if (this.state.searchActive) {
      this.setState({
        searchActive: false,
        textSearch: '',
      });
    } else {
      this.setState({
        searchActive: true,
      }, () => this.searchInput.current.focus());
    }
  }


  sortNotifications(notifications) {
    if (!this.state.sortBy) {
      return notifications;
    }
    const sortedNotifications = notifications.sort((a, b) => {
      if (typeof get(a, this.state.sortBy, '') === 'string') {
        const x = get(a, this.state.sortBy, '').toLowerCase();
        const y = get(b, this.state.sortBy, '').toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
      }
      return a[this.state.sortBy] - b[this.state.sortBy];
    });
    if (this.state.order === 'desc') {
      return sortedNotifications;
    }
    return sortedNotifications.reverse();
  }

  filterNotifications() {
    if (!this.state.searchActive) {
      return this.state.notifications;
    }

    return this.state.notifications.filter((notification) => {
      const search = this.state.textSearch.toLowerCase();
      const title = get(notification, 'title', '');
      const releasedOn = get(notification, 'releasedOn', '');
      const list = get(notification, 'list', []);

      return String(title).toLowerCase().includes(search) || releasedOn.toLowerCase().includes(search) || list.map(l => l.text).join().toLowerCase().includes(search);
    });
  }

  render() {
    return (
      <div id="module-container" className={this.state.fetching ? 'fetching' : 'full'}>
        <Paper className="table-paper">
          {this.state.fetching &&
            <div className="circular-loading-container">
              <CircularProgress />
            </div>
          }
          {!this.state.fetching &&
            <div id="module-content" className="full-width">
              <Toolbar>
                <div className="title-container">
                  <Typography variant="h6" id="tableTitle">
                    Notifications
                  </Typography>
                </div>
                <div id="page-entries">
                  <FormControl style={{ minWidth: '70px' }}>
                    <InputLabel htmlFor="per-page">Per Page</InputLabel>
                    <Select
                      value={this.state.entries}
                      onChange={(e) => this.handleEntriesChange(e)}
                      inputProps={{ id: 'per-page' }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  id="toolbar-search"
                  className={this.state.searchActive ? 'open' : 'closed'}
                >
                  <Tooltip enterDelay={300} title="Search">
                    <IconButton
                      onClick={() => this.toggleSearch()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  {
                    this.state.searchActive &&
                    <InputBase
                      id="text-search"
                      placeholder="Search"
                      onChange={(e) => this.handleSearchTextChange(e)}
                      inputRef={this.searchInput}
                      value={this.state.searchText}
                    />
                  }
                </div>
                <div
                  id="toolbar-add"
                >
                  <Tooltip enterDelay={300} title="Create Notification">
                    <Link to={'/dashboard/notifications/create'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </div>
              </Toolbar>
              <div id="table-container">
                <Table id="table" stickyHeader size={'small'}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'title'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('title')}
                          >
                            Title
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'releasedOn'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('releasedOn')}
                          >
                            Released On
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={false}
                          >
                            List
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody id="table-body">
                    {this.sortNotifications(this.filterNotifications())
                      .slice(
                        this.state.page * this.state.entries,
                        (this.state.page * this.state.entries) + this.state.entries,
                      )
                      .map(row => (
                        <TableRow key={`Notification-${row._id}`}>
                          <TableCell style={{ width: '25%', borderBottom: '3px solid rgba(224, 224, 224, 1)' }}>{row.title}</TableCell>
                          <TableCell style={{ width: '15%', borderBottom: '3px solid rgba(224, 224, 224, 1)' }}>{moment(row.releasedOn).format('MM-DD-YY')}</TableCell>
                          <TableCell style={{ width: '55%', borderBottom: '3px solid rgba(224, 224, 224, 1)' }}>
                            {row.list.length > 0 &&
                              <TableContainer style={{ maxHeight: '136px' }} size={'small'}>
                                <Table stickyHeader size={'small'}>
                                  <TableBody>
                                    {row.list.map((list, j) =>
                                      <TableRow key={`notification_list_${j}`}>
                                        <TableCell style={{ border: 0, borderBottom: j === (row.list.length - 1) ? 0 : '1px solid rgba(224, 224, 224, 1)' }}>{list.text}</TableCell>
                                        {/*<TableCell style={{ border: 0, borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>{list.icon || ''}</TableCell>*/}
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            }
                          </TableCell>
                          <TableCell align="right" style={{ borderBottom: '3px solid rgba(224, 224, 224, 1)' }}>
                            <Link to={{
                              pathname: `notifications/${encodeURIComponent(row._id)}`,
                              query: row,
                            }}
                            >
                              <Button
                                variant="outlined"
                              >
                                Edit
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                component="div"
                id="table-pagination"
                count={this.state.notifications.length}
                rowsPerPage={this.state.entries}
                rowsPerPageOptions={[]}
                page={this.state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={(e, page) => this.handleChangePage(e, page)}
              />
            </div>
          }
        </Paper>
      </div>
    );
  }
}

NotificationsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(NotificationsTable);
