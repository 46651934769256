import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
import {
  Home,
  People,
  Business,
  ExitToApp,
  Feedback,
  VpnKey,
  FormatListNumbered,
  LockOutlined,
  Assignment,
  Email,
  Notifications,
} from '@material-ui/icons';
import { thunks } from '~/ducks/user';
import './style.css';

// HOC that highlights ListItemText for current path
const HighlightedListItemText = (props) => {
  const { location, path } = props;
  return (
    <ListItemText
      {...props}
      primaryTypographyProps={{
        style:
          {
            color: location.pathname === path ?
              'rgba(0, 0, 249, 0.87)' :
              'rgba(0, 0, 0, 0.87)',
          },
      }}
    />
  );
};


// NavBar toggleable for all /dashboard routes, allows navigation to main dashboard screens
class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authTimeout: null,
      showWarning: false,
    };

    this.signOut = this.signOut.bind(this);
  }

  componentDidMount() {
    this.setState({
      authTimeout: setTimeout(() => {
        this.setState({
          showWarning: setTimeout(this.signOut, 60000),
        });
      }, 840000),
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname !== this.props.location.pathname
      || prevProps.location.search !== this.props.location.search
      || prevProps.app.loading !== this.props.app.loading
      || prevProps.app.navbarOpen !== this.props.app.navbarOpen
    ) {
      clearTimeout(this.state.authTimeout);
      clearTimeout(this.state.showWarning);
      this.setState({
        showWarning: false,
        authTimeout: setTimeout(() => {
          this.setState({
            showWarning: setTimeout(this.signOut, 60000),
          });
        }, 840000),
      });
    }
  }

  signOut() {
    const { dispatch } = this.props;
    dispatch(thunks.logout());
  }

  render() {
    const { app, history, location } = this.props;
    return (
      <Paper
        id="navbar"
        className={app.navbarOpen ? 'slideIn' : 'slideOut'}
      >
        {
          this.state.showWarning
          && <div
            id={'auth-timeout-warning'}
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.9)',
              zIndex: 999999,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              paddingTop: 150,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                padding: 15,
              }}
            >
              <Typography variant="h6" component="h1">Authentication Timeout</Typography>
              <Typography variant="body1">For security purposes you will be logged out in one minute due to inactivity.</Typography>
              <Typography variant="body1" paragraph>Press continue if you would like to stay logged in.</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  clearTimeout(this.state.showWarning);
                  this.setState({
                    showWarning: false,
                    authTimeout: setTimeout(() => {
                      this.setState({
                        showWarning: setTimeout(this.signOut, 60000),
                      });
                    }, 840000),
                  });
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        }
        <div
          id="navbar-contents"
          className={app.navbarOpen ? 'slideIn' : 'slideOut'}
        >
          <List
            id="navbar-list"
            component="nav"
          >
            <ListItem button onClick={() => history.push('/dashboard/home')}>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <HighlightedListItemText primary="Home" path="/dashboard/home" location={location} />
            </ListItem>
            <ListItem button onClick={() => history.push('/dashboard/users')}>
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <HighlightedListItemText primary="Users" path="/dashboard/users" location={location} />
            </ListItem>
            <ListItem button onClick={() => history.push('/dashboard/companies')}>
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <HighlightedListItemText primary="Company Management" path="/dashboard/companies" location={location} />
            </ListItem>
            <ListItem button onClick={() => history.push('/dashboard/suggestions')}>
              <ListItemIcon>
                <Feedback />
              </ListItemIcon>
              <HighlightedListItemText primary="Match Suggestions" path="/dashboard/suggestions" location={location} />
            </ListItem>
            <ListItem button onClick={() => history.push('/dashboard/accesscodes')}>
              <ListItemIcon>
                <VpnKey />
              </ListItemIcon>
              <HighlightedListItemText primary="Access Codes" path="/dashboard/accesscodes" location={location} />
            </ListItem>
            <ListItem button onClick={() => history.push('/dashboard/dtc')}>
              <ListItemIcon>
                <FormatListNumbered />
              </ListItemIcon>
              <HighlightedListItemText primary="DTC Numbers" path="/dashboard/dtc" location={location} />
            </ListItem>
            <ListItem button onClick={() => history.push('/dashboard/reauth')}>
              <ListItemIcon>
                <LockOutlined />
              </ListItemIcon>
              <HighlightedListItemText primary="Re-auth Users" path="/dashboard/reauth" location={location} />
            </ListItem>
            <ListItem button onClick={() => history.push('/dashboard/agreements')}>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <HighlightedListItemText primary="Agreements" path="/dashboard/agreements" location={location} />
            </ListItem>
            <ListItem button onClick={() => history.push('/dashboard/fee/transparency/email')}>
              <ListItemIcon>
                <Email />
              </ListItemIcon>
              <HighlightedListItemText primary="Fee Transparency Email" path="/dashboard/fee/transparency/email" location={location} />
            </ListItem>
            <ListItem button onClick={() => history.push('/dashboard/notifications')}>
              <ListItemIcon>
                <Notifications />
              </ListItemIcon>
              <HighlightedListItemText primary="Notifications" path="/dashboard/notifications" location={location} />
            </ListItem>
            <Divider />
            <ListItem button onClick={this.signOut}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItem>
          </List>
        </div>
      </Paper>
    );
  }
}

NavBar.propTypes = {
  app: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

HighlightedListItemText.propTypes = {
  location: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  app: state.app,
});

export default connect(mapStateToProps)(NavBar);