import user from './user';
import app from './app';
import error from './error';

const reducers = {
  user,
  app,
  error,
};

export default reducers;
