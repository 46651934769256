import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  IconButton,
  Tooltip,
  LinearProgress,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';
import {
  Refresh, ExpandMore,
} from '@material-ui/icons';
import { creators as errorCreators } from '~/ducks/error';
import getAccount from '~/services/brokerage/getAccount';
import syncBrokerageAccount from '~/services/brokerage/syncStatus';
import { connect } from 'react-redux';

class BrokerageAccountSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: null,
      accountUpdateLoading: false,
      expanded: true,
    };
  }

  async componentDidMount() {
    if (!this.state.account) {
      await this.getAccount();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
    return true;
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  async getAccount(success) {
    this.setState({
      accountUpdateLoading: true,
    });

    const account = await getAccount(this.props.userKey);
    if (account.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch user\'s brokerage account',
        action: false,
      }));
      this.setState({
        accountUpdateLoading: false,
        account: {},
      });
    } else {
      this.setState({
        accountUpdateLoading: false,
        account: account.data.account,
      });
      if (success) {
        success();
      }
    }
  }

  async refreshAccount(event) {
    event.stopPropagation();
    this.setState({
      accountUpdateLoading: true,
      account: null,
    });
    await this.getAccount(() => {
      this.props.dispatch(errorCreators.new({
        message: 'Refreshed Account Data Successfully',
        action: false,
      }));
    });
  }

  async refreshBrokerageStatus() {
    this.setState({
      accountUpdateLoading: true,
    });
    const refreshRes = await syncBrokerageAccount(this.props.userKey);
    if (refreshRes.error) {
      this.props.dispatch(errorCreators.new({
        message: refreshRes.error.message || 'Failed to sync user\'s brokerage account',
        action: false,
      }));
      this.setState({
        accountUpdateLoading: false,
        // account: {},
      });
    } else {
      this.props.dispatch(errorCreators.new({
        message: refreshRes.error.message || 'Failed to sync user\'s brokerage account',
        action: false,
      }));
      this.refreshAccount();
    }
  }

  render() {
    return (
      <Paper square style={{ marginBottom: 8 }}>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Brokerage Account
            </Typography>
            <Tooltip
              title="Refresh Account Data"
              onClick={(event) => this.refreshAccount(event)}
            >
              <IconButton size={'small'}>
                <Refresh style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            {(this.state.accountUpdateLoadin || !this.state.account) && (
              <div className="loading-container" style={{ width: '100%', position: 'absolute', left: 0, top: '48px' }}>
                <LinearProgress className="loading" />
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            {this.state.account &&
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Opened
                    </TableCell>
                    <TableCell>
                      {moment(this.state.account.openedWhen).format('MM-DD-YY hh:mmA')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Status
                    </TableCell>
                    <TableCell>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                        {get(this.state.account, 'status.name', 'Unknown')}
                        <Tooltip
                          title="Sync Brokerage Account with Grifin"
                        >
                          <IconButton size={'small'}
                            onClick={() => this.refreshBrokerageStatus()}
                          >
                            <Refresh />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Restricted
                    </TableCell>
                    <TableCell>
                      {get(this.state.account, 'restricted', false).toString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Pattern Day Trades
                    </TableCell>
                    <TableCell>
                      {get(this.state.account, 'violations.patternDayTrades', 0)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            }
          </AccordionDetails>
        </Accordion>        
      </Paper>
    );
  }
}

BrokerageAccountSummary.propTypes = {
  userKey: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(BrokerageAccountSummary);
