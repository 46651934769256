import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  Button,
  TextField,
  FormControl,
  InputAdornment,
  LinearProgress,
  CirclularProgress,
  IconButton,
} from '@material-ui/core';
import moment from 'moment';
import {
  NavigateBefore,
  Close as CloseIcon,
} from '@material-ui/icons';
import { get } from 'lodash';
import create from '~/services/accessCode/create';
import { creators as errorCreators } from '~/ducks/error';

class CreateAccessCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      code: '',
      uses: 1,
      description: '',
      expire: false,
    };

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleExpirationDateChange = this.handleExpirationDateChange.bind(this);
    this.handleClearDate = this.handleClearDate.bind(this);
  }

  handleTextChange(stateField, event) {
    if (stateField === 'uses' && event.target.value < 1) {
      return;
    }
    this.setState({ [stateField]: event.target.value });
  }

  handleExpirationDateChange(event) {
    if (event.target.value === '') {
      this.setState({
        expire: false,
      });
    } else {
      this.setState({
        expire: moment(event.target.value),
      });
    }
  }

  handleClearDate() {
    this.setState({
      expire: false,
    });
  }

  async handleSubmit() {
    this.setState({
      fetching: true,
    });
    const { code, description, expire, uses } = this.state;
    const body = {
      uses: parseInt(uses, 10),
    };

    if (code && code.length !== 0) {
      body.code = code;
    }

    if (description && description.length !== 0) {
      body.description = description;
    }

    if (expire) {
      body.expireDate = expire;
    }

    const response = await create(body);

    if (response.success) {
      this.setState({
        fetching: false,
      });
      location.replace('/dashboard/accesscodes');
    } else {
      this.props.dispatch(errorCreators.new({
        message: `Failed to create access code: ${get(response, 'error.message', '500')}`,
        action: false,
      }));
      this.setState({
        fetching: false,
      });
    }
  }

  render() {
    return (
      <div style={{ padding: '10px' }}>
        <Paper square>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                className="menu-button"
                color="inherit"
                aria-label="back"
                onClick={() => this.props.history.goBack()}
              >
                <NavigateBefore />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className="flex-grow"
              >
                Create Access Code
              </Typography>
            </Toolbar>
          </AppBar>
          <form
            autoComplete="off"
            onSubmit={this.handleSubmit}
            style={{
              padding: '10px',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <TextField
              id="code"
              label="Access Code"
              value={this.state.code}
              onChange={e => this.handleTextChange('code', e)}
              margin="normal"
            />
            <TextField
              id="uses"
              type="number"
              label="Uses"
              value={this.state.uses}
              onChange={e => this.handleTextChange('uses', e)}
              margin="normal"
            />
            <TextField
              id="expiration"
              label="Expriation Date"
              type="date"
              onChange={this.handleExpirationDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="description"
              multiline
              label="Description"
              value={this.state.description}
              onChange={e => this.handleTextChange('description', e)}
              margin="normal"
              rows={5}
            />
            <Button
              disabled={this.state.fetching}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Paper>
      </div>
    );
  }
}

export default connect()(CreateAccessCode);
