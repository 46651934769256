import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  TableBody,
  TableRow,
  TableCell,
  Table,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { get } from 'lodash';

function maskPhoneNumber(phoneNumberString) {
  if (phoneNumberString.length === 0) {
    return '';
  }
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  if (cleaned.length > 3) {
    return `(${cleaned.substring(0, 3)}) ${cleaned.length > 2 ? `${cleaned.substring(3, 6)}${cleaned.length > 6 ? `-${cleaned.substring(6, 10)}` : ''}` : ''}`;
  }

  return cleaned;
}

function validatePhoneNumber(phoneNumber) {
  const re = /^\d{10}$/;
  return re.test(String(phoneNumber));
}

class EditPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basic: props.basic,
      phoneError: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (JSON.stringify(nextProps.basic) !== JSON.stringify(this.props.basic)) {
      this.setState({ basic: nextProps.basic });
    }
    return true;
  }

  handlePhoneChange (event, field) {
    const basic = JSON.parse(JSON.stringify(this.state.basic));
    let cleaned = event.target.value.replace(/\D+/g, '').replace(/^1/, '').replace(/^[0]/, '').trim();
    if (cleaned.length > 10) {
      cleaned = cleaned.slice(0, -1);
    }
    basic[field] = cleaned;
    this.setState({ basic, [`${field}Error`]: false });
  }

  validatePhone() {
    const { phone } = this.state.basic;
    if (!validatePhoneNumber(phone)) {
      this.setState({ phoneError: true });
      return false;
    }
    return true;
  }

  onSubmit() {
    if (this.validatePhone()) {
      this.props.updatePhone({ phone: maskPhoneNumber(this.state.basic.phone) });
    }
  }

  render() {
    if (!this.props.open) return null;
    return (
      <Dialog
        onClick={(event) => event.stopPropagation()}
        fullWidth
        maxWidth={'sm'}
        open={this.props.open}
        onClose={(event) => this.props.parentState(event, { editPhone: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" className="flex-grow">
                Edit Phone
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <TextField
                    autoFocus
                    error={this.state.phoneError}
                    placeholder="(XXX) XXX-XXXX"
                    style={{ width: '100%', margin: 0 }}
                    id="phone"
                    label="Phone"
                    className={'input-field ml-0'}
                    value={maskPhoneNumber(get(this.state.basic, 'phone', ''))}
                    onChange={e => this.handlePhoneChange(e, 'phone')}
                    margin="normal"
                    type="tel"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => this.props.parentState(event, { editPhone: false })}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.onSubmit()}
            variant="contained"
            color="primary"
            className="space"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(EditPhone);
