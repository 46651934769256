import React from 'react';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  LinearProgress,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import deleteSubsidiary from '~/services/company/deleteSubsidiary';
import { creators as errorCreators } from '~/ducks/error';

class DeleteSubsidiary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  async handleDelete() {
    this.setState({
      loading: true,
    });
    const updatedCompany = await deleteSubsidiary(
      this.props.companyId,
      this.props.subsidiaryData._id,
    );
    if (updatedCompany.data) {
      location.replace(`/dashboard/companies/${this.props.companyId}`);
    } else {
      this.setState({
        loading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Failed to delete subsidiary',
        action: false,
      }));
    }
  }

  render() {
    return (
      <Paper>
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Delete Subsidiary
            </Typography>
          </Toolbar>
          {this.state.loading && (
            <div className="loading-container">
              <LinearProgress className="loading" />
            </div>
          )}
        </AppBar>
        <Button
          variant="contained"
          color="secondary"
          onClick={this.handleDelete}
          style={{ margin: '10px' }}
        >
          DELETE
        </Button>
      </Paper>
    );
  }
}

DeleteSubsidiary.propTypes = {
  companyId: PropTypes.string.isRequired,
  subsidiaryData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default DeleteSubsidiary;
