import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AppBar,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Toolbar,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import {
  Add as AddIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

import { creators as errorCreators } from '~/ducks/error';
import createSubsidiary from '~/services/company/createSubsidiary';
import './style.css';

class SubsidiaryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyData: props.companyData,
      subsidiaries: props.companyData.subsidiaries,
      loading: false,
      ticker: props.companyData.tickerSymbol,
    };
  }

  componentDidUpdate() {
    if (this.state.ticker !== this.props.companyData.tickerSymbol) {
      this.setState({
        ticker: this.props.companyData.tickerSymbol,
      });
    }
  }

  render() {
    return (
      <Paper square id="subsidiary-module-container">
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
            >
              Subsidiaries
            </Typography>
            <Link to={`${this.state.ticker}/subsidiary/new`}>
              <IconButton
                aria-label="Create new subsidiary"
                color="inherit"
                disabled={this.state.loading}
              >
                <AddIcon style={{ color: 'white' }} />
              </IconButton>
            </Link>
          </Toolbar>
          {this.state.loading && (
            <div className="loading-container">
              <LinearProgress className="loading" />
            </div>
          )}
        </AppBar>
        <div id="subsidiary-table">
          <Table stickyHeader size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Matches
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.subsidiaries.map((subsidiary, i) => (
                <TableRow key={`Subsidiaries${i}`}>
                  <TableCell>
                    {subsidiary.name}
                  </TableCell>
                  <TableCell>
                    {subsidiary.match.length}
                  </TableCell>
                  <TableCell align="right">
                    <Link to={`${this.state.ticker}/subsidiary/${encodeURIComponent(subsidiary._id)}`}>
                      <Button
                        variant="outlined"
                      >
                        Edit
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

SubsidiaryTable.propTypes = {
  companyData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(SubsidiaryTable);
