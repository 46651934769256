import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  InputBase,
  IconButton,
  Switch,
  Tooltip,
} from '@material-ui/core';
import {
  ChevronRight,
  Search as SearchIcon,
  FilterList as FilterIcon,
  GetApp as DownloadIcon,
} from '@material-ui/icons';
import { intersection, get } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { useToasts } from "react-toast-notifications";

import './style.css';

function UserTable() {
  const searchRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const apollo = useApolloClient();
  const { addToast } = useToasts();

  const queryString = useMemo(() => {
    const raw = location.search.trim().replace(/\?/, "");
    if (raw.length === 0) return {};
    return raw.split("&").reduce((acc, cur) => {
      const parts = cur.split("=");
      return {
        ...acc,
        [parts[0]]: parts[1],
      };
    }, {});
  }, [location.search]);

  const [state, setState] = useState({
    page: queryString.page || 1,
    search: queryString.search ? decodeURIComponent(queryString.search) : "",
  });

  const [searchValue, setSearchValue] = useState(state.search);
  const [searchVisible, setSearchVisible] = useState(false);

  const query = useMemo(() => getQuery({ search: state.search }, state.page), [state.page, state.search]);

  const { data, loading, error, refetch } = useQuery(gql`
    query GetUsers($query: String)  {
      users(query: $query) @rest(type: "GetUserPayload", path: "/users{args.query}") {
        recordCount
        results @type(name: "[User]") {
          publicKey
          stopDeposit
          basic @type(name: "Profile") {
            email
            firstName
            lastName
          }
        }
      }
    }
  `, {
    variables: {
      query: query
    },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    dispatch(push(`/dashboard/users${query}`));
  }, [query]);

  async function toggleStopDeposit(event, publicKey) {
    event.preventDefault();
    const checked = event.target.checked;

    try {
      const updatedUser = await apollo.mutate({
        mutation: gql`
          mutation UpdateUser($id: String, $input: UpdateUserInput) {
            updateUser(id: $id, input: $input) @rest(type: "User", path: "/users/{args.id}", method: "PATCH") {
              data @type(name: "UpdateUserResponse")
            }
          }
        `,
        variables: {
          id: publicKey,
          input: {
            data: {
              stopDeposit: checked,
            }
          }
        },
        update: (cache, { data }) => {
          refetch();
        }
      });

      addToast(`Future deposits have been ${checked ? "stopped" : "enabled"}.`, { appearance: "success", autoDismiss: true });

      if (!checked) return;

      try {
        const updatedTransactions = await apollo.mutate({
          mutation: gql`
            mutation UpdateUserTransactions($id: String, $input: UpdateUserTransactionsInput) {
              updateUserTransactions(id: $id, input: $input) @rest(type: "Transaction", path: "/users/{args.id}/update/transactions", method: "POST") {
                data @type(name: "UpdateUserTransactionsResponse")
              }
            }
          `,
          variables: {
            id: publicKey,
            input: {
              data: {
                skip: !checked,
              }
            }
          }
        });

        addToast(`Pending transactions have been halted.`, { appearance: "success", autoDismiss: true });
      } catch (err) {
        addToast(err.message || `Failed to update transactions from deposit for user`, { appearance: "error", autoDismiss: true });
      }
    } catch (err) {
      addToast(err.message || `Failed to update stop deposit for user`, { appearance: "error", autoDismiss: true });
    }
  }


  function getQuery(filter, page) {
    if (!filter && !page) return '';
    const p = page || 1;
    return `?page=${p}${filter && Object.keys(filter).length > 0 ? `&${Object.keys(filter).map(key => `${key}=${encodeURIComponent(filter[key])}`).join('&')}` : ''}`;
  }

  function renderTable() {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error...</div>;

    const { results } = data.users;

    if (!results || results.length === 0) return <div>No records found...</div>

    return (
      <>
        <div className="grid border-b py-3 gap-x-2 font-bold uppercase" style={{ gridTemplateColumns: "1fr 1fr 3fr 1fr 1fr"}}>
          <div>First Name</div>
          <div>Last Name</div>
          <div>Email</div>
          <div className="col-span-2">Stop Deposits</div>
        </div>
        {results.map((user, index) => {
          return (
            <div className={`grid gap-x-2 py-4 ${index < results.length - 1 ? "border-b" : ""}`}
              style={{ gridTemplateColumns: "1fr 1fr 3fr 1fr 1fr"}} key={`user-${user.publicKey}`}
              >
              <div>{user.basic.firstName}</div>
              <div>{user.basic.lastName}</div>
              <div>{user.basic.email}</div>
              <div>
                <Switch id={user.basic.email} value={user.basic.email} color={'secondary'} style={{ height: "auto" }} checked={!!user.stopDeposit} onChange={(e) => toggleStopDeposit(e, user.publicKey)} />
              </div>
              <div className="justify-self-end cursor-pointer"
                onClick={() => {
                  dispatch(push(`/dashboard/user/${user.publicKey}`));
                }}>
                <ChevronRight />
              </div>
            </div>
          )
        })}

        <div className="flex justify-center py-3">
        {
          state.page > 1 && (
            <a
              href="#"
              style={{ color: '#21406D', textDecoration: 'none' }}
              onClick={(e) => {
                e.preventDefault();
                setState({
                  ...state,
                  page: state.page - 1,
                });
              }}
            >{'<<'} Prev</a>
          )
        }
        <span style={{ margin: '0 10px' }}>
          Page {state.page}
        </span>
        {
          results.length === 15 && (
            <a
              href="#"
              style={{ color: '#21406D', textDecoration: 'none' }}
              onClick={(e) => {
                e.preventDefault();

                setState({
                  ...state,
                  page: parseInt(state.page) + 1,
                });
              }}
            >Next {'>>'}</a>
          )
        }
        </div>
      </>
    )
  }

  return (
    <div className="m-4">
      <div className="flex justify-between">
        <h6 className="text-2xl mb-4">
          Users
        </h6>
        <div
          id="toolbar-search"
          className={state.searchVisible ? 'open' : 'closed'}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Tooltip enterDelay={300} title="Search">
            <IconButton
              onClick={() => setSearchVisible(true)}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          {
            searchVisible && (
              <div>
                <InputBase
                  id="text-search"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  inputRef={searchRef}
                />
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setState({
                      ...state,
                      page: 1,
                      search: searchValue,
                    });
                  }}
                >Search</a>
              </div>
            )}
        </div>
      </div>

      <div className="rounded shadow p-4 text-sm overflow-y-scroll" style={{ minWidth: "720px" }}>
        {useMemo(() => renderTable(), [state, loading])}
      </div>
    </div>
  );
}

export default UserTable;
