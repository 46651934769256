import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  TextField,
  LinearProgress,
} from '@material-ui/core';
import {
  NavigateBefore,
} from '@material-ui/icons';
import updateSubsidiary from '~/services/company/updateSubsidiary';
import createSubsidiary from '~/services/company/createSubsidiary';
import { creators as errorCreators } from '~/ducks/error';

import './style.css';

class UpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subsidiaryData: props.subsidiaryData,
      name: props.subsidiaryData.name,
      loading: false,
    };

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTextChange(name, event) {
    this.setState({ [name]: event.target.value });
  }

  async handleSubmit() {
    this.setState({
      loading: true,
    });
    let updatedCompany;
    if (this.props.subsidiaryData._id) {
      updatedCompany = await updateSubsidiary(
        this.props.companyId,
        this.props.subsidiaryData._id,
        {
          name: this.state.name,
          match: this.props.subsidiaryData.match,
        },
      );
    } else {
      updatedCompany = await createSubsidiary(
        this.props.companyId,
        {
          name: this.state.name,
          match: [],
        },
      );
    }

    if (updatedCompany.data) {
      const updatedSubsidiary = updatedCompany.data.subsidiaries.find(
        o => o.name === this.state.name,
      );
      if (this.state.subsidiaryData._id !== updatedSubsidiary._id) {
        location.replace(`/dashboard/companies/${this.props.companyId}/subsidiary/${encodeURIComponent(updatedSubsidiary._id)}`);
      }
      this.setState({
        loading: false,
        name: updatedSubsidiary.name,
        subsidiaryData: updatedSubsidiary,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Company updated successfully',
        action: false,
      }));
    } else {
      this.setState({
        loading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: updatedCompany.error.message || 'Failed to update company',
        action: false,
      }));
    }
  }

  render() {
    return (
      <Paper square id="subsidiary-update-form">
        <AppBar position="static">
          <Toolbar>
            <IconButton
              className="menu-button"
              color="inherit"
              aria-label="back"
              onClick={() => this.props.history.goBack()}
            >
              <NavigateBefore />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              {this.state.name}
            </Typography>
          </Toolbar>
          {this.state.loading && (
            <div className="loading-container">
              <LinearProgress className="loading" />
            </div>
          )}
        </AppBar>
        <form
          className="company-edit-form"
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <TextField
            id="name"
            label="Name"
            className={`input-field ${this.state.name !== this.state.subsidiaryData.name ? 'changed' : ''}`}
            value={this.state.name}
            onChange={e => this.handleTextChange('name', e)}
            margin="normal"
          />
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="space"
            disabled={this.state.name === this.state.subsidiaryData.name}
          >
            Submit
          </Button>
        </form>
      </Paper>
    );
  }
}

UpdateForm.propTypes = {
  companyId: PropTypes.string.isRequired,
  subsidiaryData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect()(UpdateForm);
