import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AppBar,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Button,
  Toolbar,
  TablePagination,
  LinearProgress,
} from '@material-ui/core';
import moment from 'moment';
import getReportsList from '~/services/user/getActiveUsersReports';
import getActiveUsersReportUrl from '~/services/user/getActiveUsersReportUrl';
import { creators as errorCreators } from '~/ducks/error';

import CustomFormControl from './CustomFormControl';

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

class ActiveUsersReportsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      loading: false,
      reports: [],
      total: 0,
      page: 0,
      entries: 10,
    };
  }

  componentDidMount() {
    if (this.state.fetching) {
      this.fetchReportsList(1, this.state.entries);
    }
  }

  async fetchReportsList(page, limit) {
    const response = await getReportsList(page, limit);
    if (response.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch active users reports list',
        action: false,
      }));
      this.setState({
        fetching: false,
        reports: [],
        total: 0,
      });
    } else {
      this.setState({
        reports: response.data.reportsList,
        total: response.data.total,
        fetching: false,
      });
    }
  }

  downloadReport(fileName) {
    this.setState({
      loading: true,
    }, async () => {
      const response = await getActiveUsersReportUrl(fileName);
      const a = document.createElement('a');
      a.href = response.url;
      a.click();
      this.setState({ loading: false });
    })
  }

  handleChangePage(event, page) {
    this.setState({ page, fetching: true });
    this.fetchReportsList((page + 1), this.state.entries);
  }

  handleEntriesChange(event) {
    this.setState({
      entries: event.target.value,
      page: 0,
      fetching: true
    });
    this.fetchReportsList(1, event.target.value);
  }

  render() {
    return (
      <Paper square>
        {this.state.fetching &&
          <div className="circular-loading-container">
            <CircularProgress />
          </div>
        }
        {!this.state.fetching &&
          <div id="module-content" className="full-width">
            <AppBar position="static">
              <Toolbar>
                <div className="title-container">
                  <Typography variant="h6" id="tableTitle">
                    Active Users Reports
                  </Typography>
                </div>
                <div id="page-entries">
                  <CustomFormControl value={this.state.entries} onChange={(e) => this.handleEntriesChange(e)} />
                </div>
              </Toolbar>
              {this.state.loading && (
                <div className="loading-container" style={{ top: '1px', height: '5px' }}>
                  <LinearProgress className="loading" />
                </div>
              )}
            </AppBar>
            <div id="table-container">
              <Table id="table" stickyHeader size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Last Modified</TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody id="table-body">
                  {this.state.reports.map(row => (
                    <TableRow key={`ActiveUsersReport-${row.Key}`}>
                      <TableCell>{row.Key.replace('reportsNotAdmins/', '').replace('reportsAdmins/', '')}</TableCell>
                      <TableCell>{formatBytes(row.Size)}</TableCell>
                      <TableCell>{moment(row.LastModified).format('MM-DD-YY hh:mm A')}</TableCell>
                      <TableCell align="right">
                        <Button
                          disabled={this.state.loading}
                          onClick={() => this.downloadReport(row.Key)}
                          variant="outlined"
                        >
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              id="table-pagination"
              count={this.state.total}
              rowsPerPage={this.state.entries}
              rowsPerPageOptions={[]}
              page={this.state.page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={(e, page) => this.handleChangePage(e, page)}
            />
          </div>
        }
      </Paper>
    );
  }
}

ActiveUsersReportsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(ActiveUsersReportsTable);
