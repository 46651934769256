import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Tooltip,
  LinearProgress,
  IconButton,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import {
  Refresh, ExpandMore,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import actionsByUser from '~/services/transactions/actionsByUser';
import Transactions from './Transactions';

const actions = [{
  title: 'Run Deposits',
  url: 'deposits',
  name: 'Deposit',
}, {
  title: 'Run Orders',
  url: 'order',
  name: 'Order',
}, {
  title: 'Run Allocations',
  url: 'order/allocate',
  name: 'Allocate',
}, {
  title: 'Run Distributes',
  url: 'order/distribute',
  name: 'Distribute',
}];

class TransactionsFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      running: false,
      refresh: false,
      expanded: true,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
    return true;
  }

  async runProcess(event, url) {
    event.stopPropagation();
    if (!this.state.running) {
      this.setState({ running: true });
      await actionsByUser(url);
      this.setState({ running: false });
    }
  }

  refreshAllTransactions(event) {
    event.stopPropagation();
    this.setState({ refresh: true });
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    return (
      <Paper square>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Transactions Flow
            </Typography>
            {actions.map((action, i) =>
              <Tooltip
                key={`Transactions_Action_${i}`}
                title={action.title}
                onClick={(event) => this.runProcess(event, `${action.url}/${this.props.userKey}`)}
              >
                <Button
                  size="small"
                  variant="outlined"
                  style={{ color: 'white', borderColor: 'white', marginRight: '10px' }}
                >
                  {action.name}
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title="Refresh All Transactions"
              onClick={(event) => this.refreshAllTransactions(event)}
            >
              <IconButton size={'small'}>
                <Refresh style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            {this.state.running && (
              <div className="loading-container" style={{ width: '100%', position: 'absolute', left: 0, top: '48px' }}>
                <LinearProgress className="loading" />
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <div className="item-container" style={{ paddingTop: '7px', overflow: 'hidden' }}>
              <Grid container spacing={1} wrap={'wrap'}>
                <Grid item xs={4}>
                  <Transactions refresh={this.state.refresh} status={'SETTLED'} userKey={this.props.userKey} parentState={state => this.setState(state)} />
                </Grid>
                <Grid item xs={3}>
                  <Transactions refresh={this.state.refresh} status={'DEPOSIT'} userKey={this.props.userKey} parentState={state => this.setState(state)} />
                </Grid>
                <Grid item xs={3}>
                  <Transactions refresh={this.state.refresh} status={'ORDER'} userKey={this.props.userKey} parentState={state => this.setState(state)} />
                </Grid>
                <Grid item xs={2}>
                  <Transactions refresh={this.state.refresh} status={'DONE'} userKey={this.props.userKey} parentState={state => this.setState(state)} />
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>        
      </Paper>
    );
  }
}

TransactionsFlow.propTypes = {
  userKey: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(TransactionsFlow);
