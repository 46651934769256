import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  withStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 70
  },
  underline: {
    color: "white",
    "&::after": {
      borderBottom: "2px solid white"
    },
    "&::before": {
      borderBottom: "2px solid white"
    }
  },
}));

const CustomSelect = withStyles({
  icon: {
    fill: 'white',
  },
})(Select);

export default function SimpleSelect({ value, onChange }) {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel style={{ color: 'white' }} htmlFor="per-page">Per Page</InputLabel>
        <CustomSelect
          className={classes.underline}
          inputProps={{
            id: 'per-page',
          }}
          value={value}
          onChange={(e) => onChange(e)}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </CustomSelect>
      </FormControl>
    </div>
  );
}
