import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  CircularProgress,
  Button,
  Toolbar,
  InputBase,
  IconButton,
  MenuItem,
  Tooltip,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  Switch,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import getAll from '~/services/agreements/getAll';
import update from '~/services/agreements/update';
import { creators as errorCreators } from '~/ducks/error';
import moment from 'moment';

class AgreementsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      agreements: [],
      searchActive: false,
      filterMenu: null,
      textSearch: '',
      sortBy: 'name',
      order: 'desc',
      page: 0,
      entries: 15,
    };

    this.searchInput = React.createRef();
  }

  async componentDidMount() {
    if (this.state.fetching) {
      const response = await getAll();
      if (response.error) {
        this.props.dispatch(errorCreators.new({
          message: 'Failed to fetch agreements',
          action: false,
        }));
        this.setState({
          fetching: false,
          agreements: [],
        });
      } else {
        this.setState({
          agreements: response.data.agreements,
          fetching: false,
        });
      }
    }
  }

  handleEntriesChange(event) {
    this.setState({
      entries: event.target.value,
      page: 0,
    });
  }

  sortBy(by) {
    if (by === this.state.sortBy) {
      this.setState({
        order: this.state.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortBy: by,
        order: 'desc',
      });
    }
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleSearchTextChange(event) {
    this.setState({
      textSearch: event.target.value,
    });
  }

  toggleSearch() {
    if (this.state.searchActive) {
      this.setState({
        searchActive: false,
        textSearch: '',
      });
    } else {
      this.setState({
        searchActive: true,
      }, () => this.searchInput.current.focus());
    }
  }


  sortAgreements(agreements) {
    if (!this.state.sortBy) {
      return agreements;
    }
    const sortedAgreements = agreements.sort((a, b) => {
      if (typeof get(a, this.state.sortBy, '') === 'string') {
        const x = get(a, this.state.sortBy, '').toLowerCase();
        const y = get(b, this.state.sortBy, '').toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
      }
      return a[this.state.sortBy] - b[this.state.sortBy];
    });
    if (this.state.order === 'desc') {
      return sortedAgreements;
    }
    return sortedAgreements.reverse();
  }

  filterAgreements() {
    if (!this.state.searchActive) {
      return this.state.agreements;
    }

    return this.state.agreements.filter((agreement) => {
      const search = this.state.textSearch.toLowerCase();
      const version = get(agreement, 'version', '');
      const name = get(agreement, 'name', '');
      const url = get(agreement, 'url', '');

      return String(version).toLowerCase().includes(search) || name.toLowerCase().includes(search) || url.toLowerCase().includes(search);
    });
  }

  async toggleChecked(event, id, name, version, url) {
    const checked = event.target.checked;
    let shouldUpdate = true;

    if (checked) {
      const agreements = this.state.agreements.filter(agreement => agreement.url === url);
      if (agreements.length > 1) {
        shouldUpdate = confirm('Are you sure you want to continue? There are more agreements with the same url and they will be made inactive!');
      }
    }

    if (shouldUpdate) {
      const updatedAgreement = await update(id, name, version, url, checked);
      if (updatedAgreement.data) {
        const agreements = JSON.parse(JSON.stringify(this.state.agreements));
        agreements.map((agreement) => {
          if (agreement.url === url && agreement._id === id) {
            agreement.active = checked;
          } else if (agreement.url === url) {
            agreement.active = false;
          }
        });
        this.setState({
          agreements,
        });
        this.props.dispatch(errorCreators.new({
          message: 'Agreement updated successfully',
          action: false,
        }));
      } else {
        this.props.dispatch(errorCreators.new({
          message: updatedAgreement.error.message || `Failed to update Agreement version ${version}`,
          action: false,
        }));
      }
    }
  }

  render() {
    return (
      <div id="module-container" className={this.state.fetching ? 'fetching' : 'full'}>
        <Paper className="table-paper">
          {this.state.fetching &&
            <div className="circular-loading-container">
              <CircularProgress />
            </div>
          }
          {!this.state.fetching &&
            <div id="module-content" className="full-width">
              <Toolbar>
                <div className="title-container">
                  <Typography variant="h6" id="tableTitle">
                    Agreements
                  </Typography>
                </div>
                <div id="page-entries">
                  <FormControl style={{ minWidth: '70px' }}>
                    <InputLabel htmlFor="per-page">Per Page</InputLabel>
                    <Select
                      value={this.state.entries}
                      onChange={(e) => this.handleEntriesChange(e)}
                      inputProps={{ id: 'per-page' }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  id="toolbar-search"
                  className={this.state.searchActive ? 'open' : 'closed'}
                >
                  <Tooltip enterDelay={300} title="Search">
                    <IconButton
                      onClick={() => this.toggleSearch()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  {
                    this.state.searchActive &&
                    <InputBase
                      id="text-search"
                      placeholder="Search"
                      onChange={(e) => this.handleSearchTextChange(e)}
                      inputRef={this.searchInput}
                      value={this.state.searchText}
                    />
                  }
                </div>
                <div
                  id="toolbar-add"
                >
                  <Tooltip enterDelay={300} title="Create Agreement">
                    <Link to={'/dashboard/agreements/create'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </div>
              </Toolbar>
              <div id="table-container">
                <Table id="table" stickyHeader size={'small'}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'name'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('name')}
                          >
                            Name
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'version'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('version')}
                          >
                            Version
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'url'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('url')}
                          >
                            URL
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Last updated">
                          <TableSortLabel
                            active={this.state.sortBy === 'last_updated'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('last_updated')}
                          >
                            Last updated
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'active'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('active')}
                          >
                            Active
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody id="table-body">
                    {this.sortAgreements(this.filterAgreements())
                      .slice(
                        this.state.page * this.state.entries,
                        (this.state.page * this.state.entries) + this.state.entries,
                      )
                      .map(row => (
                        <TableRow key={`accessCode-${row._id}`}>
                          <TableCell style={{ width: '270px' }}>{row.name}</TableCell>
                          <TableCell>{row.version}</TableCell>
                          <TableCell>{row.url}</TableCell>
                          <TableCell style={{ width: '180px' }}>{moment(row.last_updated).format('MM-DD-YY hh:mm A')}</TableCell>
                          <TableCell>
                            <Switch id={row._id} value={row._id} color={'secondary'} checked={!!row.active} onChange={e => this.toggleChecked(e, row._id, row.name, row.version, row.url)} />
                          </TableCell>
                          <TableCell align="right">
                            <Link to={{
                              pathname: `agreements/${encodeURIComponent(row._id)}`,
                              query: row,
                            }}
                            >
                              <Button
                                variant="outlined"
                              >
                                Edit
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                component="div"
                id="table-pagination"
                count={this.state.agreements.length}
                rowsPerPage={this.state.entries}
                rowsPerPageOptions={[]}
                page={this.state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={(e, page) => this.handleChangePage(e, page)}
              />
            </div>
          }
        </Paper>
      </div>
    );
  }
}

AgreementsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(AgreementsTable);
