import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Tooltip,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableContainer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { get } from 'lodash';
import {
  ExpandMore,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import moment from 'moment';

class ForgotPasswordAttempts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
    return true;
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const attempts = get(this.props.user, 'forgotPassword.attempts', []);

    if (attempts.length === 0) return null;

    return (
      <Paper square style={{ marginTop: 8 }}>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Forgot Password Logs
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, flexDirection: 'column' }}>
            <div>
              <TableContainer style={{ maxHeight: 450 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        First Name
                      </TableCell>
                      <TableCell align="center"  style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        Last Name
                      </TableCell>
                      <TableCell align="center"  style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        Postal Code
                      </TableCell>
                      <TableCell align="center"  style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        Brokerage ID
                      </TableCell>
                      <TableCell align="center" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        DriveWealth User
                      </TableCell>
                      <TableCell align="center" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        National ID
                      </TableCell>
                      <TableCell align="center" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                        Phone Number
                      </TableCell>
                      <TableCell align="center">
                        Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      attempts.reverse().map((attempt, i) => (
                        <TableRow key={`FPAttempt_${i}`}>
                          <TableCell align="center" style={{ padding: '0px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '2px solid rgb(111 109 109)' }}>
                            {attempt.fields.firstName
                              && <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left" style={{ padding: '5px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                      Original
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: '5px' }}>
                                      {attempt.fields.firstName.original}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="left" style={{ padding: '5px', borderBottom: '0px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Entered</TableCell>
                                    <TableCell align="left" style={{ padding: '5px', borderBottom: '0px' }}>{attempt.fields.firstName.entered}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            }
                          </TableCell>
                          <TableCell align="center" style={{ padding: '0px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '2px solid rgb(111 109 109)' }}>
                            {attempt.fields.lastName
                              && <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left" style={{ padding: '5px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                      Original
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: '5px' }}>
                                      {attempt.fields.lastName.original}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="left" style={{ padding: '5px', borderBottom: '0px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Entered</TableCell>
                                    <TableCell align="left" style={{ padding: '5px', borderBottom: '0px' }}>{attempt.fields.lastName.entered}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            }
                          </TableCell>
                          <TableCell align="center" style={{ padding: '0px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '2px solid rgb(111 109 109)' }}>
                            {attempt.fields.postalCode
                              && <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left" style={{ padding: '5px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                      Original
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: '5px' }}>
                                      {attempt.fields.postalCode.original}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="left" style={{ padding: '5px', borderBottom: '0px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Entered</TableCell>
                                    <TableCell align="left" style={{ padding: '5px', borderBottom: '0px' }}>{attempt.fields.postalCode.entered}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            }
                          </TableCell>
                          <TableCell align="center" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '2px solid rgb(111 109 109)' }}>
                            {get(attempt, 'fields.brokerageID.message', '')}
                          </TableCell>
                          <TableCell align="center" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '2px solid rgb(111 109 109)' }}>
                            {get(attempt, 'fields.driveWealthUser.message', '')}
                          </TableCell>
                          <TableCell align="center" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '2px solid rgb(111 109 109)' }}>
                            {get(attempt, 'fields.nationalID.message', '')}
                          </TableCell>
                          <TableCell align="center" style={{ padding: '0px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '2px solid rgb(111 109 109)' }}>
                            {attempt.fields.phoneNumber
                              && <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left" style={{ padding: '5px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                      Original
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: '5px' }}>
                                      {attempt.fields.phoneNumber.original}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="left" style={{ padding: '5px', borderBottom: '0px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>Entered</TableCell>
                                    <TableCell align="left" style={{ padding: '5px', borderBottom: '0px' }}>{attempt.fields.phoneNumber.entered}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            }
                          </TableCell>
                          <TableCell align="center" style={{ borderBottom: '2px solid rgb(111 109 109)' }}>
                            {moment(attempt.date).format('MM-DD-YY hh:mm A')}
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
}

ForgotPasswordAttempts.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(ForgotPasswordAttempts);
