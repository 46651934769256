import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  LinearProgress,
  Switch,
  IconButton,
  TableContainer,
} from '@material-ui/core';
import {
  PlusOne,
  AddCircleOutline,
  Refresh,
} from '@material-ui/icons';
import { get } from 'lodash';
import { creators as errorCreators } from '~/ducks/error';
import getByUser from '~/services/transactions/getByUser';
import update from '~/services/transactions/update';
import { connect } from 'react-redux';
import One from './one';
import Multiple from './multiple';

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openOne: false,
      openMultiple: false,
      transactions: null,
      transactionsUpdateLoading: true,
      sortBy: '',
      sortType: '',
      order: 'desc',
    };
  }

  componentDidMount() {
    if (this.state.transactionsUpdateLoading) {
      this.getTransactions();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.refresh !== this.props.refresh && nextProps.refresh) {
      this.refreshTransactions(nextProps.refresh);
    }
    return true;
  }

  refreshTransactions(refresh) {
    this.setState({
      transactionsUpdateLoading: true,
      transactions: [],
    });
    this.getTransactions(refresh);
  }

  async getTransactions(refresh) {
    const transactions = await getByUser(this.props.userKey, this.props.status);
    if (transactions.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch transactions for user',
        action: false,
      }));
      this.setState({
        transactionsUpdateLoading: false,
        transactions: [],
        openOne: false,
        openMultiple: false,
      });
    } else {
      this.setState({
        transactionsUpdateLoading: false,
        transactions: transactions.data,
        openOne: false,
        openMultiple: false,
      });
    }
    if (refresh) {
      this.props.parentState({ refresh: false });
    }
  }

  sortBy(by, type) {
    if (by === this.state.sortBy) {
      this.setState({
        order: this.state.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortBy: by,
        order: 'desc',
        sortType: type || 'string',
      });
    }
  }

  sortTransactions(transactions) {
    if (!this.state.sortBy) {
      return transactions;
    }
    const sortedTransactions = transactions.sort((a, b) => {
      const aValue = get(a, this.state.sortBy, '') || '';
      const bValue = get(b, this.state.sortBy, '') || '';
      if (this.state.sortType === 'string') {
        const x = aValue.toLowerCase();
        const y = bValue.toLowerCase();
        if (x === '' || x === null) return 1;
        if (y === '' || y === null) return -1;
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
      }
      return aValue - bValue;
    });
    if (this.state.order === 'desc') {
      return sortedTransactions;
    }
    return sortedTransactions.reverse();
  }

  async toggleChecked(event, id) {
    const checked = event.target.checked;
    const updatedTransaction = await update(
      id,
      {
        roundupEnabled: checked,
      },
    );
    if (updatedTransaction.data) {
      const transactions = JSON.parse(JSON.stringify(this.state.transactions));
      const findTransaction = transactions.find(transaction => transaction._id === id);
      if (findTransaction) {
        findTransaction.roundupEnabled = checked;
        this.setState({
          transactions,
        });
      }
      this.props.dispatch(errorCreators.new({
        message: 'Transaction updated successfully',
        action: false,
      }));
    } else {
      this.props.dispatch(errorCreators.new({
        message: updatedTransaction.error.message || 'Failed to update transaction',
        action: false,
      }));
    }
  }

  render() {
    return (
      <Paper square>
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              {this.props.status}
            </Typography>
            {this.props.status === 'SETTLED' &&
              <div>
                <Tooltip
                  title="Add Single Transaction"
                  onClick={() => this.setState({ openOne: true })}
                >
                  <IconButton>
                    <PlusOne style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
                <One userKey={this.props.userKey} open={this.state.openOne} parentState={state => this.setState(state)} refreshTransactions={() => this.refreshTransactions()} />
                <Tooltip
                  title="Add Multiple Transactions"
                  onClick={() => this.setState({ openMultiple: true })}
                >
                  <IconButton>
                    <AddCircleOutline style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
                <Multiple userKey={this.props.userKey} open={this.state.openMultiple} parentState={state => this.setState(state)} refreshTransactions={() => this.refreshTransactions()} />
              </div>
            }
            <Tooltip
              title="Refresh Transactions"
              onClick={() => this.refreshTransactions()}
            >
              <IconButton>
                <Refresh style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          </Toolbar>
          {this.state.transactionsUpdateLoading && (
            <div className="loading-container">
              <LinearProgress className="loading" />
            </div>
          )}
        </AppBar>
        {!this.state.transactionsUpdateLoading &&
          <TableContainer style={{ maxHeight: 480 }}>
            <Table stickyHeader id="table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel
                        active={this.state.sortBy === 'name'}
                        direction={this.state.order}
                        onClick={() => this.sortBy('name', 'string')}
                      >
                        Name
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel
                        active={this.state.sortBy === 'amount'}
                        direction={this.state.order}
                        onClick={() => this.sortBy('amount', 'number')}
                      >
                        Amount
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  {this.props.status === 'SETTLED' &&
                    <TableCell>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel
                          active={false}
                        >
                          Roundup Enabled
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody id="table-body">
                {this.sortTransactions(this.state.transactions)
                  .map(row => (
                    <TableRow key={`transaction-${row._id}`}>
                      <TableCell component="th" scope="row">
                        {row.name}
                        {row.companyName === 'cash' && <b><br />{'cash'}</b>}
                        {row.haltedReason && <b style={{ color: 'red' }}><br />{row.haltedReason}</b>}
                      </TableCell>
                      <TableCell>{get(row, 'amount', '')}</TableCell>
                      {this.props.status === 'SETTLED' &&
                      <TableCell>
                        <Switch id={row._id} value={row._id} color={'secondary'} checked={!!row.roundupEnabled} onChange={e => this.toggleChecked(e, row._id)} />
                      </TableCell>
                      }
                    </TableRow>
                  ),
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Paper>
    );
  }
}

Transactions.propTypes = {
  userKey: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(Transactions);
