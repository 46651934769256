import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Snackbar,
  Button,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { creators } from '~/ducks/error';
// import * as Sentry from '@sentry/browser';

class ErrorBar extends Component {
  constructor(props) {
    super(props);

    this.getErrorActionLabel = this.getErrorActionLabel.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);
    this.handleErrorAction = this.handleErrorAction.bind(this);
  }

  getErrorActionLabel() {
    switch (this.props.error.action) {
      case 'reload':
        return 'REFRESH';
      default:
        return 'CLOSE';
    }
  }

  handleErrorClose() {
    this.props.dispatch(creators.clear());
  }

  handleErrorAction() {
    const { dispatch, error } = this.props;
    switch (error.action) {
      case 'refresh':
        dispatch(creators.clear());
        window.location.reload();
        break;
      default:
        dispatch(creators.clear());
    }
  }


  render() {
    const { error } = this.props;
    // Sentry.captureException(error.message);
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message={error.message}
        open={error.error}
        onClose={() => this.handleErrorClose()}
        onActionClick={() => this.handleErrorAction()}
        action={
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={this.handleErrorAction}
          >
            {this.getErrorActionLabel()}
          </Button>
        }
      />
    );
  }
}

ErrorBar.propTypes = {
  error: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  error: state.error,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBar);
