import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProtectedRoute from '~/components/ProtectedRoute';
import HeaderBar from '~/components/HeaderBar';
import ErrorBar from '~/components/ErrorBar';
import Login from '~/routes/Login';
import Dashboard from '~/routes/Dashboard';
import './style.css';

// Top level router for auth gate and dashboard
function App() {
  return (
    <div id="app">
      <HeaderBar />
      <Switch>
        <Route exact path="/login" component={Login} />
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <Route component={() => <h6>404</h6>} />
      </Switch>
      <ErrorBar />
      {
        location.pathname === '/' && (
          <Redirect push to="/login" />
        )
      }
    </div>
  );
}

export default App;
