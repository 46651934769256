import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import get from '~/services/company/get';
import { creators as errorCreators } from '~/ducks/error';

import UpdateForm from './UpdateForm';
import ManageMatches from './ManageMatches';
import SubsidiaryTable from './SubsidiaryTable';
import DeleteCompany from './DeleteCompany';
import ManageTags from './ManageTags';
import ManageRiskLevel from './ManageRiskLevel';

import './style.css';

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      company: null,
    };
  }

  async componentDidMount() {
    if (this.state.fetching && this.props.match.params.ticker === 'create') {
      this.setState({
        company: {
          subsidiaries: [],
          name: '',
          tickerSymbol: '',
          logo: '',
          background: '',
          match: [],
        },
        fetching: false,
      });
    }
    if (this.state.fetching && this.props.match.params.ticker !== 'create') {
      const company = await get(this.props.match.params.ticker);
      if (company.error) {
        this.props.dispatch(errorCreators.new({
          message: 'Failed to fetch company',
          action: false,
        }));
        this.setState({
          fetching: true,
          company: {},
        });
      } else {
        this.setState({
          company: company.data,
          fetching: false,
        });
      }
    }
  }

  async componentDidUpdate() {
    if (this.props.match.params.ticker !== 'create') {
      if (this.props.match.params.ticker !== this.state.company.tickerSymbol) {
        const company = await get(this.props.match.params.ticker);
        if (company.error) {
          this.props.dispatch(errorCreators.new({
            message: 'Failed to fetch company',
            action: false,
          }));
          this.setState({
            fetching: false,
            company: {},
          });
        } else {
          this.setState({
            company: company.data,
            fetching: false,
          });
        }
      }
    }
  }

  render() {
    if (this.state.fetching) {
      return (
        <div id="company-loading-container">
          <CircularProgress />
        </div>
      );
    }
    return (
      <div id="company-modules-container">
        <div id="company-top-modules-container">
          <UpdateForm companyData={this.state.company} history={this.props.history} new={this.props.match.params.ticker === 'create'} />
          {this.props.match.params.ticker !== 'create' && <ManageTags companyData={this.state.company} />}
        </div>
        {(this.props.match.params.ticker !== 'create') &&
          <div id="company-bottom-modules-container">
            <ManageMatches companyData={this.state.company} />
            <SubsidiaryTable companyData={this.state.company} />
          </div>
        }
        {this.props.match.params.ticker !== 'create' &&
          <div id="company-bottom-modules-container">
            <DeleteCompany companyData={this.state.company} />
            <ManageRiskLevel companyData={this.state.company} riskLevel={this.state.company.riskLevel || ''} />
          </div>
        }
        {/* <p>{JSON.stringify(this.state.company, null, '\n')}</p> */}
      </div>
    );
  }
}

Company.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(Company);
