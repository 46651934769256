import React from 'react';
import {
  Paper,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import {
  Refresh, ExpandMore,
} from '@material-ui/icons';
import { creators as errorCreators } from '~/ducks/error';
import { without } from 'lodash';
import update from '~/services/user/update';
import PropTypes from 'prop-types';

const carouselItems = [
  { id: 1, name: 'Grifin' },
  { id: 2, name: 'Time Machine' },
  { id: 5, name: 'Referral Code' },
  { id: 3, name: 'Security Settings' },
  { id: 4, name: 'Verify Email/Phone' },
]
class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userUpdateLoading: false,
      expanded: true,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.expanded !== this.state.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
    return true;
  }

  handleChange() {
    this.setState({ expanded: !this.state.expanded })
  }

  async refreshCarouselItem(num) {
    this.setState({
      userUpdateLoading: true,
    });
    const user = JSON.parse(JSON.stringify(this.props.user));
    user.dashboard = without(user.dashboard || [], num);
    const updatedUser = await update(
      this.props.userKey,
      {
        dashboard: user.dashboard,
      },
    );
    if (updatedUser.data) {
      this.props.parentState({ user });
      this.setState({
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Carousel updated successfully',
        action: false,
      }));
    } else {
      this.setState({
        userUpdateLoading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Failed to update Carousel',
        action: false,
      }));
    }
  }

  render() {
    const { dashboard } = this.props.user;

    return (
      <Paper square>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
            >
              Carousel
            </Typography>
            {this.state.userUpdateLoading && (
              <div className="loading-container">
                <LinearProgress className="loading" />
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Table>
              <TableBody>
                {carouselItems.map((item, i) => {
                  if (!dashboard.includes(item.id)) return null;
                  return (
                    <TableRow key={`CarouselItem_${i}`}>
                      <TableCell>
                        {item.name}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        <Tooltip
                          title="Bring back to the Carousel"
                        >
                          <IconButton
                            style={{ padding: 5 }}
                            onClick={() => this.refreshCarouselItem(item.id)}
                          >
                            <Refresh />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>        
      </Paper>
    )
  }
}

Carousel.propTypes = {
  user: PropTypes.object,
};

Carousel.defaultProps = {
  user: {},
};

export default Carousel;
