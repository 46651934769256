import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  LinearProgress,
  Checkbox,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Table,
} from '@material-ui/core';
import create from '~/services/redemptions/create';
import getBrokerageAccount from '~/services/brokerage/getAccountSummary';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { InfoOutlined } from '@material-ui/icons';

class CreateRedemption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: null,
      amount: '',
      fullAmount: false,
      disabled: false,
    };
    this.amountInput = React.createRef();
  }

  async componentDidMount() {
    if (!this.state.account) {
      await this.getAccount();
    }
  }

  async getAccount(success) {
    this.setState({
      disabled: true,
    });

    const account = await getBrokerageAccount(this.props.userKey);
    if (account.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch user\'s brokerage account',
        action: false,
      }));
      this.setState({
        disabled: false,
        account: {},
      });
    } else {
      this.setState({
        disabled: false,
        account: account.data,
      });
      if (success) {
        success();
      }
    }
  }

  handleAmountChange(amount, event) {
    this.setState({ [amount]: event.target.value });
  }

  async createRedemption(event) {
    event.stopPropagation();
    this.setState({ disabled: true });
    const response = await create({ userKey: this.props.userKey, amount: this.state.amount });
    await this.getAccount();
    this.props.refreshRedemptions(event, response.error);
    this.setState({ disabled: false });
  }

  handleFullAmountToggle() {
    const amount = this.state.fullAmount ? '' : get(this.state.account, 'withdrawals.availableWithdrawAmount', 0);
    this.setState({ fullAmount: !this.state.fullAmount, amount }, () => {
      if (!this.state.fullAmount) {
        this.amountInput.current.focus()
      }
    });
  }

  render() {
    const tooltipAvailableWithdrawal = <div><div>{'Cash Available For Withdrawal: '}<b>{`$${get(this.state.account, 'cash.cashAvailableForWithdrawal', '0')}`}</b></div><div>{'Pending Withdrawals: '}<b>{`$${get(this.state.account, 'withdrawals.pendingRedemptions.amount', '0')}`}</b></div></div>;
    const availableWithdrawAmount = get(this.state.account, 'withdrawals.availableWithdrawAmount', 0);
    return (
      <Dialog
        onClick={(event) => event.stopPropagation()}
        fullWidth
        maxWidth={'sm'}
        open={this.props.open}
        onClose={(event) => this.props.parentState(event, { create: false })}
        aria-labelledby="responsive-dialog-title"
      >
        {this.state.disabled &&
          <div className="loading-container">
            <LinearProgress className="loading" />
          </div>
        }
        <DialogTitle>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" className="flex-grow">
                Create Redemption
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  {'Calculated Withdrawal'}
                  <Tooltip
                    title={tooltipAvailableWithdrawal}
                  >
                    <IconButton size={'small'}>
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  ${get(this.state.account, 'withdrawals.availableWithdrawAmount', 'None')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {'Full Amount'}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={this.state.fullAmount}
                    onChange={() => this.handleFullAmountToggle()}
                    value="statusChange"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <TextField
                    inputRef={this.amountInput}
                    disabled={this.state.fullAmount}
                    autoFocus
                    style={{ width: '100%', margin: 0 }}
                    id="amount"
                    label="Amount"
                    className={'input-field ml-0'}
                    value={this.state.amount}
                    onChange={e => this.handleAmountChange('amount', e)}
                    margin="normal"
                    type="number"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => this.props.parentState(event, { create: false })}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={(event) => this.createRedemption(event)}
            variant="contained"
            color="primary"
            className="space"
            disabled={this.state.disabled || availableWithdrawAmount <= 0}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateRedemption.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(CreateRedemption);
