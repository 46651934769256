import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  Button,
} from '@material-ui/core';

import update from '~/services/company/update';
import { creators as errorCreators } from '~/ducks/error';

import './style.css';

class DeleteCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleted: props.companyData.deleted,
      loading: false,
    };
  }

  async toggleDelete() {
    this.setState({
      loading: true,
    });
    const { tickerSymbol } = this.props.companyData;
    const updatedCompany = await update(
      tickerSymbol,
      { deleted: !this.state.deleted },
    );

    if (updatedCompany.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to update company',
        action: false,
      }));
    }

    this.setState({
      loading: false,
      deleted: updatedCompany.data.deleted,
    });
  }

  render() {
    return (
      <Paper square id="delete-module-container">
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              {`${this.state.deleted ? 'Restore' : 'Delete'} Company`}
            </Typography>
          </Toolbar>
        </AppBar>
        <div id="delete-module-content">
          <p>
            Deleting a company will remove it from all company listings<br />and
            prevent new transaction matches and purchase orders.<br />
            In-progress purchase orders will continue to resolve.
          </p>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.toggleDelete()}
            disabled={this.state.loading}
          >
            {this.state.deleted ? 'Restore' : 'Delete'}
          </Button>
        </div>
      </Paper>
    );
  }
}

DeleteCompany.propTypes = {
  companyData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(DeleteCompany);
