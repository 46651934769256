import config from 'Config'; /* eslint-disable-line */
import store from '../../';

export default async (userKey) => {
  const authUser = store.getState().user;
  return fetch(`${config.apiUrl}/user/${userKey}/brokerage/summary`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: authUser.access_token,
      'grifin-user-key': authUser.publicKey,
    },
  })
    .then(async (response) => {
      if (response.status !== 200) {
        const json = await response.json();
        throw new Error(json || response.statusText);
      }
      return response;
    })
    .then(async (response) => {
      const json = await response.json();
      console.log('JSON', json);
      return { success: true, data: json };
    })
    .catch(error => ({ error }));
};
