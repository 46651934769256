import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  CircularProgress,
  Button,
  Toolbar,
  InputBase,
  IconButton,
  Tooltip,
  TablePagination,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Switch,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Add as AddIcon,
  Image as ImageIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import getAll from '~/services/company/getAll';
import update from '~/services/company/update';
import { creators as errorCreators } from '~/ducks/error';
import './style.css';

function formatISODate(isoDate) {
  const date = new Date(isoDate);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

class CompanyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      companies: [],
      total: 0,
      searchActive: false,
      filterMenu: false,
      textSearch: '',
      sortBy: '',
      order: 'desc',
      page: 0,
      entries: 15,
    };

    this.searchInput = React.createRef();

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.sortCompanies = this.sortCompanies.bind(this);
    this.handleEntriesChange = this.handleEntriesChange.bind(this);
  }

  async componentDidMount() {
    if (this.state.fetching) {
      await this.fetchCompanies();
    }
  }

  async fetchCompanies() {
    const { textSearch, page, entries, sortBy, order } = this.state;
    const companies = await getAll(textSearch, page + 1, entries, sortBy, order);
    if (companies.error) {
      this.props.dispatch(errorCreators.new({
        message: 'Failed to fetch companies',
        action: false,
      }));
      this.setState({
        fetching: false,
        companies: [],
      });
    } else {
      this.setState({
        companies: companies.data.companies,
        total: companies.data.meta.total,
        fetching: false,
      });
    }
  }

  handleEntriesChange(event) {
    this.setState({
      entries: event.target.value,
      fetching: true,
    }, async () => this.fetchCompanies());
  }

  sortBy(by) {
    if (by === this.state.sortBy) {
      this.setState({
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        page: 0,
      }, () => this.fetchCompanies());
    } else {
      this.setState({
        sortBy: by,
        order: 'desc',
        page: 0,
      }, () => this.fetchCompanies());
    }
  }

  handleChangePage(event, page) {
    this.setState({ page, fetching: true }, async () => this.fetchCompanies());
  }

  handleSearchTextChange(event) {
    this.setState({
      textSearch: event.target.value,
      page: 0,
    }, async () => this.fetchCompanies());
  }

  toggleSearch() {
    if (this.state.searchActive) {
      this.setState({
        searchActive: false,
        textSearch: '',
      });
    } else {
      this.setState({
        searchActive: true,
      }, () => this.searchInput.current.focus());
    }
  }

  sortCompanies(companies) {
    if (!this.state.sortBy) {
      return companies;
    }
    const sortedCompanies = companies.sort((a, b) => {
      if (typeof a[this.state.sortBy] === 'string') {
        // Handle cash with no ticker
        if (!b[this.state.sortBy]) return 1;
        // Sort strings
        const x = a[this.state.sortBy].toLowerCase();
        const y = b[this.state.sortBy].toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
      }
      return a[this.state.sortBy] - b[this.state.sortBy];
    });
    if (this.state.order === 'desc') {
      return sortedCompanies;
    }
    return sortedCompanies.reverse();
  }

  async toggleChecked(event, tickerSymbol) {
    const checked = event.target.checked;
    const updatedCompany = await update(
      tickerSymbol,
      {
        lowMatchPriority: checked,
      },
    );
    if (updatedCompany.data) {
      const companies = JSON.parse(JSON.stringify(this.state.companies));
      const findCompany = companies.find(company => company.tickerSymbol === tickerSymbol);
      if (findCompany) {
        findCompany.lowMatchPriority = checked;
        this.setState({
          companies,
        });
      }
      this.props.dispatch(errorCreators.new({
        message: `Company with ticker symbol ${tickerSymbol} updated successfully`,
        action: false,
      }));
    } else {
      this.props.dispatch(errorCreators.new({
        message: updatedCompany.error.message || `Failed to update company with ticker symbol ${tickerSymbol}`,
        action: false,
      }));
    }
  }

  render() {
    return (
      <div id="module-container" className={this.state.fetching ? 'fetching' : 'full'}>
        <Paper className="table-paper">
          {this.state.fetching &&
            <div className="circular-loading-container">
              <CircularProgress />
            </div>
          }
          {!this.state.fetching &&
            <div id="module-content" className="full-width">
              <Toolbar>
                <div className="title-container">
                  <Typography variant="h6" id="tableTitle">
                    Companies
                  </Typography>
                </div>
                <div id="page-entries">
                  <FormControl style={{ minWidth: '70px' }}>
                    <InputLabel htmlFor="per-page">Per Page</InputLabel>
                    <Select
                      value={this.state.entries}
                      onChange={this.handleEntriesChange}
                      inputProps={{ id: 'per-page' }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  id="toolbar-search"
                  className={this.state.searchActive ? 'open' : 'closed'}
                >
                  <Tooltip enterDelay={300} title="Search">
                    <IconButton
                      onClick={this.toggleSearch}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  {
                    this.state.searchActive &&
                    <InputBase
                      id="text-search"
                      placeholder="Search"
                      onChange={this.handleSearchTextChange}
                      inputRef={this.searchInput}
                      value={this.state.textSearch}
                    />
                  }
                </div>
                <div
                  id="toolbar-filter"
                >
                  <Tooltip enterDelay={300} title="New Company">
                    <IconButton
                      onClick={() => this.props.history.push('companies/create')}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Toolbar>
              <div id="table-container">
                <Table stickyHeader size={'small'}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'tickerSymbol'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('tickerSymbol')}
                          >
                            Symbol
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'name'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('name')}
                          >
                            Name
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={false}
                          >
                            Subsidiaries
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={false}
                          >
                            Matches
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'created_at'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('created_at')}
                          >
                            Created
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'riskLevel'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('riskLevel')}
                          >
                            Risk Level
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'lowMatchPriority'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('lowMatchPriority')}
                          >
                            Low Match Priority
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={false}
                        >
                          Logo
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      this.state.companies
                        .map(row => (
                          <TableRow key={`user-${row.tickerSymbol}`}>
                            <TableCell component="th" scope="row">{row.tickerSymbol}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.subsidiaries.length}</TableCell>
                            <TableCell>{row.match.length}</TableCell>
                            <TableCell>{formatISODate(row.created_at)}</TableCell>
                            <TableCell>{row.riskLevel || ''}</TableCell>
                            <TableCell>
                              <Switch id={row.tickerSymbol} value={row.tickerSymbol} color={'secondary'} checked={!!row.lowMatchPriority} onChange={e => this.toggleChecked(e, row.tickerSymbol)} />
                            </TableCell>
                            <TableCell>
                              {row.logo || row.logo !== ''
                                ? <Tooltip enterDelay={300} title="Open logo image in new tab">
                                  <IconButton
                                    onClick={() => { const win = window.open(row.logo, '_blank'); win.focus(); }}
                                  >
                                    <ImageIcon />
                                  </IconButton>
                                </Tooltip>
                                : 'none'
                              }
                            </TableCell>
                            <TableCell align="right">
                              <Link to={`companies/${encodeURIComponent(row.tickerSymbol)}`}>
                                <Button
                                  variant="outlined"
                                >
                                  Edit
                                </Button>
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))
                    }
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                component="div"
                id="table-pagination"
                count={this.state.total}
                rowsPerPage={this.state.entries}
                rowsPerPageOptions={[]}
                page={this.state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
              />
            </div>
          }
        </Paper>
      </div>
    );
  }
}

CompanyTable.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(withRouter(CompanyTable));
