import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  LinearProgress,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import {
  Add,
  Delete,
} from '@material-ui/icons';
import update from '~/services/company/update';
import { get } from 'lodash';
import { creators as errorCreators } from '~/ducks/error';

import './style.css';

class ManageMatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyData: props.companyData,
      loading: false,
      matches: props.companyData.match,
      newMatch: '',
      checked: props.companyData.lowMatchPriority || false,
    };
  }

  handleTextChange(name, event) {
    this.setState({ [name]: event.target.value });
  }

  async handleSubmit() {
    const updatedMatches = [...get(this.state.companyData, 'match', [])];
    if (updatedMatches.includes(this.state.newMatch)) {
      this.props.dispatch(errorCreators.new({
        message: 'Duplicate match',
        action: false,
      }));
      return;
    }
    this.setState({
      loading: true,
    });
    const { tickerSymbol } = this.props.companyData;

    updatedMatches.push(this.state.newMatch);
    const updatedCompany = await update(
      tickerSymbol,
      {
        match: updatedMatches,
      },
    );
    if (updatedCompany.data) {
      this.setState({
        loading: false,
        newMatch: '',
        matches: updatedCompany.data.match,
        companyData: updatedCompany.data,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Company updated successfully',
        action: false,
      }));
    } else {
      this.setState({
        loading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: updatedCompany.error.message || 'Failed to update companyy',
        action: false,
      }));
    }
  }

  async deleteMatch(match) {
    const { tickerSymbol } = this.props.companyData;
    this.setState({
      loading: true,
    });
    const newMatches = [...get(this.state.companyData, 'match', [])];
    for (let i = newMatches.length - 1; i >= 0; i -= 1) {
      if (newMatches[i] === match) {
        newMatches.splice(i, 1);
        break;
      }
    }
    const updatedCompany = await update(
      tickerSymbol,
      {
        match: newMatches,
      },
    );
    if (updatedCompany.data) {
      this.setState({
        loading: false,
        matches: updatedCompany.data.match,
        companyData: updatedCompany.data,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Company updated successfully',
        action: false,
      }));
    } else {
      this.setState({
        loading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Failed to update company',
        action: false,
      }));
    }
  }
  async toggleChecked(event) {
    const checked = event.target.checked;
    this.setState({
      loading: true,
    });
    const { tickerSymbol } = this.props.companyData;
    const updatedCompany = await update(
      tickerSymbol,
      {
        lowMatchPriority: checked,
      },
    );
    if (updatedCompany.data) {
      this.setState({
        loading: false,
        companyData: updatedCompany.data,
        checked,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Company updated successfully',
        action: false,
      }));
    } else {
      this.setState({
        loading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: updatedCompany.error.message || 'Failed to update companyy',
        action: false,
      }));
    }
  }

  render() {
    return (
      <Paper square id="matches-module-container">
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Transaction Matches
            </Typography>
            <div className={'low-match'}>
              <FormControlLabel
                classes={{ label: 'my-label' }}
                control={<Switch color={'secondary'} checked={this.state.checked} onChange={e => this.toggleChecked(e)} />}
                label={'Low Match Priority'}
              />
            </div>
          </Toolbar>
          {this.state.loading && (
            <div className="loading-container">
              <LinearProgress className="loading" />
            </div>
          )}
        </AppBar>
        <div>
          <TextField
            placeholder="New regular expression"
            variant="outlined"
            value={this.state.newMatch}
            onChange={e => this.handleTextChange('newMatch', e)}
            margin="normal"
            style={{ padding: '0 10px 0 10px', width: '98%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Add Regular Expression"
                    onClick={() => this.handleSubmit()}
                    disabled={this.state.loading}
                  >
                    <Add />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div id="matches-table">
          <Table stickyHeader size={'small'}>
            <TableBody>
              {(this.state.matches || []).map(match => (
                <TableRow
                  key={`match-${match}`}
                >
                  <TableCell style={{ width: '100%' }}>
                    {match}
                  </TableCell>
                  <TableCell
                    align="right"
                  >
                    <IconButton
                      onClick={() => this.deleteMatch(match)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

ManageMatches.propTypes = {
  companyData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ManageMatches);
