import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  LinearProgress,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import {
  Add,
  Delete,
} from '@material-ui/icons';
import updateSubsidiary from '~/services/company/updateSubsidiary';
import { creators as errorCreators } from '~/ducks/error';

import './style.css';

class ManageMatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subsidiary: props.subsidiaryData,
      loading: false,
      matches: props.subsidiaryData.match,
      newMatch: '',
    };

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTextChange(name, event) {
    this.setState({ [name]: event.target.value });
  }

  async handleSubmit() { // TODO: check for duplicates
    this.setState({
      loading: true,
    });
    const updatedMatches = [...this.props.subsidiaryData.match];
    updatedMatches.push(this.state.newMatch);
    const updatedCompany = await updateSubsidiary(
      this.props.companyId,
      this.props.subsidiaryData._id,
      {
        name: this.props.subsidiaryData.name,
        match: updatedMatches,
      },
    );
    if (updatedCompany.data) {
      const updatedSubsidiary = updatedCompany.data.subsidiaries.find(
        o => o.name === this.props.subsidiaryData.name,
      );
      if (this.state.subsidiary._id !== updatedSubsidiary._id) {
        location.replace(`/dashboard/companies/${this.props.companyId}/subsidiary/${encodeURIComponent(updatedSubsidiary._id)}`);
      }
      this.setState({
        loading: false,
        newMatch: '',
        matches: updatedSubsidiary.match,
        subsidiaryData: updatedSubsidiary,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Company updated successfully',
        action: false,
      }));
    } else {
      this.setState({
        loading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: updatedCompany.error.message || 'Failed to update subsidiary',
        action: false,
      }));
    }
  }

  async deleteMatch(match) {
    this.setState({
      loading: true,
    });
    const newMatches = [...this.props.subsidiaryData.match];
    for (let i = newMatches.length - 1; i >= 0; i -= 1) {
      if (newMatches[i] === match) {
        newMatches.splice(i, 1);
        break;
      }
    }
    const updatedCompany = await updateSubsidiary(
      this.props.companyId,
      this.props.subsidiaryData._id,
      {
        name: this.props.subsidiaryData.name,
        match: newMatches,
      },
    );
    if (updatedCompany.data) {
      const updatedSubsidiary = updatedCompany.data.subsidiaries.find(
        o => o.name === this.props.subsidiaryData.name,
      );
      if (this.state.subsidiary._id !== updatedSubsidiary._id) {
        location.replace(`/dashboard/companies/${this.props.companyId}/subsidiary/${encodeURIComponent(updatedSubsidiary._id)}`);
      }
      this.setState({
        loading: false,
        matches: updatedSubsidiary.match,
        subsidiaryData: updatedSubsidiary,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Company updated successfully',
        action: false,
      }));
    } else {
      this.setState({
        loading: false,
      });
      this.props.dispatch(errorCreators.new({
        message: 'Failed to update subsidiary',
        action: false,
      }));
    }
  }

  render() {
    return (
      <Paper square id="subsidiary-matches-container">
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Transaction Matches
            </Typography>
          </Toolbar>
          {this.state.loading && (
            <div className="loading-container">
              <LinearProgress className="loading" />
            </div>
          )}
        </AppBar>
        <div>
          <TextField
            placeholder="New regular expression"
            variant="outlined"
            value={this.state.newMatch}
            onChange={e => this.handleTextChange('newMatch', e)}
            margin="normal"
            style={{ padding: '0 10px 0 10px', width: '98%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Add Regular Expression"
                    onClick={this.handleSubmit}
                  >
                    <Add />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Table>
          <TableBody>
            {(this.state.matches || []).map(match => (
              <TableRow
                key={`match-${match}`}
              >
                <TableCell style={{ width: '100%' }}>
                  {match}
                </TableCell>
                <TableCell
                  align="right"
                >
                  <IconButton
                    onClick={() => this.deleteMatch(match)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

ManageMatches.propTypes = {
  companyId: PropTypes.string.isRequired,
  subsidiaryData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ManageMatches);
