import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import get from '~/services/company/get';
import { creators as errorCreators } from '~/ducks/error';

import UpdateForm from './UpdateForm';
import ManageMatches from './ManageMatches';
import DeleteSubsidiary from './DeleteSubsidiary';

import './style.css';

class Subsidiary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      company: null,
      subsidiary: null,
    };
  }

  async componentDidMount() {
    if (this.state.fetching) {
      const company = await get(this.props.match.params.ticker);
      if (company.error) {
        this.props.dispatch(errorCreators.new({
          message: 'Failed to fetch company',
          action: false,
        }));
        this.setState({
          fetching: false,
          company: {},
        });
      } else {
        let subsidiary;
        if (this.props.match.params.subsidiaryId === 'new') {
          subsidiary = {
            name: '',
          };
        } else {
          subsidiary = company.data.subsidiaries.find(
            o => o._id === decodeURIComponent(this.props.match.params.subsidiaryId),
          );
        }
        this.setState({
          company: company.data,
          subsidiary,
          fetching: false,
        });
      }
    }
  }

  async componentDidUpdate() {
    if (this.props.match.params.subsidiaryId !== 'new') {
      if (this.props.match.params.subsidiaryId !== this.state.subsidiary._id) {
        const company = await get(this.props.match.params.ticker);
        if (company.error) {
          this.props.dispatch(errorCreators.new({
            message: 'Failed to fetch company',
            action: false,
          }));
          this.setState({
            fetching: false,
            company: {},
          });
        } else {
          const subsidiary = company.data.subsidiaries.find(
            o => o._id === decodeURIComponent(this.props.match.params.subsidiaryId),
          );
          this.setState({
            company: company.data,
            subsidiary,
            fetching: false,
          });
        }
      }
    }
  }

  render() {
    if (this.state.fetching) {
      return (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <CircularProgress />
        </div>
      );
    }
    return (
      <div style={{ padding: '10px' }}>
        <UpdateForm
          companyId={this.props.match.params.ticker}
          subsidiaryData={this.state.subsidiary}
          history={this.props.history}
        />
        {this.props.match.params.subsidiaryId !== 'new' &&
        <ManageMatches
          companyId={this.props.match.params.ticker}
          subsidiaryData={this.state.subsidiary}
        />
        }
        {this.props.match.params.subsidiaryId !== 'new' &&
        <DeleteSubsidiary
          companyId={this.props.match.params.ticker}
          subsidiaryData={this.state.subsidiary}
        />
        }
        {/* <p>{JSON.stringify(this.state.company, null, '\n')}</p>
        <p>{JSON.stringify(this.state.subsidiary, null, '\n')}</p> */}
      </div>
    );
  }
}

Subsidiary.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Subsidiary);
