import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  TableBody,
  TableRow,
  TableCell,
  Table,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { get } from 'lodash';

function validateName(text) {
  const regex = new RegExp(/^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-]+){2,40}$/u);

  return regex.test(text);
}

class EditName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basic: props.basic,
      firstNameError: false,
      lastNameError: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (JSON.stringify(nextProps.basic) !== JSON.stringify(this.props.basic)) {
      this.setState({ basic: nextProps.basic });
    }
    return true;
  }

  handleNameChange (event, field) {
    const basic = JSON.parse(JSON.stringify(this.state.basic));
    basic[field] = event.target.value;
    this.setState({ basic, [`${field}Error`]: false });
  }

  validateFullName() {
    const { firstName, lastName } = this.state.basic;
    const errors = {};
    if (!validateName(firstName)) {
      errors.firstNameError = true;
    }
    if (!validateName(lastName)) {
      errors.lastNameError = true;
    }
    if (Object.keys(errors).length > 0) {
      this.setState(errors);
      return false;
    }
    return true;
  }

  onSubmit() {
    if (this.validateFullName()) {
      this.props.updateName(this.state.basic);
    }
  }

  render() {
    if (!this.props.open) return null;
    return (
      <Dialog
        onClick={(event) => event.stopPropagation()}
        fullWidth
        maxWidth={'sm'}
        open={this.props.open}
        onClose={(event) => this.props.parentState(event, { editName: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" className="flex-grow">
                Edit Full Name
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <TextField
                    autoFocus
                    error={this.state.firstNameError}
                    style={{ width: '100%', margin: 0 }}
                    id="firstName"
                    label="First Name"
                    className={'input-field ml-0'}
                    value={get(this.state.basic, 'firstName', '')}
                    onChange={e => this.handleNameChange(e, 'firstName')}
                    margin="normal"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <TextField
                    error={this.state.lastNameError}
                    style={{ width: '100%', margin: 0 }}
                    id="lastName"
                    label="Last Name"
                    className={'input-field ml-0'}
                    value={get(this.state.basic, 'lastName', '')}
                    onChange={e => this.handleNameChange(e, 'lastName')}
                    margin="normal"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => this.props.parentState(event, { editName: false })}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.onSubmit()}
            variant="contained"
            color="primary"
            className="space"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(EditName);
