import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Tooltip,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails, CircularProgress,
} from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';
import {
  ExpandMore,
} from '@material-ui/icons';
import getDepositById from '~/services/deposits/getById';
import addBusinessDays from '~/utils/addBusinessDays';

function getResolutionProgress(brokerageAccount, strikeObject, strikeDeposit) {
  if (strikeObject.status === 'CREATED') {
    return 1;
  }

  if (strikeObject.status === 'RESOLVING') {
    switch (strikeDeposit.status) {
      case 'RETRY': {
        return 2;
      }
      case 'WORKING': {
        return 3;
      }
      case 'READY': {
        return 4;
      }
      case 'DONE': {
        return 5;
      }
      default: {
        return 0;
      }
    }
  }

  return 0;
}

class StrikeResolution extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      strikeDeposit: null,
      expanded: true,
      error: null,
    }
  }

  componentDidMount() {
    const strikeDepositId = get(this, 'props.user.strikes.deposits[0].deposit', false);
    const userKey = get(this, 'props.user.publicKey', false);
    if (!this.state.strikeDeposit && strikeDepositId && userKey) {
      getDepositById(userKey, strikeDepositId)
        .then((response) => {
          if (response.error) {
            throw new Error(response.error);
          }
          this.setState({
            strikeDeposit: response.data,
          });
        })
        .catch((err) => {
          this.setState({
            error: err,
          })
        });
    }
  }

  renderStrikeResolutionContent() {
    if (!this.state.strikeDeposit) {
      return <CircularProgress />
    }

    const progress = getResolutionProgress(this.props.user.brokerage.account, this.props.user.strikes, this.state.strikeDeposit);
    const RESOLUTION_STEPS = 5;
    const retryDepositAmount = this.state.strikeDeposit.amount;
    const strikeStartDate = moment(this.props.user.strikes.created_at);
    const businessDaysToResolveStatusesJob = moment(this.state.strikeDeposit.last_updated).hours > 6 ? 6 : 5;
    const waitingPeriodEndDate = progress === RESOLUTION_STEPS ? addBusinessDays(moment(this.state.strikeDeposit.last_updated), businessDaysToResolveStatusesJob).startOf('day').set({ h: 6, m: 0 }) : false;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          padding: 10,
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <ul>
          <li><b>Returned Deposit Amount:</b> {`$${retryDepositAmount.toFixed(2)}`}</li>
          <li><b>Strike start date:</b> {`${strikeStartDate.format('MM/DD/YY hh:mm')}`}</li>
          <li><b>Resolution Attempts:</b> {get(this, 'props.user.strikes.deposits[0].attempts', null)}</li>
          {waitingPeriodEndDate &&
            <li><b>Expected Account Restoration Date:</b> {`${waitingPeriodEndDate.format('MM/DD/YY hh:mm')}`}</li>
          }
        </ul>
        <ul style={{ listStyle: 'disc' }}>
          <li
            style={{
              color: progress > 1 ? 'green' : 'black',
            }}
          >
            <b>Retry Deposit Permission: </b>User was shown a screen when they logged in informing them of their return deposit. They pressed the "Retry Deposit" button and so their retry deposit will begin next Wednesday. Up until this point, deposits dating back to strike start date have been skipped.
          </li>
          <li
            style={{
              color: progress > 2 ? 'green' : 'black',
            }}
          >
            <b>Retry Deposit Initiated: </b>User will see deposit amount leave their bank account within 3 business days.
          </li>
          <li
            style={{
              color: progress > 3 ? 'green' : 'black',
            }}
          >
            <b>Retry Deposit cleared: </b>Funds have been transferred from the user’s funding source and are available for trade in their brokerage account. The deposit will be verified by Grifin by the end of the day. Deposit will remain as cash.
          </li>
          <li
            style={{
              color: progress > 4 ? 'green' : 'black',
            }}
          >
            <b>Retry Deposit complete: </b>User’s account will be restored automatically after 5 business days from when their deposit cleared allowing them to continue investing and/or creating buy orders.
          </li>
        </ul>
        <div
          style={{
            height: 10,
            width: '100%',
            border: '1px solid black',
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <div
            style={{
              width: `${(progress / RESOLUTION_STEPS) * 100}%`,
              backgroundColor: 'blue',
              height: 8,
            }}
          />
        </div>
      </div>
    )
  }

  render() {
    if (!get(this, 'props.user.strikes.status', false)) {
      return null;
    }

    return (
      <Paper square style={{ marginTop: 10 }}>
        <Accordion
          square
          expanded={this.state.expanded}
          onChange={() => this.handleChange()}
        >
          <AccordionSummary
            expandIcon={
              <Tooltip
                title={this.state.expanded ? 'Collapse' : 'Expand'}
              >
                <ExpandMore style={{ color: 'white' }} />
              </Tooltip>
            }
            aria-controls="panel1c-content"
            id="panel1c-header"
            style={{ color: 'white', backgroundColor: '#3f51b5', minHeight: 48 }}
            classes={{ content: 'accordion-summary-content' }}
          >
            <Typography
              variant="h6"
              color="inherit"
              className="flex-grow"
            >
              Strike Resolution
            </Typography>
            {!this.state.strikeDeposit && (
              <div className="loading-container" style={{ width: '100%', position: 'absolute', left: 0, top: '48px' }}>
                <LinearProgress className="loading" />
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails>
            {this.renderStrikeResolutionContent()}
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
}

StrikeResolution.propTypes = {
  user: PropTypes.object.isRequired,
}

export default StrikeResolution
