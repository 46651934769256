import React from "react";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { RestLink } from "apollo-link-rest";

import CONFIG from "Config";

function ApolloContext({ children, store }) {
  const restLink = new RestLink({
    uri: CONFIG.apiUrl,
  });

  const setAuthorizationLink = setContext(async (request, previousContext) => {
    const authUser = store.getState().user;
    if (!authUser.isAuthed) {
      return ({
        headers: {
          'Content-Type': 'application/json',
        }
      });
    }
    return ({
      headers: {
        'Content-Type': 'application/json',
        authorization: authUser.access_token,
        'grifin-user-key': authUser.publicKey,
      }
    });
  });

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: setAuthorizationLink.concat(restLink)
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

export { ApolloContext };
