import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Select,
  MenuItem,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { get } from 'lodash';

const statuses = [
  'NOT_STARTED',
  'COMPLETE',
]

class EditSMSVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smsVerification: props.smsVerification,
    };
  }

  handleSMSVerificationChange (event, field) {
    const smsVerification = JSON.parse(JSON.stringify(this.state.smsVerification));
    smsVerification[field] = event.target.value;
    this.setState({ smsVerification });
  }

  onSubmit() {
    this.props.updateSMSVerification(this.state.smsVerification);
  }

  render() {
    if (!this.props.open) return null;
    return (
      <Dialog
        onClick={(event) => event.stopPropagation()}
        fullWidth
        maxWidth={'xs'}
        open={this.props.open}
        onClose={(event) => this.props.parentState(event, { editSMSVerification: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" color="inherit" className="flex-grow">
                Edit SMS Verification Status
              </Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <Select
                    style={{ width: '100%', margin: 0 }}
                    labelId="sms-verification-select-label"
                    label="SMS Verification Status"
                    value={get(this.state.smsVerification, 'status', '')}
                    onChange={e => this.handleSMSVerificationChange(e, 'status')}
                  >
                    {statuses.map(status => <MenuItem value={status}>{status}</MenuItem>)}
                  </Select>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) => this.props.parentState(event, { editSMSVerification: false })}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.onSubmit()}
            variant="contained"
            color="primary"
            className="space"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(EditSMSVerification);
