import React, { useEffect, useState } from "react";
import { gql, useLazyQuery, useQuery, useApolloClient } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import {
  ChevronLeft,
  ChevronRight,
  ExitToApp,
  Search as SearchIcon,
  FilterList as FilterIcon,
  GetApp as DownloadIcon,
} from '@material-ui/icons';
import { get } from "lodash";

import AccountDetails from "./components/AccountDetails";

function User({ history }) {
  const apolloClient = useApolloClient();
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const { data, loading, error } = useQuery(gql`
  query GetUser($id: String) {
    user(id: $id) @rest(type: "User", path: "/users/{args.id}") {
      basic @type(name: "Profile") {
        email
        firstName
        lastName
      }
      brokerage @type(name: "Brokerage") {
        account @type(name: "BrokerageAccount") {
          id
        }
      }
    }
  }
`, { variables: { id: id }});

  const {
    data: accountData,
    loading: accountLoading,
    error: accountError,
    refetch: refetchAccount,
  } = useQuery(gql`
      query GetDrivewealthAccountInfo($id: String) {
        drivewealthAccount(id: $id) @rest(type: "DrivewealthAccount", path: "/user/{args.id}/brokerage/account") {
          account @type(name: "DrivewealthAccount") {
            id
            accountNo
            status @type(name: "DrivewealthAccountStatus") {
              name
              description
            }
            accountType @type(name: "DrivewealthAccountType") {
              name
              description
            }
            bod @type(name: "DrivewealthAccountBOD") {
              cashAvailableForTrading
              cashAvailableForWithdrawal
              cashBalance
              equityValue
              moneyMarket
              updatedWhen
            }
            openedWhen
          }
        },
        drivewealthAccountSummary(id: $id) @rest(type: "DrivewealthAccountSummary", path: "/user/{args.id}/brokerage/account/summary") {
          accountSummary @type(name: "DrivewealthAccountSummary") {
            accountID
            accountNo
            tradingType
            lastUpdated
            cash @type(name: "DrivewealthAccountSummaryCash") {
              cashAvailableForTrade
              cashAvailableForWithdrawal
              cashBalance
            }
            equity @type(name: "DrivewealthAccountSummaryEquity") {
              equityValue
              equityPositions @type(name: "DrivewealthAccountSummaryEquityPositions") {
                symbol
                instumentID
                openQty
                costBasis
                marketValue
                side
                priorClose
                availableForTradingQty
                avgPrice
                mktPrice
              }
            }
          }
        }
      }`, {
        variables: {
          id: id
        }
      }
    );

  function getName() {
    if (loading) return "Loading...";
    if (error) return "";

    const firstName = get(data, "user.basic.firstName", "");
    const lastName = get(data, "user.basic.lastName", "");

    if (firstName.length === 0 && lastName.length === 0) return "User (Name not Set)";
    return `${firstName} ${lastName}`.trim();
  }

  function renderBrokerageStatus(status) {
    let color = "#ccc";
    switch (status.name) {
      case "OPEN":
        color = "green";
        break;
      case "OPEN_NO_NEW_TRADES":
        color = "yellow";
        break;
      case "CLOSED":
        color = "red";
        break;
      default:
        break;
    }

    return <span className="block w-3 h-3 rounded-full tooltip right" data-content={`${status.description}`} style={{ backgroundColor: color }}></span>
  }

  function renderBrokerage() {
    if (accountError) return null;
    if (accountLoading) return <div>...Loading...</div>
    if(!accountData) return null;

    const { drivewealthAccount, drivewealthAccountSummary } = accountData;

    if (!drivewealthAccount || !drivewealthAccountSummary) return (
      <>
        <h2 className="font-bold text-lg mb-2 flex justify-between items-center">
          <span>Brokerage</span>
        </h2>
        <div>
          Brokerage account not found.
        </div>
      </>
    )

    return (
      <>
        <h2 className="font-bold text-lg mb-2 flex justify-between items-center">
          <span>Brokerage</span>
          {renderBrokerageStatus(drivewealthAccount.account.status)}
        </h2>
        <a href="#"
          className="flex items-center justify-between py-2"
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}>
          <span>Account Info</span>
          <ChevronRight fontSize="default" />
        </a>
        <Link to={`/dashboard/user/${id}/brokerage/transactions`} className="flex items-center justify-between py-2">
          <span>Transactions</span>
          <ChevronRight fontSize="default" />
        </Link>

        <AccountDetails
          isOpen={open}
          close={() => setOpen(false)}
          user={{ name: getName(), publicKey: id }}
          account={{ info: drivewealthAccount, summary: drivewealthAccountSummary }}
          refresh={() => {
            refetchAccount();
          }}
        />
      </>
    )
  }

  return (
    <div className="m-5">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <a href="#" onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}>
            <ChevronLeft fontSize="large" />
          </a>
          <h1 className="text-xl font-bold">{getName()}</h1>
        </div>
        <div>
          <Link to={`/dashboard/users/${id}`} className="flex items-center">
            <span className="pr-2">Legacy User Dashboard</span>
            <ExitToApp fontSize="large" />
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-3">
        <div className="rounded-sm shadow p-4">
          {renderBrokerage()}
        </div>
        <div className="rounded-sm shadow p-4">
          <h2 className="font-bold text-lg mb-2">Banking</h2>
          <Link to={`/dashboard/user/${id}/transactions`} className="flex items-center justify-between">
            <span>Transactions</span>
            <ChevronRight fontSize="default" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default User;
