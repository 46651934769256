import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import { ToastProvider } from 'react-toast-notifications';

import "../style/grifin.css";

import { ApolloContext } from '~/components/Apollo';
import App from './routes/App';
import configureStore from './stores/configureStore';

const history = createBrowserHistory();
const { store, persistor } = configureStore({}, history);

export default store;

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ApolloContext store={store}>
          <ToastProvider>
            <App />
          </ToastProvider>
        </ApolloContext>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
);

if (module.hot) {
  module.hot.accept(App);
}
