import config from 'Config'; /* eslint-disable-line */

const login = async (email, password) => (
  fetch(`${config.apiUrl}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: email,
      password,
    }),
  })
    .then(async (response) => {
      if (response.status !== 200 || false) {
        const json = await response.json();
        throw new Error(json);
      }
      return response;
    })
    .then(async (response) => {
      const json = await response.json();
      return { success: true, data: json };
    })
    .catch(error => (
      { error }
    ))
);

export default login;
