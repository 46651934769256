import config from 'Config'; /* eslint-disable-line */
import store from '../../';

const update = async (companyTicker, data) => {
  const authUser = store.getState().user;
  return fetch(`${config.apiUrl}/companies/${encodeURIComponent(companyTicker)}/subsidiary`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: authUser.access_token,
      'grifin-user-key': authUser.publicKey,
    },
    body: JSON.stringify({
      data,
    }),
  })
    .then(async (response) => {
      if (response.status !== 200 || false) {
        const json = await response.json();
        throw new Error(json);
      }
      return response;
    })
    .then(async (response) => {
      const json = await response.json();
      return { success: true, data: json };
    })
    .catch((error) => {
      console.log(error);
      return { error };
    });
};

export default update;
