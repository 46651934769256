import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  CircularProgress,
  Button,
  Toolbar,
  InputBase,
  IconButton,
  MenuItem,
  Tooltip,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  Switch,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import getAll from '~/services/dtc/getAll';
import update from '~/services/dtc/update';
import { creators as errorCreators } from '~/ducks/error';

class DTCTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      dtcs: [],
      searchActive: false,
      filterMenu: null,
      textSearch: '',
      sortBy: '',
      order: 'desc',
      page: 0,
      entries: 15,
    };

    this.searchInput = React.createRef();

    this.toggleSearch = this.toggleSearch.bind(this);
    this.filterDTCs = this.filterDTCs.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.sortDTCs = this.sortDTCs.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleEntriesChange = this.handleEntriesChange.bind(this);
  }

  async componentDidMount() {
    if (this.state.fetching) {
      const response = await getAll();
      if (response.error) {
        this.props.dispatch(errorCreators.new({
          message: 'Failed to fetch dtcs numbers',
          action: false,
        }));
        this.setState({
          fetching: false,
          dtcs: [],
        });
      } else {
        this.setState({
          dtcs: response.data.dtcs,
          fetching: false,
        });
      }
    }
  }

  handleEntriesChange(event) {
    this.setState({
      entries: event.target.value,
    });
  }

  sortBy(by) {
    if (by === this.state.sortBy) {
      this.setState({
        order: this.state.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortBy: by,
        order: 'desc',
      });
    }
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleSearchTextChange(event) {
    this.setState({
      textSearch: event.target.value,
    });
  }

  toggleSearch() {
    if (this.state.searchActive) {
      this.setState({
        searchActive: false,
        textSearch: '',
      });
    } else {
      this.setState({
        searchActive: true,
      }, () => this.searchInput.current.focus());
    }
  }


  sortDTCs(DTCs) {
    if (!this.state.sortBy) {
      return DTCs;
    }
    const sortedDTCs = DTCs.sort((a, b) => {
      if (typeof get(a, this.state.sortBy, '') === 'string') {
        const x = get(a, this.state.sortBy, '').toLowerCase();
        const y = get(b, this.state.sortBy, '').toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
      }
      return a[this.state.sortBy] - b[this.state.sortBy];
    });
    if (this.state.order === 'desc') {
      return sortedDTCs;
    }
    return sortedDTCs.reverse();
  }

  filterDTCs() {
    if (!this.state.searchActive) {
      return this.state.dtcs;
    }

    return this.state.dtcs.filter((dtc) => {
      const search = this.state.textSearch.toLowerCase();
      const number = get(dtc, 'number', '');
      const name = get(dtc, 'name', '');

      return number.toLowerCase().includes(search) || name.toLowerCase().includes(search);
    });
  }

  async toggleChecked(event, id, number, name) {
    const checked = event.target.checked;
    const updatedCompany = await update(id, number, name, checked);
    if (updatedCompany.data) {
      const dtcs = JSON.parse(JSON.stringify(this.state.dtcs));
      const findDTC = dtcs.find(dtc => dtc._id === id);
      if (findDTC) {
        findDTC.active = checked;
        this.setState({
          dtcs,
        });
      }
      this.props.dispatch(errorCreators.new({
        message: 'DTC number updated successfully',
        action: false,
      }));
    } else {
      this.props.dispatch(errorCreators.new({
        message: updatedCompany.error.message || `Failed to update DTC number ${number}`,
        action: false,
      }));
    }
  }

  render() {
    return (
      <div id="module-container" className={this.state.fetching ? 'fetching' : 'full'}>
        <Paper className="table-paper">
          {this.state.fetching &&
            <div className="circular-loading-container">
              <CircularProgress />
            </div>
          }
          {!this.state.fetching &&
            <div id="module-content" className="full-width">
              <Toolbar>
                <div className="title-container">
                  <Typography variant="h6" id="tableTitle">
                    DTC Numbers
                  </Typography>
                </div>
                <div id="page-entries">
                  <FormControl style={{ minWidth: '70px' }}>
                    <InputLabel htmlFor="per-page">Per Page</InputLabel>
                    <Select
                      value={this.state.entries}
                      onChange={this.handleEntriesChange}
                      inputProps={{ id: 'per-page' }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  id="toolbar-search"
                  className={this.state.searchActive ? 'open' : 'closed'}
                >
                  <Tooltip enterDelay={300} title="Search">
                    <IconButton
                      onClick={this.toggleSearch}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  {
                    this.state.searchActive &&
                    <InputBase
                      id="text-search"
                      placeholder="Search"
                      onChange={this.handleSearchTextChange}
                      inputRef={this.searchInput}
                      value={this.state.searchText}
                    />
                  }
                </div>
                <div
                  id="toolbar-add"
                >
                  <Tooltip enterDelay={300} title="Create DTC Number">
                    <Link to={'/dashboard/dtc/create'}>
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </div>
              </Toolbar>
              <div id="table-container">
                <Table id="table" stickyHeader size={'small'}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'number'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('number')}
                          >
                            Number
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'name'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('name')}
                          >
                            Name
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip enterDelay={300} title="Sort">
                          <TableSortLabel
                            active={this.state.sortBy === 'active'}
                            direction={this.state.order}
                            onClick={() => this.sortBy('active')}
                          >
                            Active
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody id="table-body">
                    {this.sortDTCs(this.filterDTCs())
                      .slice(
                        this.state.page * this.state.entries,
                        (this.state.page * this.state.entries) + this.state.entries,
                      )
                      .map(row => (
                        <TableRow key={`accessCode-${row._id}`}>
                          <Tooltip enterDelay={300} title={get(row, 'description', false) || 'No Description'}>
                            <TableCell>
                              {row.number}
                            </TableCell>
                          </Tooltip>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>
                            <Switch id={row._id} value={row._id} color={'secondary'} checked={!!row.active} onChange={e => this.toggleChecked(e, row._id, row.number, row.name)} />
                          </TableCell>
                          <TableCell align="right">
                            <Link to={{
                              pathname: `dtc/${encodeURIComponent(row._id)}`,
                              query: row,
                            }}
                            >
                              <Button
                                variant="outlined"
                              >
                                Edit
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                component="div"
                id="table-pagination"
                count={this.state.dtcs.length}
                rowsPerPage={this.state.entries}
                rowsPerPageOptions={[]}
                page={this.state.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
              />
            </div>
          }
        </Paper>
      </div>
    );
  }
}

DTCTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(DTCTable);
